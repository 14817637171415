import { OnInit, Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ImageUploaderService } from './image-uploader.service';
import { UsersProvider } from './users-provider.service';
import { User } from '../../models';
import { Organization } from '../../models/organization';
import { OrgType } from '../../models/enum';

@Injectable({
  providedIn: 'root'
})
export class OrganizationProviderService {
  constructor(private httpservice: HttpService, private imageUploader: ImageUploaderService,
    private _usersProvider: UsersProvider
  ) {
  }

  public addOrganization(org: Organization, file) {
    // console.log(file);

    const body = org;
    return new Promise((resolve, reject) => {
      this.httpservice.post('addOrganization', body).then((success: any) => {
        if (file) {
          this.imageUploader.uploadImage(file, 'organizationsLogos', success.entity.organizationId).then(successUpload => {
            localStorage.setItem('CurrentUser', JSON.stringify(success.entity));
            resolve(success);
          }).catch(err => {
            reject(err);
          });
        } else {
          localStorage.setItem('CurrentUser', JSON.stringify(success.entity));
          resolve(success);
        }
      }).catch(error => {

        reject(error);

      });
    });


  }

  public updateOrganization(updates, file = null) {
    return new Promise((resolve, reject) => {
      const body = {
        updates: updates
      };
      this.httpservice.post('updateOrganization', body).then((success: any) => {
        if (file) {
          this.imageUploader.uploadImage(file, 'organizationsLogo', success.id);
        }
        resolve({ success: true });
      }).catch(error => {

        reject({ success: false });

      });
    });
  }

  public getOrganizationLogo(orgId) {
    return this.imageUploader.downloadImage('organizationsLogos', orgId);
  }
  public addOrganizationUser(data) {
    return new Promise((resolve, reject) => {
      this._usersProvider.getCurrentUser().then((user: User) => {
        const body = {
          orgId: user.organizationId,
          data: data
        };
        this.httpservice.post('createOrganizationUser', body).then((success: any) => {
          resolve(success);
        }).catch(error => {
          reject({ success: false, error: error });
        });
      });
    });
  }

  public updateOrganizationUser(data, isEmailChanged) {
    return new Promise((resolve, reject) => {
      this._usersProvider.getCurrentUser().then((user: User) => {
        const body = {
          updates: data,
          isEmailChanged: isEmailChanged
        };
        this.httpservice.post('updateOrganizationUser', body).then((success: any) => {
          resolve(success);
        }).catch(error => {
          resolve({ success: false, error: error });
        });
      });
    });
  }

  public getOrganizationUsers(force = false) {
    return new Promise((resolve, reject) => {
      const load = (user) => {
        this.httpservice.post('getOrganizationUsers', { organizationId: user.organizationId }).then((users: User[]) => {
          localStorage.setItem(`organizationUsers-${user.organizationId}`, JSON.stringify(users));
          resolve(users);
        }).catch(err => { reject({ message: 'There was an error getting organization users !', fullError: err }); });
      };

      this._usersProvider.getCurrentUser().then((user: User) => {
        if (!user.organizationId) {
          reject({ message: 'No user organisation id in the current user' });
        } else {
          try {
            const storageUsers = JSON.parse(localStorage.getItem(`organizationUsers-${user.organizationId}`));
            if (!force) {
              if (storageUsers && storageUsers.length > 0) {
                resolve(storageUsers);
              } else {
                load(user);
              }
            } else {
              load(user);
            }
          } catch (err) {
            // DO NOTHING
            load(user);
          }
        }
      }).catch(err => {
        console.log(err);
        reject(err);
      });
    });

  }

  validateUserCode(code: string) {
    return new Promise((resolve, reject) => {
      this._usersProvider.getCurrentUser(true).then((user: User) => {
        if (user) {
          this.httpservice.post('validateUserCode', { userId: user.id, code: code }).then((success: any) => {
            localStorage.setItem('CurrentUser', JSON.stringify(success.user));
            resolve(success.message);
          }).catch(err => {
            reject(err);
          });
        } else {

          reject({ message: 'You are not connected' });
        }
      }).catch(err => {
        reject({ message: 'Error when getting current user !', fullError: err });
      });
    });

  }
  /**
   * get all organization types
   * @param force force get from datastore if set to false it tries to get them from local storage
   */
  getAllOrgTypes(force = false) {
    return new Promise((resolve, reject) => {
      let local;
      try {
        local = JSON.parse(localStorage.getItem('orgtypes'));
        if (force || !local) {
          this.httpservice.get('getAllOrgTypes').then((results: OrgType[]) => {
            local = results;
            localStorage.setItem('orgtypes', JSON.stringify(results));
            resolve(local);
          }).catch(err => {
            reject(err);
          });
        } else {
          resolve(local);
        }
      } catch (error) {
        this.httpservice.get('getAllOrgTypes').then((results: OrgType[]) => {
          local = results;
          localStorage.setItem('orgtypes', JSON.stringify(results));
          resolve(local);
        }).catch(err => {
          reject(err);
        });
      }

    });
  }
  /**
   * Unsubscribe user from an organization
   * @param userid user id
   * @param orgId organizatio id
   */
  unsubUserFromOrg(userid: string, orgId: string) {
    return this.httpservice.post('unsubUserFromOrg', { userId: userid, orgId: orgId });
  }


}
