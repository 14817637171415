import { badges } from './badges';
import { DesignType } from '../../../models/enum/index';

export class DisplayedDesign {
  name: string;
  userName: string;
  sessionNumber: string;
  eventDate: string;
  realTime?: string = Date.now().toString();
  waitingTime: string;
  eventLogoUrl: string;
  userImage: string;
  qrCodeUrl: string;
  isVip?= false;
  type?: DesignType = DesignType.BADGE;

}
interface Coord {
  x: number;
  y: number;
}
interface Property {
  pos: Coord;
  value: string;
}
export class DisplayedAdminDesign {
  eventName: Property;

  eventLogo: Property;

  eventDate: Property;
  qrCodeUrl: Property;
  vqUsers: Property;
  normalQueueSize: Property;
  vips: Property;
  sessionDuration: Property;
  personsPerSession: Property;
}
export class UserBadgeOne extends DisplayedDesign {
  display(userbadge: DisplayedDesign = null) {
    if (userbadge !== null) {
      this.eventDate = userbadge.eventDate;
      this.eventLogoUrl = userbadge.eventLogoUrl;
      this.isVip = userbadge.isVip;
      this.name = userbadge.name;
      this.qrCodeUrl = userbadge.qrCodeUrl;
      this.realTime = userbadge.realTime;
      this.sessionNumber = userbadge.sessionNumber;
      this.type = userbadge.type;
      this.userImage = userbadge.userImage;
      this.waitingTime = userbadge.waitingTime;
    }

    return `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 738 1316.64">
        <defs>
          <clipPath id="a4cd82bd-0eae-4bb4-bb94-a7cddc7793e8">
            <circle cx="366" cy="325.32" r="182" style="fill: none;stroke: #969696;stroke-miterlimit: 10;stroke-width: 4px"/>
          </clipPath>
        </defs>
        <g id="6efe8060-8b78-4a65-8788-a1f830d72ff2" data-name="Calque 11">
          <path d="M697.08,21.32H34.92A23.41,23.41,0,0,0,11.5,44.74V1271.9a23.41,23.41,0,0,0,23.42,
          23.42H697.08a23.41,23.41,0,0,0,23.42-23.42V44.74A23.41,23.41,0,0,0,697.08,21.32ZM486,1149.72a20.6,
          20.6,0,0,1-20.6,20.6H266.6a20.6,20.6,0,0,1-20.6-20.6V950.92a20.6,20.6,0,0,1,20.6-20.6H465.4a20.6,
          20.6,0,0,1,20.6,20.6ZM526,74.9a23.41,23.41,0,0,1-23.42,23.42H229.42A23.41,23.41,0,0,1,206,74.9v-.33a23.42,
          23.42,0,0,1,23.42-23.42H502.58A23.42,23.42,0,0,1,526,74.57Z" style="fill: #fff;
          stroke: #010101;stroke-miterlimit: 10;stroke-width: 4px"/>
          <g>
            <g style="clip-path: url(#a4cd82bd-0eae-4bb4-bb94-a7cddc7793e8)">
              <g>
                <image id="user_picture" height="400px" xlink:href="${this.userImage}" x="160" y="125" />
              </g>
            </g>
            <circle cx="366" cy="325.32" r="182" style="fill: none;stroke: #969696;stroke-miterlimit: 10;stroke-width: 4px"/>
          </g>
          <g>
          <image id="qr_code" xlink:href="${this.qrCodeUrl}" height="225px" x="253" y="936"/>

          </g>
          <g>
            <g>
            <image xlink:href="${this.eventLogoUrl}"  y="1180" x="305" height="100px" id="event_logo" />

            </g>

          </g>
          <g id="0bb8493f-fcae-4127-b723-771020f061b0" data-name="Calque 3">
            <g>
              <path d="M44.26,80.67l-8.08-20h2.34l6.95,17.64,6.95-17.64h2.34l-8.08,20H44.26Z" style="fill: #ec5388"/>
              <g>
                <path d="M71.11,89.51H68.92a19.25,19.25,0,0,1-10.46-3.94,17,17,0,0,1-4.64-5.48.29.29,0,0,1,0-.35A4.62,4.62,0,0,1,
                55.64,81a11.63,11.63,0,0,0,1.83,1.54,2.63,2.63,0,0,0,2.17.25c.45-.14.59-.29.29-.74a21.66,21.66,0,0,1-3-7.65C55.52,
                66.94,57,60.18,62,54.43a19.52,19.52,0,0,1,10.62-6.09c.21,0,.47-.21.57.16a26,26,0,0,0-8.61,5.7c-3.79,3.86-5.85,8.47-5.46,
                13.93C59.58,74.47,64,79.76,70,80.71c3.88.6,6.95-.86,9.48-3.67A35.54,35.54,0,0,0,82,73.88c.27-.37.45-.43.78-.1a5.46,5.46,
                0,0,1,1.76,5.33c-.6,3-2.52,5.13-4.72,7.08A15.07,15.07,0,0,1,71.11,89.51Z" style="fill: #ec5388"/>
                <path d="M81.47,49.28a18.62,18.62,0,0,1,10.26,3.16,17.89,17.89,0,0,1,5.82,6.26c.1.18.16.37,0,.51s-.33,0-.49-.08A6.12,
                6.12,0,0,1,95.66,58a11.58,11.58,0,0,0-1.76-1.48,2.55,2.55,0,0,0-1.44-.43,2.6,2.6,0,0,0-1.15.31l.49.9a24.32,24.32,0,0,1,
                2.71,7.1,25.29,25.29,0,0,1,.53,6,23.34,23.34,0,0,1-2.19,8.94,20.84,20.84,0,0,1-5,6.65,21.36,21.36,0,0,1-9.76,
                4.8c-.16,0-.37.14-.45-.14s.2-.29.33-.35A31.14,31.14,0,0,0,81.8,88.5a22,22,0,0,0,8-8,17,17,0,0,0,2.22-7.79,14.54,14.54,0,0,
                0-1.42-7.28,12.47,12.47,0,0,0-11.28-7.28,9,9,0,0,0-6,2.3,23,23,0,0,0-4.08,4.72c-.23.35-.39.43-.78.1A6,6,0,0,1,67.25,
                58a15.39,15.39,0,0,1,7.14-7.24,13.88,13.88,0,0,1,5.09-1.35A18.78,18.78,0,0,1,81.47,49.28Z" style="fill: #21a5d6"/>
              </g>
              <g>
                <path d="M99.23,63.68a9.06,9.06,0,0,1,7.34-3.47c6.07,0,9.44,4.14,9.44,10.52S113,81.18,107,81.18c-2.38,
                0-4.23-.59-7.61-3.43V90.18H97.06V60.71h2.09Zm.16,12c3.3,3,4.86,3.63,7.1,3.63,5.13,0,
                7.26-3.3,7.26-8.63,0-5.09-2.58-8.64-7.75-8.64a7.5,7.5,
                0,0,0-6.62,3.63Z" style="fill: #21a5d6"/>
                <path d="M122.2,62.16a15.78,15.78,0,0,1,7.42-2c4.39,0,8.86,2.44,8.86,7.42V80.67h-2l-.08-3a10.34,10.34,0,0,1-8,3.49c-4,
                0-7.81-2.22-7.81-6.56,0-4.64,4.16-6.6,8.57-6.6a14.82,14.82,0,0,1,7.08,1.76V67.52c0-3.77-3.53-5.68-6.95-5.68a13.76,13.76,
                0,0,0-6.42,1.74Zm14,9.19a12.77,12.77,0,0,0-6.71-1.81c-3.57,0-6.77,1.46-6.77,5,0,3.24,2.91,4.92,6.19,4.92A9,9,0,0,0,136.21,
                76v-4.7Z" style="fill: #21a5d6"/>
                <path d="M143.9,76.85c1.5,1.44,4.62,2.44,7.57,2.44,3.2,0,6.07-1.19,6.07-3.94,
                0-3.22-3.06-3.53-6.5-4.18-3.9-.7-7.06-1.48-7.06-5.33s4.08-5.58,8.29-5.58A13.18,13.18,0,0,1,159,62l-.72,1.52a12.92,12.92,0,
                0,0-6.11-1.5c-3.14,0-6,1.25-6,3.82s2.19,3.1,
                4.84,3.59c4.53.84,8.7,1.23,8.7,6,0,3.88-4.18,5.62-8.27,5.62-3.18,0-6.62-1-8.41-2.73Z" style="fill: #21a5d6"/>
                <path d="M163.94,76.85c1.5,1.44,4.62,2.44,7.57,2.44,3.2,0,6.07-1.19,6.07-3.94,
                0-3.22-3.06-3.53-6.5-4.18-3.9-.7-7.06-1.48-7.06-5.33s4.08-5.58,8.29-5.58A13.18,13.18,0,0,1,179.08,62l-.72,
                1.52a12.92,12.92,0,0,0-6.11-1.5c-3.14,0-6,1.25-6,3.82s2.19,3.1,
                4.84,3.59c4.53.84,8.7,1.23,8.7,6,0,3.88-4.18,5.62-8.27,5.62-3.18,0-6.62-1-8.41-2.73Z" style="fill: #21a5d6"/>
              </g>
              <g>
                <path d="M76.87,79.23H77a.31.31,0,0,1,0,.49h0l0,0Z" style="fill: #b1cad2"/>
                <path d="M82.1,83.87a2.59,2.59,0,0,1,2.56-2.48,2.09,2.09,0,0,1,2.17,2.17,2.74,2.74,0,0,
                1-2.38,2.6A2.2,2.2,0,0,1,82.1,83.87Z" style="fill: #145d78"/>
                <path d="M89,82.68a1.79,1.79,0,0,1,1.68,1.76,2,2,0,0,1-2,1.85,1.8,1.8,0,0,1-1.68-1.76A2,2,
                0,0,1,89,82.68Z" style="fill: #145d78"/>
                <path d="M80.16,84.71a1.7,1.7,0,0,1-1.64-1.64,1.87,1.87,0,0,1,1.8-1.78,1.65,1.65,0,0,1,1.6,
                1.6A1.84,1.84,0,0,1,80.16,84.71Z" style="fill: #145d78"/>
                <path d="M91.05,89.1a1.45,1.45,0,0,1-1.4-1.39A1.4,1.4,0,0,1,91,86.37a1.44,1.44,0,0,1,1.37,
                1.4A1.32,1.32,0,0,1,91.05,89.1Z" style="fill: #175d78"/>
                <path d="M77,79.74a4.08,4.08,0,0,1,0-.49,1.54,1.54,0,0,1,.39-.59,1.09,1.09,0,0,1,1.68,0,
                1.2,1.2,0,0,1-1.68,1.72A1.25,1.25,0,0,1,77,79.74Z" style="fill: #175d78"/>
                <path d="M78.45,75.4a.84.84,0,0,1,.82.92,1,1,0,0,1-1,.86.84.84,0,0,1-.8-.84A.94.94,0,0,1,
                78.45,75.4Z" style="fill: #185e79"/>
                <path d="M91.72,90.82a1,1,0,0,1-.9.94.81.81,0,0,1-.86-.84,1,1,0,0,1,.86-.92A.8.8,0,0,1,91.72,90.82Z" style="fill: #185e79"/>
              </g>
            </g>
          </g>${this.isVip ? ` <g id="vip">
          <polygon points="355.28 130.09 348.45 148.47 328.79 141.63 342.04 125.82 355.28 130.09" style="fill: #ffc713"/>
          <polygon points="339.76 124.54 332.35 118.55 310.13 130.24 326.37 139.35 339.76 124.54" style="fill: #ffc713"/>
          <polygon points="352.29 149.32 380.5 149.32 373.95 130.81 357.71 130.81 352.29 149.32" style="fill: #ffc713"/>
          <path d="M348.59,151s-17.66-5.13-16.81-4.84,31.06,49.57,31.06,49.57Z" style="fill: #ffc713"/>
          <polygon points="327.22 144.2 312.98 137.36 354.57 187.5 327.22 144.2" style="fill: #ffc713"/>
          <polygon points="377.51 130.09 384.35 148.47 404 141.63 390.76 125.82 377.51 130.09" style="fill: #ffc713"/>
          <polygon points="393.04 124.54 400.44 118.55 422.67 130.24 406.43 139.35 393.04 124.54" style="fill: #ffc713"/>
          <path d="M384.2,151s17.66-5.13,16.81-4.84S370,195.76,370,195.76Z" style="fill: #ffc713"/>
          <polygon points="405.57 144.2 419.82 137.36 378.22 187.5 405.57 144.2" style="fill: #ffc713"/>
          <path d="M352.86,152.74h26.78s-13.39,45.59-13.39,44.16S352.86,152.74,352.86,152.74Z" style="fill: #ffc713"/>
          <polygon points="335.2 116.56 341.47 122.26 356.28 127.67 375.37 127.67 389.62 123.11 397.02 117.41 385.63
           111.72 376.51 109.72 354.57 109.72 342.32 111.72 335.2 116.56" style="fill: #ffc713"/>
        </g>` : ``}

          <text transform="translate(219 568.97)" style="font-size: 76.56463623046875px;fill: #979797;font-family: MyriadPro-Regular,
           Myriad Pro">${this.userName}<tspan style="font-size: 39px;letter-spacing: 0.006009615384615385em">
          <tspan id="session" x="71.83" y="138.68">Session ${this.sessionNumber}</tspan></tspan>
          <tspan style="font-size: 39px"><tspan x="-50" y="185.48" style="letter-spacing: -0.07599659455128205em">
          Your session starts in <tspan id="waiting_time">${this.waitingTime}</tspan></tspan>
          <tspan x="50.03" y="279.08" style="letter-spacing: 0.003981370192307692em" id="event-date">${this.eventDate}</tspan>
          <tspan x="102.95" y="325.88" id="time_from_now" >${this.realTime}</tspan></tspan></text>
        </g>
      </svg>
        `;
  }
}
export class UserBadgeTwo extends DisplayedDesign {
  display(): string {
    return ``;
  }
}
export class AdminBadgeOne extends DisplayedAdminDesign {
  constructor() {
    super();
    this.eventDate = { pos: { x: 0, y: 0 }, value: '' };
    this.eventLogo = { pos: { x: 0, y: 0 }, value: '' };
    this.eventName = { pos: { x: 0, y: 0 }, value: '' };
    this.normalQueueSize = { pos: { x: 0, y: 0 }, value: '' };
    this.personsPerSession = { pos: { x: 0, y: 0 }, value: '' };
    this.qrCodeUrl = { pos: { x: 0, y: 0 }, value: '' };
    this.sessionDuration = { pos: { x: 0, y: 0 }, value: '' };
    this.vips = { pos: { x: 0, y: 0 }, value: '' };
    this.vqUsers = { pos: { x: 0, y: 0 }, value: '' };
  }
  display(adminBadge: any = null) {
    if (adminBadge !== null) {
      this.eventDate = adminBadge.eventDate;
      this.eventLogo = adminBadge.eventLogo;
      this.eventName = adminBadge.eventName;
      this.normalQueueSize = adminBadge.normalQueueSize;
      this.personsPerSession = adminBadge.personsPerSession;
      this.qrCodeUrl = adminBadge.qrCodeUrl;
      this.sessionDuration = adminBadge.sessionDuration;
      this.vips = adminBadge.vips;
      this.vqUsers = adminBadge.vqUsers;

    }
    return `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 760 1324" style="enable-background:new 0 0 760 1324;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;stroke:#010101;stroke-width:4;stroke-miterlimit:10;}
	.st1{clip-path:url(#SVGID_3_);}
	.st2{clip-path:url(#SVGID_7_);}
	.st3{fill:#979797;}
	.st4{font-family:'ArialMT';}
	.st5{font-size:32px;}
	.st6{font-size:52px;}
	.st7{fill:#EC5388;}
	.st8{fill:#21A5D6;}
	.st9{fill:#B1CAD2;}
	.st10{fill:#145D78;}
	.st11{fill:#175D78;}
	.st12{fill:#185E79;}
</style>
<title>badges</title>
<g id="_x36_be259a9-de67-4cab-a7fc-37f8faac539a">
	<path class="st0" d="M711.1,25H48.9C36,25,25.5,35.5,25.5,48.4c0,0,0,0,0,0v1227.2c0,12.9,10.5,23.4,23.4,23.4c0,0,0,0,0,0h662.2
		c12.9,0,23.4-10.5,23.4-23.4c0,0,0,0,0,0V48.4C734.5,35.5,724,25,711.1,25C711.1,25,711.1,25,711.1,25z M220,78.3
		c0-12.9,10.5-23.4,23.4-23.4h273.2c12.9,0,23.4,10.5,23.4,23.4v0.3c0,12.9-10.5,23.4-23.4,23.4c0,0,0,0,0,0H243.4
		c-12.9,0-23.4-10.5-23.4-23.4c0,0,0,0,0,0V78.3z M542,1122.9c0,29-23.5,52.5-52.5,52.5h-219c-29,0-52.5-23.5-52.5-52.5v-219
		c0-29,23.5-52.5,52.5-52.5h219c29,0,52.5,23.5,52.5,52.5V1122.9z"/>
	<g>
		<g>
			<defs>
				<polygon id="SVGID_1_" points="1,1 2,1 1,2 				"/>
			</defs>
			<defs>
				<polygon id="SVGID_2_" points="-1,-1 -2,-1 -1,-2 				"/>
			</defs>
			<clipPath id="SVGID_3_">
				<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
			</clipPath>
			<clipPath id="SVGID_4_" class="st1">
				<use xlink:href="#SVGID_2_"  style="overflow:visible;"/>
			</clipPath>
			<g style="clip-path:url(#SVGID_4_);enable-background:new    ;">
				
					<image style="overflow:visible;" width="100" height="100" id="qr_code" xlink:href="${this.qrCodeUrl.value}"  transform="matrix(1 0 0 1 180 965)">
				</image>
			</g>
		</g>
	</g>
	<g>
		<g>
			<defs>
				<polygon id="SVGID_5_" points="61.4,1.5 62.4,1.5 61.4,2.5 				"/>
			</defs>
			<defs>
				<polygon id="SVGID_6_" points="59.4,-0.5 58.4,-0.5 59.4,-1.5 				"/>
			</defs>
			<clipPath id="SVGID_7_">
				<use xlink:href="#SVGID_5_"  style="overflow:visible;"/>
			</clipPath>
			<clipPath id="SVGID_8_" class="st2">
				<use xlink:href="#SVGID_6_"  style="overflow:visible;"/>
			</clipPath>
		</g>
	</g>
	<text transform="matrix(1 0 0 1 218.1013 456.6566)" class="st3 st4 st5">${this.eventDate.value}</text>
	<text transform="matrix(1 0 0 1 563.0505 500.58)" class="st3 st4 st5"> </text>
	<text transform="matrix(1 0 0 1 282.1795 516.6567)" class="st3 st4 st5">VQ users : ${this.vqUsers.value}</text>
	<text transform="matrix(1 0 0 1 416.0799 560.58)" class="st3 st4 st5"> </text>
	<text transform="matrix(1 0 0 1 257.2655 576.6569)" class="st3 st4 st5">Normal queue : ${this.normalQueueSize.value}</text>
	<text transform="matrix(1 0 0 1 456.908 620.58)" class="st3 st4 st5"> </text>
	<text transform="matrix(1 0 0 1 328.4218 636.6566)" class="st3 st4 st5">VIPs: ${this.vips.value}</text>
	<text transform="matrix(1 0 0 1 377.7055 680.58)" class="st3 st4 st5"> </text>
	<text transform="matrix(1 0 0 1 227.9999 706.6569)" class="st3 st4 st5">Session duration : ${this.sessionDuration.value}</text>
	<text transform="matrix(1 0 0 1 487.2921 750.58)" class="st3 st4 st5"> </text>
	<text transform="matrix(1 0 0 1 217.5515 756.6564)" class="st3 st4 st5">Persons per session: ${this.personsPerSession.value}</text>
	<text transform="matrix(1 0 0 1 274.5146 354.8267)" class="st3 st4 st6" text-anchor="middle" x="100" y="30"> ${this.eventName.value}</text>
</g>
   <g>
          ${this.qrCodeUrl.value ? `
          <image id="qr_code" 
          href="${this.qrCodeUrl.value}" height="300px" width="300px" x="230" y="865"></image>

          ` : ``}

          </g>
           <g>
            ${this.eventLogo.value ? `<image xmlns:xlink="http://www.w3.org/1999/xlink"
             href="${this.eventLogo.value}" y="110" x="280" height="200px" width="200px"
             id="event_logo"></image>` : `
            <image href="assets/img/brand.png"
             y="110" x="280" height="200px" width="200px" id="event_logo"></image>
            `}
          </g>
<g id="_x33_7bfd4c4-debb-4bb6-afc0-8a400525c8c0">
	<g>
		<path class="st7" d="M278.1,1260.5l-12.5-30.8h3.6l10.7,27.3l10.7-27.3h3.6l-12.5,30.9L278.1,1260.5L278.1,1260.5z"/>
		<g>
			<path class="st7" d="M319.6,1274.2h-3.4c-5.9-0.4-11.5-2.5-16.2-6.1c-3-2.3-5.4-5.2-7.2-8.5c-0.1-0.2-0.1-0.4,0-0.5
				c1.1,0.4,2,1.1,2.8,1.9c0.9,0.9,1.8,1.7,2.8,2.4c1,0.6,2.2,0.8,3.4,0.4c0.7-0.2,0.9-0.4,0.5-1.2c-2.3-3.6-3.9-7.6-4.7-11.8
				c-2.1-11.5,0.1-21.9,7.9-30.8c4.3-4.8,10.1-8.1,16.4-9.4c0.3-0.1,0.7-0.3,0.9,0.2c-5,2-9.5,5-13.3,8.8c-5.8,6-9,13.1-8.4,21.5
				c0.7,9.8,7.5,18,16.9,19.4c6,0.9,10.7-1.3,14.7-5.7c1.4-1.6,2.7-3.2,3.9-4.9c0.4-0.6,0.7-0.7,1.2-0.2c2.4,2,3.4,5.2,2.7,8.2
				c-0.9,4.6-3.9,7.9-7.3,10.9C329.2,1272.5,324.5,1273.7,319.6,1274.2z"/>
			<path class="st8" d="M335.6,1212.1c5.7,0,11.2,1.7,15.9,4.9c3.7,2.5,6.8,5.8,9,9.7c0.2,0.3,0.2,0.6,0.1,0.8s-0.5,0-0.8-0.1
				c-0.8-0.4-1.6-1-2.2-1.7c-0.8-0.9-1.7-1.6-2.7-2.3c-0.7-0.4-1.4-0.7-2.2-0.7c-0.6,0-1.2,0.2-1.8,0.5l0.8,1.4
				c2,3.4,3.4,7.1,4.2,11c0.6,3.1,0.9,6.2,0.8,9.3c-0.2,4.8-1.3,9.5-3.4,13.8c-1.8,3.9-4.4,7.4-7.7,10.3c-4.3,3.7-9.5,6.3-15.1,7.4
				c-0.2,0.1-0.6,0.2-0.7-0.2s0.3-0.4,0.5-0.5c2-0.8,4-1.8,5.9-2.9c5.1-2.9,9.4-7.2,12.4-12.3c2.1-3.7,3.3-7.8,3.4-12
				c0.3-3.9-0.5-7.8-2.2-11.3c-3.1-6.8-9.9-11.2-17.4-11.3c-3.4,0-6.7,1.3-9.2,3.6c-2.4,2.1-4.5,4.6-6.3,7.3
				c-0.4,0.5-0.6,0.7-1.2,0.2c-3.5-3-3.8-7.5-2-11.3c2.3-4.9,6.2-8.8,11-11.2c2.5-1.2,5.1-1.9,7.9-2.1
				C333.5,1212.2,334.6,1212.1,335.6,1212.1z"/>
		</g>
		<g>
			<path class="st8" d="M363,1234.3c2.7-3.5,6.9-5.5,11.3-5.4c9.4,0,14.6,6.4,14.6,16.3c0,10-4.7,16.2-14,16.2
				c-3.7,0-6.5-0.9-11.8-5.3v19.2h-3.5v-45.5h3.2L363,1234.3z M363.3,1252.9c5.1,4.7,7.5,5.6,11,5.6c7.9,0,11.2-5.1,11.2-13.3
				c0-7.9-4-13.4-12-13.4c-4.2-0.1-8.1,2-10.2,5.6V1252.9z"/>
			<path class="st8" d="M398.5,1231.9c3.5-2,7.4-3,11.5-3.1c6.8,0,13.7,3.8,13.7,11.5v20.2h-3.2l-0.1-4.6c-3.1,3.5-7.7,5.5-12.4,5.4
				c-6.2,0-12.1-3.4-12.1-10.1c0-7.2,6.4-10.2,13.2-10.2c3.8,0,7.6,0.9,11,2.7v-3.5c0-5.8-5.5-8.8-10.7-8.8c-3.5,0.1-6.9,1-9.9,2.7
				L398.5,1231.9z M420.1,1246.1c-3.1-1.9-6.7-2.8-10.4-2.8c-5.5,0-10.5,2.3-10.5,7.7c0,5,4.5,7.6,9.6,7.6c4.4,0.2,8.6-1.8,11.3-5.2
				L420.1,1246.1L420.1,1246.1z"/>
			<path class="st8" d="M432.1,1254.6c2.3,2.2,7.1,3.8,11.7,3.8c5,0,9.4-1.8,9.4-6.1c0-5-4.7-5.5-10-6.4c-6-1.1-10.9-2.3-10.9-8.2
				s6.3-8.6,12.8-8.6c3.7-0.1,7.3,0.9,10.5,2.7l-1.1,2.3c-2.9-1.5-6.2-2.3-9.4-2.3c-4.9,0-9.2,1.9-9.2,5.9s3.4,4.8,7.5,5.6
				c7,1.3,13.5,1.9,13.5,9.2c0,6-6.5,8.7-12.8,8.7c-4.9,0-10.2-1.5-13-4.2L432.1,1254.6z"/>
			<path class="st8" d="M463,1254.6c2.3,2.2,7.2,3.8,11.7,3.8c5,0,9.4-1.8,9.4-6.1c0-5-4.7-5.5-10-6.4c-6-1.1-10.9-2.3-10.9-8.2
				s6.3-8.6,12.8-8.6c3.7-0.1,7.3,0.9,10.5,2.7l-1.1,2.3c-2.9-1.5-6.2-2.3-9.4-2.3c-4.9,0-9.2,1.9-9.2,5.9s3.4,4.8,7.5,5.6
				c7,1.3,13.5,1.9,13.5,9.2c0,6-6.5,8.7-12.8,8.7c-4.9,0-10.2-1.5-13-4.2L463,1254.6z"/>
		</g>
		<g>
			<path class="st9" d="M328.5,1258.3h0.1c0.2,0.2,0.2,0.5,0.1,0.7c0,0,0,0.1-0.1,0.1h-0.1h-0.1V1258.3z"/>
			<path class="st10" d="M336.6,1265.5c0.1-2.1,1.9-3.8,4-3.8c1.8-0.1,3.3,1.3,3.4,3.1c0,0.1,0,0.2,0,0.2c0.1,2.2-2.3,4-3.7,4
				c-1.9,0.2-3.5-1.2-3.7-3.1C336.6,1265.8,336.6,1265.6,336.6,1265.5z"/>
			<path class="st10" d="M347.3,1263.7c1.4,0.1,2.6,1.3,2.6,2.7c-0.1,1.6-1.4,2.9-3,2.9c-1.4-0.1-2.6-1.3-2.6-2.7
				C344.3,1264.9,345.7,1263.6,347.3,1263.7z"/>
			<path class="st10" d="M333.6,1266.8c-1.4-0.1-2.5-1.2-2.5-2.5c0.1-1.5,1.3-2.7,2.8-2.7c1.3,0,2.4,1.1,2.5,2.5
				C336.3,1265.5,335.1,1266.7,333.6,1266.8z"/>
			<path class="st11" d="M350.4,1273.6c-1.2,0-2.1-1-2.2-2.1c0-1.1,1-2,2.1-2.1c1.2,0.1,2.1,1,2.1,2.2
				C352.4,1272.6,351.6,1273.5,350.4,1273.6C350.4,1273.6,350.4,1273.6,350.4,1273.6z"/>
			<path class="st11" d="M328.6,1259.1c0-0.2,0-0.5,0-0.8c0.1-0.3,0.3-0.6,0.6-0.9c0.6-0.7,1.7-0.8,2.4-0.2c0.1,0.1,0.2,0.1,0.2,0.2
				c0.7,0.8,0.6,2-0.2,2.6c-0.7,0.6-1.7,0.6-2.4,0C328.9,1259.8,328.7,1259.5,328.6,1259.1z"/>
			<path class="st12" d="M330.9,1252.4c0.7,0,1.3,0.6,1.3,1.3c0,0,0,0.1,0,0.1c-0.1,0.8-0.7,1.3-1.5,1.3c-0.7,0-1.2-0.6-1.2-1.3
				C329.4,1253,330.1,1252.4,330.9,1252.4C330.9,1252.4,330.9,1252.4,330.9,1252.4z"/>
			<path class="st12" d="M351.4,1276.2c0,0.8-0.6,1.4-1.4,1.4c-0.7,0-1.3-0.5-1.3-1.2c0,0,0-0.1,0-0.1c0-0.7,0.6-1.3,1.3-1.4
				c0.7-0.1,1.3,0.4,1.4,1.1C351.4,1276.1,351.4,1276.2,351.4,1276.2z"/>
		</g>
	</g>
</g>
</svg>



        `;
  }
}
