import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../service/auth-service/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss']
})
export class EmailValidationComponent implements OnInit {

  public isModalShown = true;
  public options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true
  };
  @ViewChild('autoShownModal') public autoShownModal: ModalDirective;
  constructor(private authService: AuthService,
    private router: Router,
    private _notificationsService: NotificationsService,
    private translateservice: TranslateService
  ) { }
  ngOnInit(): void { }
  /** */
  public showModal(): void {
    this.isModalShown = true;
  }
  /** */
  public hideModal(): void {
    this.autoShownModal.hide();
  }
  /** */
  public onHidden(): void {
    this.isModalShown = false;
  }
  verify() {
    this.authService.sendVerification().then(success => {
      this.translateservice.get('notifications.user.emailverification.emailsent').subscribe(values => {
        this._notificationsService.success(values);
      });
      setTimeout(() => this.router.navigate(['/home']), 2000);
    }).catch(error => {
      this.translateservice.get('notifications.user.emailverification.error').subscribe(values => {
        this._notificationsService.success(values);
      });
    });
  }
}
