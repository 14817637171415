import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BadgeDesign, User } from '../../../models';
import { ToolboxModalComponent } from '../toolbox-modal/toolbox-modal.component';

@Component({
  selector: 'app-modal-service-toolbox',
  templateUrl: './modal-service-toolbox.component.html',
  styleUrls: ['./modal-service-toolbox.component.scss']
})
export class ModalServiceToolboxComponent {
  bsModalRef: BsModalRef;
  @Input() add = false;
  @Input() edit = false;
  @Input() badgeDesign: BadgeDesign = new BadgeDesign();
  @Input() currentUser: User;
  @Output() addClicked: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: BsModalService) { }
  openModalWithComponent() {

    this.bsModalRef = this.modalService.show(ToolboxModalComponent, { class: 'modal-lg' });
    this.bsModalRef.content.currentUser = this.currentUser;
    this.bsModalRef.content.badgeDesign = this.badgeDesign;
    this.bsModalRef.content.addClicked.subscribe((value) => {
      console.log('badge_design-->', value);
      this.bsModalRef.hide();
      this.addClicked.emit({ option: 'refresh' });
    });
    this.bsModalRef.content.add = this.add;
    this.bsModalRef.content.edit = this.edit;

  }
}
