// import {orgType} from '../../../../orgType.ts'

export enum ORGTYPE {
    OTHERS = 0,
    HOSPITAL, // 1
    UNIVERSITY, // 2
    IT, // 3
    AIRPORT, // 4
    RETAIL, // 5
    GOVERNMENT // 6
}

export class OrgType {
    name: string;
    id: string;
    description: string;
    identifier: number;
    admin: string;
    manager1: string;
    manager2: string;
    user: string;
    venueName: string;
    eventName: string;

}
