import { Pipe, PipeTransform } from '@angular/core';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { User } from '../../models';
import { OrganizationProviderService } from '../../service/providers/organization-provider.service';

@Pipe({
  name: 'displayOrganizationName'
})
export class DisplayOrganizationNamePipe implements PipeTransform {

  constructor(private usersProvider: UsersProvider, private orgProv: OrganizationProviderService) { }

  transform(value: string, type: string, plural = false): any {
    return new Promise((resolve, reject) => {
      let newValue = value;
      this.usersProvider.getCurrentUser().then((user: User) => {
        if (user.organizationId && user.organization) {
          // console.log('I AM HERE');
          this.orgProv.getAllOrgTypes().then((types: any[]) => {
            const ftype = types.find(item => Number(item.identifier) === Number(user.organization.type));
            newValue = type === 'venue' ? ftype.venueName : ftype.eventName;

            resolve(newValue);
          }).catch(err => {
            console.log(err);
            resolve(newValue);
          });
        } else {
          resolve(newValue);
        }
      }).catch(() => resolve(newValue));
    });
  }

  getSpecificName(venueName = null) {
    let value = 'Event';
    if (venueName) {
      switch (venueName) {
        case 'University': value = 'Course'; break;
        case 'Hospital': value = 'Schedule'; break;
      }
    }
    return value;
  }

}
