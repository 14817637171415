import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UsersProvider } from '../providers/users-provider.service';
import { User } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CanActivateViaOrganizationService implements CanActivate {


  constructor(private usrProvider: UsersProvider, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    return new Promise(resolve => {
      this.usrProvider.getCurrentUser().then((user: User) => {
        if (user && user.organizationId) {
          resolve(true);
        } else {
          if (user) {
            this.router.navigate(['setup-organization']);
          }
          resolve(false);
        }
      });

    });
  }
}
