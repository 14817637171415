import {Util} from './tools/index';
import {IQRCode, ISVG} from './interfaces/index';
import {DesignType} from './enum/index';

// badge--Design-----------------------------------------------------------
export class BadgeDesign {
  name? = '';
  primaryColor? = '';
  secondaryColor? = '';
  userid? = '';
  /**regenaration time value by default is 5 minutes*/
    // regenerationTime?: number; // minutes
  type?: DesignType;
  adminsvg? = '';
  usersvg? = '';
  adminxml? = '';
  userxml? = '';
  kind?: string;
  id?: string;
  default? = false;
  isTemplate? = true;
  organizationId? = '';

  constructor(id = '') {
    this.type = DesignType.BADGE;
    if (id !== '') {
      this.id = id;
    }
    this.kind = 'BadgeDesign';
    Object.defineProperties(this, {
      id: {enumerable: false},
      kind: {enumerable: false}
    });
    this.adminxml = `<mxGraphModel grid="1" gridSize="10" guides="1" tooltips="1" connect="1" arrows="1" fold="1" page="1"
         pageScale="1" pageWidth="827" pageHeight="1169" background="#ffffff">
    <root><mxCell id="0"/><mxCell id="1" parent="0"/></root>
    </mxGraphModel>`;
    this.userxml = this.adminxml;
  }
}

