import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InterfaceComponent } from './interface.component';
import { CanActivateViaAuthGuardService } from '../service/CanActivateViaAuthGuard/can-activate-via-auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VenuesComponent } from './venues/venues.component';
import { EventsComponent } from './events/events.component';
import { ProfileComponent } from './profile/profile.component';
import { UsersComponent } from './users/users.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { BadgeDesignComponent } from './badge-design/badge-design.component';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { SupportComponent } from './support/support.component';
import { CanActivateViaOrganizationService } from '../service/can-activate-via-organization/can-activate-via-organization.service';
import { OrganizationUsersComponent } from './organization-users/organization-users.component';
import { CanActivateAllowAccessViaRolesService } from '../service/can-activate-allow-access/can-activate-allow-access-via-roles.service';
import { ROLES } from '../models/enum/roles';
import { ORGTYPE } from '../models/enum';

const routes: Routes = [
  {
    path: 'interface',
    component: InterfaceComponent,
    canActivate: [CanActivateViaAuthGuardService, CanActivateViaOrganizationService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'venues',
        component: VenuesComponent,
        canActivate: [CanActivateAllowAccessViaRolesService],
        data: {
          allOrgs: true,
          roles: [ROLES.ADMINISTRATOR]
        }
      },
      {
        path: 'events',
        component: EventsComponent
      },
      {
        path: 'organization-users',
        component: OrganizationUsersComponent,

        data: {
          allOrgs: true,
          roles: [ROLES.ADMINISTRATOR, ROLES.MANAGERONE]
        }
      },
      // {
      //   path: 'events/map',
      //   component: MapComponent
      // },
      // {
      //   path: 'events/manage',
      //   component: ManageEventsComponent
      // },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'analytics',
        component: AnalyticsComponent
      },
      {
        path: 'badgeDesign',
        component: BadgeDesignComponent
      },
      {
        path: 'toolbox',
        component: ToolboxComponent
      },
      {
        path: 'support',
        component: SupportComponent
      },
      // {
      //   path: 'becomeManager/:EventId/:Code',
      //   component: BecomeManagerComponent
      // },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'

      }
    ]
  }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InterfaceRoutingModule { }
