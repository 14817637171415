export class SocialMedia {
    name: string;
    smThreshold?: any;
    message: string;
    eventId: string;
    hashtag: string;
    id?: string;
    kind?: string;
    constructor({name, message, eventId}) {
        this.kind = 'SocialMedia';
        this.name = name;
        this.message = message;
        this.eventId = eventId;
        Object.defineProperties(this, {
            id: { enumerable: false },
            kind: { enumerable: false }
        });
    }
}
