import { Pipe, PipeTransform } from '@angular/core';
import { DesignType } from '../models/enum';

@Pipe({
  name: 'filterByType'
})
export class FilterByTypePipe implements PipeTransform {

  transform(values, type: DesignType): any {
    // let list = [];

    return values.filter(value =>
        '' + value.type === type + ''

    );
}

}
