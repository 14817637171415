import { EventMember } from './index';
import { IEventAdmin, IEventManager, IFriend, ISwapRequest, IEventMember, IValidation } from './interfaces/index';
import { TimeStamp } from './tools/index';
import { Organization } from './organization';
import { ROLES } from './enum/roles';

/**
 * Class User
 * @version 0.0.8
 */
export class User {
    /**First name */
    firstName: string;
    /**Last name */
    lastName: string;
    /**Email */
    email: string;
    /**Phone number */
    phone?: string;
    // tslint:disable-next-line:whitespace
    profilePictureURL?= '';
    /**is the user premium or not */
    isPremium?: boolean;
    /**Time of the user' registration */
    registeredAt: number = Date.now();
    /**The events where this user is registred */
    eventsMember: IEventMember[];
    /**The list of event that the user created */
    eventsAdmin: IEventAdmin[];
    /**The list of events that the user manages */
    eventsManager: IEventManager[];
    /**The friend list */
    friends: IFriend[];
    /**Swap request list */
    swapRequests: ISwapRequest[];
    keyWords: string[];
    id: string; // must be given because it is the user id from the firebase currentUserId
    kind?: string;
    organizationId?: string;
    organization?: Organization;
    role?: ROLES;
    validation?: IValidation;
    checked?: boolean;
    constructor(obj: any = {}) {
        this.firstName = obj.firstName ? obj.firstName : '';
        this.lastName = obj.lastName ? obj.lastName : '';
        this.email = obj.email ? obj.email : '';
        this.isPremium = false;
        this.id = obj.id;
        this.keyWords = [this.email, this.firstName.toLowerCase(), this.lastName.toLowerCase()];
        this.kind = 'User';
        this.swapRequests = [];
        this.eventsAdmin = [];
        this.eventsManager = [];
        this.eventsMember = [];
        this.organizationId = obj.organizationId;
        this.role = obj.role || undefined; // Admin | Manager1 | Manager2 | User
        this.organizationId = obj.organizationId || undefined;
        this.organization = obj.organization || undefined;
        this.validation = obj.validation || undefined;
        Object.defineProperties(this, {
            id: { enumerable: false },
            kind: { enumerable: false }
        });
    }
    public static fromUser(user: User): User {
        const cuser: User = {
            email: user.email,
            eventsAdmin: user.eventsAdmin,
            eventsMember: user.eventsMember,
            eventsManager: user.eventsManager,
            firstName: user.firstName,
            friends: user.friends,
            id: user.id,
            kind: user.kind,
            isPremium: user.isPremium,
            keyWords: user.keyWords,
            lastName: user.lastName,
            phone: user.phone,
            registeredAt: user.registeredAt,
            swapRequests: user.swapRequests
        };
        Object.defineProperties(cuser, {
            id: { enumerable: false },
            kind: { enumerable: false }
        });
        return cuser;
    }
}
