import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeEnumToStrPipe } from '../pipes/type-enum-to-str.pipe';
import { HomeNavigationComponent } from './home-navigation/home-navigation.component';
import { InterfaceNavigationComponent } from './interface-navigation/interface-navigation.component';
import { SliderComponent } from './slider/slider.component';
import { HomeFooterComponent } from './home-footer/home-footer.component';
import { AuthService } from '../service/auth-service/auth.service';
import { VQEventsProviderService } from '../service/providers/v-qevents-provider.service';
import { UsersProvider } from '../service/providers/users-provider.service';
import { AngularFireAuth, AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireModule } from 'angularfire2';
import { CONFIG } from '../../environments/config';
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { SimpleNotificationsModule, NotificationsService } from 'angular2-notifications';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterfaceSidebarComponent } from './interface-sidebar/interface-sidebar.component';
import { SafeHTMLPipe } from '../pipes/safe-html.pipe';
import { FilterByTypePipe } from '../pipes/filter-by-type.pipe';
import { DisplayOrganizationNamePipe } from '../pipes/display-organization-name/display-organization-name.pipe';
import { FilterUsersByRolesPipe } from '../pipes/filter-users-by-roles.pipe';
import { RoleTOStrPipe } from '../pipes/role-tostr.pipe';
import { ChatComponent } from './chat/chat.component';
import { ChatConversationComponent } from './chat-conversation/chat-conversation.component';
import { ChatSidebarComponent } from './chat-sidebar/chat-sidebar.component';
import { CanAccessDirective } from '../directives/can-access/can-access.directive';
import { PickGroupMembersComponent } from './pick-group-members/pick-group-members.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterEventByPipe } from '../pipes/filter-event-by.pipe';


import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

@NgModule({
  imports: [
    CommonModule, HttpModule, RouterModule,
    AngularFireModule.initializeApp(CONFIG.FIREBASECONFIG), // must be initialized
    SimpleNotificationsModule.forRoot(), FormsModule, BrowserAnimationsModule, PerfectScrollbarModule, TooltipModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

    // SortablejsModule.forRoot({ animation: 150 })
  ],
  declarations: [TypeEnumToStrPipe, HomeNavigationComponent, InterfaceNavigationComponent, FilterByTypePipe, RoleTOStrPipe, SafeHTMLPipe,
    SliderComponent, HomeFooterComponent, InterfaceSidebarComponent, DisplayOrganizationNamePipe,
    FilterUsersByRolesPipe, ChatComponent, ChatConversationComponent, ChatSidebarComponent, CanAccessDirective, PickGroupMembersComponent,
    FilterEventByPipe],
  exports: [TypeEnumToStrPipe, FilterByTypePipe, SafeHTMLPipe, HomeNavigationComponent, InterfaceNavigationComponent, TranslateModule,
    SliderComponent, HomeFooterComponent, InterfaceSidebarComponent,
    DisplayOrganizationNamePipe, FilterUsersByRolesPipe, ChatComponent, ChatConversationComponent,
    ChatSidebarComponent, RoleTOStrPipe, CanAccessDirective, FilterEventByPipe],
  providers: [AuthService, VQEventsProviderService, UsersProvider,
    AngularFireAuth, AngularFireAuthModule, AngularFireDatabase, NotificationsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
