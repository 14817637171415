import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { User } from '../../models';
import { ROLES } from '../../models/enum/roles';
import { Utils, RouterData } from '../../utils/utils';


@Directive({
  selector: '[appCanAccess]'
})
export class CanAccessDirective implements OnInit {

  @Input() canAccess: RouterData;

  constructor(private elementRef: ElementRef, private _usersProvider: UsersProvider) { }

  ngOnInit(): void {

    this._usersProvider.getCurrentUser().then((user: User) => {
      return Utils.allow(this.canAccess, user).then((returned: boolean) => {
        if (!returned) {
          this.elementRef.nativeElement.remove();
        }
      });
    }).catch(err => {
      console.log('CanAccessDirective', err);
      this.elementRef.nativeElement.remove();
    });
  }

}
