import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth-service/auth.service';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-home-navigation',
  templateUrl: './home-navigation.component.html',
  styleUrls: ['./home-navigation.component.scss']
})
export class HomeNavigationComponent implements OnInit {
  isLogged = false;
  isVerified = false;
  currentUser: any = {};
  loading = false;
  en = true;
  constructor(private authService: AuthService, private router: Router,
    private usersProvider: UsersProvider, private translate: TranslateService) {
    try {
      this.en = JSON.parse(localStorage.getItem('en'));
    } catch (error) {
      this.en = JSON.parse(localStorage.getItem('en'));

    }
    if (this.en === null) {
      this.en = true;
      localStorage.setItem('en', JSON.stringify(this.en));
    }

  }
  toggleLangage() {
    this.en = !this.en;
    localStorage.setItem('en', JSON.stringify(this.en));

    this.translate.use(this.en ? 'en' : 'fr');
    location.reload();
  }
  logOut() {
    console.log('login out ...');
    this.authService.logout();
    window.location.reload();
  }
  ngOnInit(): void {
    this.loading = true;
    if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {
      this.loading = true;
      this.usersProvider.getCurrentUser().then(User => {
        this.loading = false;
        if (User !== null) {
          this.currentUser = User;

          localStorage.setItem('CurrentUser', JSON.stringify(User));
          this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
          this.isLogged = true;
          this.isVerified = this.currentUser['emailVerified'];
        }

      }).catch(err => {
        console.log(err);
      });

    } else {
      this.loading = false;
      this.isLogged = true;
      this.isVerified = this.currentUser['emailVerified'];
      this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    }
  }
}
