import { Component, OnInit, Inject, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import * as $ from 'jquery';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit {

  public options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true

  };
  en = true;
  constructor(@Inject(DOCUMENT) private document, private route: ActivatedRoute, private notificationservice: NotificationsService) {
    try {
      this.en = JSON.parse(localStorage.getItem('en'));
    } catch (error) {

    }
    if (this.en === null || this.en === undefined) {
      this.en = true;
    }
  }
  ngAfterViewInit(): void {
    console.log(this.route.snapshot.queryParamMap.get('message'));
    if (this.route.snapshot.queryParamMap.get('message')) {
      this.notificationservice.error(this.route.snapshot.queryParamMap.get('message'));
    }
  }
  ngOnInit() {

    this.document.getElementById('theme')
      .setAttribute('href', './assets/css/home.css');
    for (let i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }

    setInterval(() => {
      // console.log(this.en);
      $('#howto-img').attr('src', 'assets/img/howto-design' + (Math.floor(Math.random() * 2) + 1) + (this.en ? '' : '_fr') + '.png');
    }, 2000);
    $('#buttondown').click(function () {
      $('html, body').animate({
        scrollTop: $('#features').offset().top
      }, 1200);
    });
    $('#button-howto-section').click(function () {
      $('html, body').animate({
        scrollTop: $('#howto-section').offset().top
      }, 1200);
    });
  }
}

