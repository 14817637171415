import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { User } from '../../../models';
import { ModalOrgUsersComponent } from '../modal-org-users/modal-org-users.component';

@Component({
  selector: 'app-modal-service-org-users',
  templateUrl: './modal-service-org-users.component.html',
  styleUrls: ['./modal-service-org-users.component.scss']
})
export class ModalServiceOrgUsersComponent {
  bsModalRef: BsModalRef;
  @Input() add = false;
  @Input() edit = false;
  @Input() currentUser = null;
  @Output() addClicked: EventEmitter<any> = new EventEmitter();
  @Input() user: User = new User();
  constructor(private modalService: BsModalService) { }
  openModalWithComponent() {
    this.bsModalRef = this.modalService.show(ModalOrgUsersComponent, { class: 'modal-lg' });
    /**patch 1.4.2 */
    this.bsModalRef.content.addClicked.subscribe(($user) => {
      this.bsModalRef.hide();
      if ($user.failed !== true) {
      this.addClicked.emit($user);
      }
    });
    /**-- */
    this.bsModalRef.content.add = this.add;
    this.bsModalRef.content.edit = this.edit;
    this.bsModalRef.content.user = this.user;
    if (this.edit) {
      this.bsModalRef.content.oldEmail = this.user.email;
    }
    this.bsModalRef.content.currentUser = this.currentUser;
  }

}
