import { Injectable, Inject } from '@angular/core';
import { OnInit } from '@angular/core';

import { VQEventsProviderService } from './v-qevents-provider.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpService } from '../http/http.service';
import { ImageUploaderService } from './image-uploader.service';
import { AuthService } from '../auth-service/auth.service';
import { User } from '../../models';
import { MemberStatus } from '../../models/enum';
@Injectable()
export class UsersProvider implements OnInit {
    constructor(@Inject(AngularFireDatabase) private database: AngularFireDatabase,
        private vQeventsProvider: VQEventsProviderService, private httpservice: HttpService, private authService: AuthService,
        private imageUploader: ImageUploaderService) {
        this.ngOnInit();
    }
    public sendNotification(body) {
        return this.httpservice.post('sendNotification', body);
    }
    public getAllUsers() {
        return new Promise((resolve, reject) => {
            this.httpservice.get('getallusers').then((success: any) => {
                let userlist = [];
                userlist = success;
                userlist.forEach(user => {
                    // Be carefull here we need to make these attributes non-enumerable so thez don't end up in the database as fields
                    Object.defineProperties(user, {
                        id: { enumerable: false },
                        kind: { enumerable: false }
                    });
                });
                resolve(userlist);
            }).catch(err => {
                reject(err);
            });
        });
        /*
        return this.Users$.do(() => { }).map(models.FirebaseUser.fromJsonList);
        */
    }
    public getUsersNotManagerOrAdmin(eventId) {
        const body = { data: eventId };
        return new Promise((resolve, reject) => {
            this.httpservice.post('getUsersNotManagerOrAdmin', body).then(list => {
                resolve(list);
            }).catch(err => reject(err));
        });
    }
    /**
     * get current app user
     */
    getCurrentUser(force = false) {
        return new Promise((resolve, reject) => {
            let user;
            try {
                user = JSON.parse(localStorage.getItem('CurrentUser'));
            } catch (error) {
                // do nothing
            }
            if (user && user.organization && !force) {
                resolve(user);
            } else {
                this.authService.getCurrentFirebaseUser().then((u: any) => {
                    if (u) {
                        this.getWithUid(u.uid).then(res => {
                            localStorage.setItem('CurrentUser', JSON.stringify(res));
                            resolve(res);
                        }).catch(err => {
                            reject(err);
                        });
                    } else {
                        resolve(null);
                    }
                }).catch(err => {
                    reject(err);
                });
            }
        });

    }
    /**
     * get a user from his fire base uid
     * @param uid user Id
     */
    getWithUid(uid: string) {
        return new Promise((resolve, reject) => {
            const body = { id: uid };
            // console.log('---->',uid)
            this.httpservice.post('getUser', body).then((success) => {
                resolve(<User>success);
            }).catch(err => {
                reject(err);
            });
        });
    }
    /**
     * This methode updates the user into datastore
     * @param user
     * @version 0.0.1
     */
    updateUser(user: User) {
        const body = { updates: user, id: user.id };
        return new Promise((resolve, reject) => {
            this.httpservice.post('updateUser', body).then((resp: any) => {
                resolve(resp);
            });
        });
    }
    updateProfilePicture(data, type, uid) {
        return new Promise((resolve, reject) => {
            this.imageUploader.uploadImage(data, 'user_image', `${uid}.png`).then((success: any) => {
                const user: any = {};
                user.id = uid;
                user.profilePictureURL = success.downloadURL;
                this.updateUser(user).then((updatesuccess: any) => {
                    this.getCurrentUser().then((fuser: User) => {
                        localStorage.setItem('CurrentUser', JSON.stringify(fuser));
                    });
                });
                resolve(success);
            }).catch(err => reject(err));

        });
    }
    getProfilePicture(uid) {
        return new Promise((resolve, reject) => {
            this.imageUploader.downloadImage('user_image', `${uid}.`).then((url) => {
                console.log('prov', url);
                resolve(url);
            }).catch((err) => reject(err));
        });
    }
    /**
     * Delete a user from database
     */
    deleteuser(userId: string) {
        return new Promise((resolve, reject) => {
            const body = { id: userId };
            this.httpservice.post('deleteuser', body).then((resp: any) => {
                resolve(resp);
            }).catch(err => reject(err));
        });
    }
    /**
     * This function is checking if the user is premium or not
     * @param userId
     * @version 2.0.0
     */
    /*IsPremium(userId: string) {
        return new Promise((resolve, reject) => {
            let body = {};
            body = { id: userId };
            this.httpservice.post('ispremium', body).then((resp: any) => {
                resolve(resp);
            }).catch(err => reject(err));
        });
    }*/
    /**
     *Change premium value
     * @param userkey
     * @param premium
     */
    TogglePremium(userId: string) {
        console.log(userId);
        return new Promise((resolve, reject) => {
            let body = {};
            body = { id: userId };
            this.httpservice.post('togglePremium', body).then((resp: any) => {
                resolve(resp);
            }).catch(err => reject(err));
        });
    }
    HasRole(uid: string, RoleName: string) {
        // console.log(uid,RoleName)
        return new Promise((resolve, reject) => {
            resolve(true);

        }); // promise
    }
    AddUser(user: User, overwrite: boolean = false) {
        const body = { data: user, overwrite: overwrite, id: user.id };
        // console.log("User to add-->",User);
        return new Promise((resolve, reject) => {
            this.getWithUid(user.id).then((foundUser: any) => {
                console.log('Found User', foundUser);
                if (foundUser.status === 404) {
                    this.httpservice.post('addUser', body).then(success => resolve(success)).catch(err => reject(err));
                } else {
                    resolve({ success: true, message: 'User already added' });
                }
            }).catch(err => {
                reject(err);
            });

        });
    }
    deleteUser(user: User) { }
    changeEventMemberStatus(EventMemberid: string, status: MemberStatus) {
        const body = { updates: { status: status }, id: EventMemberid };
        return new Promise((resolve, reject) => {
            this.httpservice.post('updateEventMember', body).then(success => resolve(success)).catch(err => reject(err));
        });
    }
    ngOnInit(): void {
    }

    searchUser(keyword) {
        return new Promise((resolve, reject) => {
            if (keyword && keyword.length > 0) {
                const body = {
                    keyword: keyword
                };
                this.httpservice.post('searchUser', body)
                    .then(success => resolve(success))
                    .catch(err => reject(err));
            } else {
                reject({ success: false, message: 'Please enter a keyword!' });
            }
        });
    }

    assignUsers(users, organizationId, role) {
        return new Promise((resolve, reject) => {
            const body = {
                users: users,
                orgId: organizationId,
                role: role
            };
            this.httpservice.post('assignUsers', body)
                .then(success => resolve(success))
                .catch(err => reject(err));
        });
    }

}
