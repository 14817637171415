import { Component, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Venue, User } from '../../../models';
import { ORGTYPE } from '../../../models/enum';
import { VQEventsProviderService } from '../../../service/providers/v-qevents-provider.service';
import { VenuesProviderService } from '../../../service/providers/venues-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { BsModalRef } from 'ngx-bootstrap';
import { UsersProvider } from '../../../service/providers/users-provider.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
    selector: 'app-venue-modal',
    templateUrl: './venue-modal.component.html',
    styleUrls: ['./venue-modal.component.scss']
})
export class VenueModalComponent implements OnInit, AfterViewInit {
    // select variables
    page = 'Venue';
    selectedValue: any = [];
    disabled = false;
    types: any[] = [];
    value: any = {};
    positions: any = [];
    venue: Venue = new Venue();
    add = false;
    edit = false;
    show = false;
    currentUser: User;
    @Output()
    addClicked: EventEmitter<any> = new EventEmitter();
    loading = false;
    public options = {
        position: ['bottom', 'right'],
        timeOut: 5000,
    };

    constructor(
        public bsModalRef: BsModalRef,
        private vqeventprovider: VQEventsProviderService,
        private venuesProvider: VenuesProviderService,
        private _notificationsService: NotificationsService,
        private userProvider: UsersProvider,
        private translateService: TranslateService
    ) {
        if (!!navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (this.venue === undefined) {
                    this.venue = new Venue();
                }
                this.venue.gpsCoord.lat = position.coords.latitude;
                this.venue.gpsCoord.lng = position.coords.longitude;
            });
            // Support
        } else {
            // No support
        }
    }
    ngAfterViewInit(): void {

    }
    onMapReady(map) {
        // console.log('map', map);
        // console.log('markers', map.markers);  // to get all markers as an array
    }
    onIdle(event) {
        // console.log('map', event.target);
    }
    onMarkerInit(marker) {
        if (this.edit || this.add) {
            console.log('LATLNG', marker.getPosition().lat(), marker.getPosition().lng());
            this.venue.gpsCoord.lat = marker.getPosition().lat();
            this.venue.gpsCoord.lng = marker.getPosition().lng();
        }

    }
    onMapClick(event) {
        if (this.positions.length === 0) {
            this.positions.push(event.latLng);
        }
        if (this.edit || this.add) {
            this.venue.gpsCoord.lat = event.latLng.lat();
            this.venue.gpsCoord.lng = event.latLng.lng();
        }
        // console.log(event.latLng.lat());
        // event.target.markers[0]=;
        // event.target.panTo(event.latLng);
    }
    // --- select type methods
    /**
       * Selected value
       */
    public selected(value: any): void {
        this.venue.type = value.id;
        console.log('Selected value is: ', value);
    }
    /**
     * Removed value
     * @param value
     */
    public removed(value: any): void {
        console.log('Removed value is: ', value);
    }
    /**
     * Search input
     * @param value
     */
    public typed(value: any): void {
        console.log('New search input: ', value);
    }
    public refreshValue(value: any): void {
        this.value = value;
    }
    /**
     *
     */
    ngOnInit(): void {
        this.types.push({ id: ORGTYPE.UNIVERSITY, text: 'University' });
        this.types.push({ id: ORGTYPE.AIRPORT, text: 'Airport' });
        this.types.push({ id: ORGTYPE.GOVERNMENT, text: 'Government' });
        this.types.push({ id: ORGTYPE.IT, text: 'IT' });
        this.types.push({ id: ORGTYPE.RETAIL, text: 'Retail' });
        this.types.push({ id: ORGTYPE.HOSPITAL, text: 'Hospital' });
        this.types.push({ id: ORGTYPE.OTHERS, text: 'Others' });
        if (this.venue.type !== undefined) {
            // console.log(this.vqEvent.type);
            this.types.forEach(element => {
                if (element.id === this.venue.type) {
                    this.selectedValue[0] = element;
                    return;
                }
            });
        }

    }
    addVenue(update = false) {
        this.loading = true;
        if (!update) {
            this.venue.admins.push({
                fullName: this.currentUser.firstName + ' ' +
                    this.currentUser.lastName, id: this.currentUser.id
            });
            this.userProvider.getCurrentUser().then((user: User) => {
                if (user && user.organizationId) {
                    this.venuesProvider.addVenue(this.venue, user.organizationId).then((success: any) => {
                        const venue = new Venue(
                            {
                                id: this.venue.id,
                                name: this.venue.name,
                                description: this.venue.description,
                                gpsCoord: this.venue.gpsCoord,
                                profile: this.venue.profile,
                                map: this.venue.map,
                                openingDays: this.venue.openingDays,
                                admins: this.venue.admins,
                                events: this.venue.events,
                                type: this.venue.type,
                                deleted: this.venue.deleted,
                                organizationId: user.organizationId
                            });
                        console.log('added venue', venue);
                        this.loading = false;

                        this.addClicked.emit({ object: venue, updated: update });
                        // location.reload();
                        this.translateService.get('notifications.venue.add.success').subscribe(value => {
                            this._notificationsService.success(value);
                        });
                    }).catch(err => {
                        this.loading = false;
                        console.error(err);
                        this.translateService.get('notifications.venue.add.success').subscribe(value => {
                            this._notificationsService.error(value);
                        });
                    });
                } else {
                    this.translateService.get('notifications.venue.add.error').subscribe(value => {
                        this._notificationsService.error(value);
                    });
                }
            }).catch(err => {
                console.log(err);
                this.translateService.get('notifications.venue.add.error').subscribe(value => {
                    this._notificationsService.error(value);
                });
            });
        } else {
            this.userProvider.getCurrentUser().then((user: User) => {
                if (user && user.organizationId) {
                    this.venuesProvider.updateVenue(this.venue, user.organizationId).then((success: any) => {
                        const venue = new Venue(
                            {
                                id: success.id,
                                name: this.venue.name,
                                description: this.venue.description,
                                gpsCoord: this.venue.gpsCoord,
                                profile: this.venue.profile,
                                map: this.venue.map,
                                openingDays: this.venue.openingDays,
                                admins: this.venue.admins,
                                events: this.venue.events,
                                type: this.venue.type,
                                deleted: this.venue.deleted,
                                organizationId: user.organizationId
                            });
                        this.loading = false;

                        this.addClicked.emit({ object: venue, updated: update });
                        // location.reload();
                        this.translateService.get('notifications.venue.update.success').subscribe(value => {
                            this._notificationsService.error(value);
                        });
                    }).catch(err => {
                        this.loading = false;
                        this.translateService.get('notifications.venue.update.error').subscribe(value => {
                            this._notificationsService.error(value);
                        });
                    });
                } else {
                    this.translateService.get('notifications.venue.add.error').subscribe(value => {
                        this._notificationsService.error(value);
                    });
                }
            }).catch(err => {
                console.log(err);
                this.translateService.get('notifications.venue.add.error').subscribe(value => {
                    this._notificationsService.error(value);
                });
            });
        }
    }
}
