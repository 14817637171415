export class Util {

    /**This method generate a random code that has a length by default of 6
         * @param {number} lenghthRdm the length of the output code
         * @version 1.2.7
         * @author bouzahar.yosobox(at)gmail.com
         */
    public static generateCode(lenghthRdm: number = 6): string {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < lenghthRdm; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
        return text;
    }
}
