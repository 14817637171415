import { Component, OnInit, AfterViewInit, OnChanges, Input, Inject, SimpleChanges } from '@angular/core';
import { User, BadgeDesign } from '../../models';
import { DOCUMENT } from '@angular/common';
import { BadgesProviderService } from '../../service/providers/badges-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { AuthService } from '../../service/auth-service/auth.service';

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() currentUser: User;
  @Input() toolboxid = 'toolbox';
  @Input() badgeDesign: BadgeDesign;
  public options = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true

  };


  constructor(@Inject(DOCUMENT) private document, private authService: AuthService,
    private badgeprovider: BadgesProviderService,
    private _notificationsService: NotificationsService,
    private userProvider: UsersProvider) {
    // console.log('CONSTRUCTOR', this.toolboxid);
    if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {

      this.userProvider.getCurrentUser().then((user: User) => {

        this.currentUser = user;
        localStorage.setItem('CurrentUser', JSON.stringify(user));
        this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

        // this.loading = false;
      });
    } else {
      this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

    }
    // console.log( this.currentUser);

  }
  ngOnChanges(changes: SimpleChanges): void {
     console.log('BADGE CHANGED previous', changes.badgeDesign.previousValue);
     console.log('BADGE CHANGED current', changes.badgeDesign.currentValue);
    this.load(<BadgeDesign>changes.badgeDesign.currentValue);
  }
  ngAfterViewInit(): void {
    // console.log(this.badge);

    // $('.geFooterContainer').css('position','absolute');
  }
  ngOnInit() {
    // this.load(this.badgeDesign);
  }
  load(badge: BadgeDesign) {
     console.log(badge);
    if (badge.userxml === '' || badge.userxml === undefined || badge.userxml === null) {
      badge.userxml = `<mxGraphModel grid="1" gridSize="10" guides="1" tooltips="1" connect="1" arrows="1" fold="1"
       page="1" pageScale="1" pageWidth="827" pageHeight="1169" background="#ffffff">
      <root><mxCell id="0"/><mxCell id="1" parent="0"/></root>
      </mxGraphModel>`;
    }
    if (badge.adminxml === '' || badge.adminxml === undefined || badge.adminxml === null) {
      badge.adminxml = `<mxGraphModel dx="6360" dy="1865" grid="1" gridSize="10" guides="1" tooltips="1" connect="1"
       arrows="1" fold="1" page="1" pageScale="1" pageWidth="827" pageHeight="1169" background="#ffffff"><root><mxCell id="0"/>
       <mxCell id="1" parent="0"/><mxCell id="2" value="" style="rounded=1;whiteSpace=wrap;html=1;arcSize=3;" vertex="1" parent="1">
       <mxGeometry x="10" y="10" width="780" height="1110" as="geometry"/></mxCell><mxCell id="3" value="Event Name"
        style="shape=mxgraph.event.name;editable=0;identifier=event.name;fillColor=none;strokeColor=none;fontSize=35;" vertex="1"
         parent="1"><mxGeometry x="148" y="100" width="532" height="100" as="geometry"/></mxCell><mxCell id="4"
          value="Event date (mm-dd-yyyy)" style="shape=mxgraph.event.date;editable=0;identifier=event.date;fillColor=none;
          fontSize=39;strokeColor=none;" vertex="1" parent="1">
          <mxGeometry x="144" y="230" width="536" height="100" as="geometry"/></mxCell><mxCell id="5" value=""
           style="shape=mxgraph.qrcode.image;editable=0;identifier=qrcode.image;fillColor=none;fontSize=39;"
           vertex="1" parent="1"><mxGeometry x="47"
           y="380" width="706" height="635" as="geometry"/></mxCell></root></mxGraphModel>`;
    }
    if (badge.usersvg !== '' && badge.usersvg !== null && badge.usersvg !== undefined) {
      localStorage.setItem(this.toolboxid + '.svg', badge.usersvg);
      console.log('USER BADGE svg SET--> localStorage');
    }
    if (badge.adminsvg !== '' && badge.adminsvg !== undefined && badge.adminsvg !== null) {
      localStorage.setItem(this.toolboxid + '.svg', badge.adminsvg);
      console.log('ADMIN BADGE svg SET--> localStorage');
    }
    if (document.getElementById(this.toolboxid) !== null) {
      if (this.toolboxid === 'usertoolbox') {
        localStorage.setItem(this.toolboxid + '.xml', badge.userxml);

      }
      // console.log('XML', badge.adminxml);
      if (this.toolboxid === 'admintoolbox') {
        localStorage.setItem(this.toolboxid + '.xml', badge.adminxml);

      }
      document.getElementById(this.toolboxid).innerHTML = '';

      // console.log(document.getElementById(this.toolboxid));

      if (document.getElementById(this.toolboxid + '-script') !== null) {
        document.body.removeChild(document.getElementById(this.toolboxid + '-script'));
      }
      if (document.getElementById('toolbox-style') === null) {
        const style = document.createElement('link');
        style.href = '/assets/toolbox/styles/grapheditor.css';
        style.rel = 'stylesheet';
        style.id = 'toolbox-style';
        document.head.appendChild(style);
      }

      // console.log('ONINIT',this.toolboxid);
      const s = document.createElement('script');
      s.id = this.toolboxid + '-script';
      s.type = 'text/javascript';
      s.innerHTML = `
    mxLoadResources = true;
    (function() {
      var editorUiInit = EditorUi.prototype.init;
      EditorUi.prototype.init = function() {
          editorUiInit.apply(this, arguments);
          this.actions.get('export').setEnabled(false);
                  var enabled=true;
                  this.actions.get('open').setEnabled(enabled || Graph.fileSupport);
                  this.actions.get('import').setEnabled(enabled || Graph.fileSupport);
                  this.actions.get('save').setEnabled(enabled);
                  this.actions.get('saveAs').setEnabled(enabled);
                  this.actions.get('export').setEnabled(enabled);
  };
      mxResources.loadDefaultBundle = false;
     var bundle = mxResources.getDefaultBundle(RESOURCE_BASE, mxLanguage) ||
          mxResources.getSpecialBundle(RESOURCE_BASE, mxLanguage);
      mxUtils.getAll([bundle, STYLE_PATH + '/default.xml'], function(xhr) {
          mxResources.parse(xhr[0].getText());
         var themes = new Object();
         themes[Graph.prototype.defaultThemeName] = xhr[1].getDocumentElement();
         let ui=new EditorUi(new Editor(urlParams['chrome'] == '0', themes),${this.toolboxid});
      }, function() {
          document.body.innerHTML = '<center style=\"margin-top:10%;\">'
          +'Error loading resource files. Please check browser console.</center>';
      });
  })();`;
      document.body.appendChild(s);
    }
  }
  save() {

    let xml;
    let svg;
    let localStrorageFileName;
    for (let i = 0; i < localStorage.length; i++) {
      const tabKey = Object.keys(localStorage)[i].split('.');
      if (tabKey !== undefined && tabKey[tabKey.length - 1] === 'xml') {
        xml = localStorage[Object.keys(localStorage)[i]];
        localStrorageFileName = Object.keys(localStorage)[i];
        break;
      }

    }
    for (let i = 0; i < localStorage.length; i++) {
      const tabKey = Object.keys(localStorage)[i].split('.');
      if (tabKey !== undefined && tabKey[tabKey.length - 1] === 'svg') {
        svg = localStorage[Object.keys(localStorage)[i]];
        localStrorageFileName = Object.keys(localStorage)[i];
        break;
      }

    }
    console.log(svg, xml);
    if (xml !== undefined && svg !== undefined) {
      // this.badgeDesign.adminsvg = svg;
      // this.badgeDesign.adminxml = xml;
      // this.badgeDesign.userid = this.currentUser.id;
      // this.badgeDesign.name = localStrorageFileName;
      // this.badge.type=this.selectedType;
      // this.badgeprovider.addBadgeDesign(badge).then((success:any)=>{
      //  this._notificationsService.success('Design Saved!',success.message);
      // }).catch(error=>{
      //  this._notificationsService.error('Design Error!',error.message);
      // });
      // console.log(this.badgeDesign);
    }
  }


}
