import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsProviderService {

  constructor(private database: AngularFireDatabase,
    private httpservice: HttpService) {

  }
  public getCountRecentAppUser(orgId) {

    return new Promise((resolve, reject) => {
      this.httpservice.post('getCountRecentAppUser', { orgId: orgId }).then((success) => {

        resolve(success);
      }).catch(err => reject(err));
    });
  }
  public getCountRecentAppVipUser(orgId) {

    return new Promise((resolve, reject) => {
      this.httpservice.post('getCountRecentAppVipUser', { orgId: orgId }).then((success) => {

        resolve(success);
      }).catch(err => reject(err));
    });
  }
  public getCountScansToday(orgId) {
    return new Promise((resolve, reject) => {
      this.httpservice.post('getCountScansToday', { orgId: orgId }).then((success) => {

        resolve(success);
      }).catch(err => reject(err));
    });
  }
  public getCountUsersTodayForEvent(eventIds, orgId) {
    const body = { eventIds: eventIds, orgId: orgId };
    return new Promise((resolve, reject) => {
      this.httpservice.post('getCountUsersTodayForEvent', body).then((success) => {

        resolve(success);
      }).catch(err => reject(err));
    });
  }
  public getCountScannedUsersByManagers(orgId) {
    return this.httpservice.post('getCountScannedUsersByManagers', { orgId: orgId });
  }
  public getNumberOfVipUsersOfAllEventsPerMonth(orgId, year: number = (new Date()).getFullYear()) {
    return this.httpservice.post('getNumberOfVipUsersOfAllEventsPerMonth', { organizationId: orgId, year: year });
  }

  public getCountScannedUsersBttDates(orgId, startDate, endDate) {
    return this.httpservice.post('getCountScannedUsersBttDates', { organizationId: orgId, startDate: startDate, endDate: endDate });

  }
  public getCompletedSwap() {
    return this.httpservice.get('getCompletedSwap');
  }
}
