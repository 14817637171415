import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateViaAuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {

  }
  canActivate() {
    new Promise((resolve, reject) => {
      resolve(
        this.authService.getCurrentFirebaseUser().then(auth => {
          if (auth != null) {
            // console.log("Logged User--> ", auth);
            if (!auth['emailVerified']) {
              this.router.navigate(['/emailvalidation']);
            }
            return true;
          } else {
            // console.log("Logged User--> ", auth);
            this.router.navigate(['/home']);
            return false;
          }
        })
      );
    }).then((resp) => {
      // console.log("Resp-->", resp);
      return resp;
    });
    return true;
  }
}
