import { ORGTYPE } from './enum';

/**
 * Organization of users
 */
export class Organization {
  id?: string;
  kind?: string;
  name?: string;
  description?: string;
  phone?: string;
  type?: ORGTYPE;
  address?: string;
  imageUrl?: string;
  creatorId?: string;
  constructor() {
  }
}
