import { Pipe, PipeTransform } from '@angular/core';
import { VQEvent } from '../models';

@Pipe({
  name: 'filterEventBy'
})
export class FilterEventByPipe implements PipeTransform {

  transform(eventdetails: any[], args?: any): any {
    let filtered = eventdetails;
    const todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);
    const todaybeforMidnight = new Date();
    todaybeforMidnight.setHours(23, 59, 59, 999);

    switch (args) {
      case 'up':
        filtered = eventdetails.filter(e => {
          const start = new Date(e.event.startDate);
          const end = new Date(e.event.endDate);
          if (this.validateEvent(e.event)) {
            const morningStart = new Date(e.event.timeInterval.morning.start);
            const afternoonEnd = new Date(e.event.timeInterval.afternoon.end);
            start.setHours(morningStart.getHours(), morningStart.getMinutes(), 0, 0);
            end.setHours(afternoonEnd.getHours(), afternoonEnd.getMinutes(), 0, 0);
          }
          return start.getTime() > todaybeforMidnight.getTime();
        });
        return filtered;
      case 'current':
        filtered = eventdetails.filter(e => {
          const start = new Date(e.event.startDate);
          const end = new Date(e.event.endDate);
          if (this.validateEvent(e.event)) {
            const morningStart = new Date(e.event.timeInterval.morning.start);
            const afternoonEnd = new Date(e.event.timeInterval.afternoon.end);
            start.setHours(morningStart.getHours(), morningStart.getMinutes(), 0, 0);
            end.setHours(afternoonEnd.getHours(), afternoonEnd.getMinutes(), 0, 0);
          }
          return end.getTime() > Date.now();
        });
        return filtered;
      case 'past':
        filtered = eventdetails.filter(e => {
          const start = new Date(e.event.startDate);
          const end = new Date(e.event.endDate);
          if (this.validateEvent(e.event)) {
            const morningStart = new Date(e.event.timeInterval.morning.start);
            const afternoonEnd = new Date(e.event.timeInterval.afternoon.end);
            start.setHours(morningStart.getHours(), morningStart.getMinutes(), 0, 0);
            end.setHours(afternoonEnd.getHours(), afternoonEnd.getMinutes(), 0, 0);
          }
          return end.getTime() < Date.now();
        });
        return filtered;
      case 'deleted':
        filtered = eventdetails.filter(e => {
          if (e.event) {
            return e.event.deleted;
          }
        });
        return filtered;
      default:
        return filtered;
    }
  }

  validateEvent(e: VQEvent) {
    if (e) {
      if (e.timeInterval && e.timeInterval.afternoon && e.timeInterval.morning) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

