import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { Organization } from '../../models/organization';
import { User } from '../../models';
import { OrganizationProviderService } from '../../service/providers/organization-provider.service';
import { VQEventEmitterService } from '../../service/vq-event-emitter/vqevent-emitter.service';
import { Subscription } from 'rxjs';
import { RealtimeDatabaseService } from '../../service/realtime-database/realtime-database.service';

// import { RealtimeDatabaseService } from '../../service/realtime-database/realtime-database.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  hide = true;
  addGroupShow = false;
  public organization: Organization = {};
  public members: User[] = [];
  currentUser: User;
  sub_emitter: Subscription;
  notifConv: boolean;
  notifGroup: boolean;
  constructor(private userProvider: UsersProvider,
    private orgProvider: OrganizationProviderService,
    private vqEventEmitter: VQEventEmitterService,
    private rtdbs: RealtimeDatabaseService
  ) {
    this.userProvider.getCurrentUser().then((user: User) => {
      this.currentUser = user;
      this.orgProvider.getOrganizationUsers().then((members: User[]) => {
        this.rtdbs.listenOnMyConversations().subscribe(notif => {
          this.notifConv = notif;
        });
        this.rtdbs.listenOnMyGroups().subscribe(notif => {
          this.notifGroup = notif;
        });
        this.organization = user.organization;
        this.members = members;
      }).catch(err => {
        // do nothing
      });
      // console.log(this.organization);
    });
    this.sub_emitter = this.vqEventEmitter.Item$.subscribe((value: any) => {
      if (value && value.event === 'edit-group') {
        if (value.data.group) {

          this.showAddGroup();
        }
      }
    });
  }

  ngOnInit() {
  }

  showChat() {
    this.hide = !this.hide;
  }

  close() {

    this.hide = true;
  }

  showAddGroup() {
    this.addGroupShow = true;
  }
  ngOnDestroy(): void {
    this.sub_emitter.unsubscribe();
  }
}
