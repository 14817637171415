import { IEvent, IAdmin, IDayInterval, IGPSCoord } from './interfaces/index';
import { ORGTYPE } from './enum/index';


/**
 * Venue class
 * @version 0.0.1
 */
export class Venue {
    name: string;
    description: string;
    profile: string;
    gpsCoord?: IGPSCoord;
    openingDays?: IDayInterval[] = [];
    map?: string;
    deleted = false;
    events?: IEvent[] = [];
    admins?: IAdmin[] = [];
    organizationId?: string;
    /**
     * Type
     */
    type?: ORGTYPE;
    id?: string;
    kind?: string;
    constructor(obj: any = {}) {
        this.name = obj.name ? obj.name : '';
        this.description = obj.description ? obj.description : '';
        this.gpsCoord = obj.gpsCoord ? obj.gpsCoord : '';
        this.profile = obj.profile ? obj.profile : '';
        this.map = obj.map ? obj.map : '';
        this.openingDays = obj.openingDays ? obj.openingDays : [];
        this.admins = obj.admins ? obj.admins : [];
        this.events = obj.events ? obj.events : [];
        this.type = obj.type ? obj.type : -1;
        this.deleted = !!obj.deleted;
        this.gpsCoord = obj.gpsCoord ? obj.gpsCoord : { lat: 37.775, lng: -122.434 }; // San francisco by default
        this.kind = 'Venue';
        this.organizationId = obj.organizationId;
        this.id = obj.id ? obj.id : undefined;
        Object.defineProperties(this, {
            id: { enumerable: false },
            kind: { enumerable: false }
        });
    }
}
