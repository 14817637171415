import { ISenderReceiver } from './interfaces/index';
import { SwapStatus } from './enum/index';

export class SwapRequest {
    sender: ISenderReceiver;
    receiver?: ISenderReceiver;
    status?:SwapStatus;
    giveAway?:boolean;
    id?: string;
    kind?: string;
    /**
     * Constructor
     * @param param0 {id:string|number,uid:string,eventid:string|number,accepted:boolean}
     */
    constructor({ senderid, eventid, recieverid, isGiveAway = false }) {
        this.sender.uid = senderid;
        this.sender.eventId = eventid;
        this.receiver.uid = recieverid;
        this.status=SwapStatus.PENDING;
        this.giveAway=isGiveAway;
        this.receiver.eventId = undefined;
        this.kind = 'SwapRequest';
        Object.defineProperties(this, {
            id: { enumerable: false },
            kind: { enumerable: false }
        });
    }
}
