import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { User, VQEvent } from '../../models';
import { AuthService } from '../../service/auth-service/auth.service';
import { VQEventsProviderService } from '../../service/providers/v-qevents-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { Http } from '@angular/http';
import { IManager } from '../../models/interfaces';
import { Util } from '../../tools';
import { Observable, Subscription } from 'rxjs';
import { BadgesProviderService } from '../../service/providers/badges-provider.service';
import { VqEmitterService } from '../../service/vqEmitter/vq-emitter.service';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, AfterViewInit, OnDestroy {
  page = 'Event';
  loading = true;
  eventsDetailsList: any[] = [];
  venuesList: any[] = [];
  usersList: any[] = [];
  JSON: any;
  public options = {
    position: ['bottom', 'right'],
    timeOut: 5000
  };
  currentUser: any = {};
  currentEvent: any = {};
  currentVenue: any = {};
  currentBadge: any = {};
  showM = false;
  filterby = 'current';
  sub_emitter: Subscription;
  constructor(
    private vqeventprovider: VQEventsProviderService,
    private _notificationsService: NotificationsService,
    private userProvider: UsersProvider,
    private vq_emmiter: VqEmitterService,
    private translateservice: TranslateService
  ) {
    this.JSON = JSON;
    if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {
      this.loading = true;
      this.userProvider.getCurrentUser().then((user: User) => {
        this.loading = false;
        this.currentUser = user;
        localStorage.setItem('CurrentUser', JSON.stringify(user));
        this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
        this.getAllEvents();
      });
    } else {
      this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
      this.getAllEvents();
    }
    this.sub_emitter = this.vq_emmiter.Item$.subscribe((value: any) => {

      if (value && value.action === 'reload_eventlist') {
        this.getAllEvents();
      }
    });
  }

  ngOnInit(): void {
    document
      .getElementById('subtheme')
      .setAttribute('href', '/assets/css/eventsvenues.css');
  }
  ngOnDestroy() {
    this.sub_emitter.unsubscribe();
  }
  private script($event) {
    if ($($($event.target).data('target')).hasClass('opened')) {
      $($($event.target).data('target')).removeClass('opened');

      $($($event.target).data('target')).addClass('closed');
    } else if ($($($event.target).data('target')).hasClass('closed')) {
      $($($event.target).data('target')).removeClass('closed');
      $($($event.target).data('target')).addClass('opened');
    } else {
      $($($event.target).data('target')).addClass('opened');
    }
    // setTimeout(function () {
    //     $($($event.target).data('target')).removeClass('opened');
    //     $($($event.target).data('target')).addClass('closed');
    // }, 4500);
    let i = $('.event-wrapper').length; // must be the number of element
    $('.event-wrapper').each(function (index, element) {
      $(element).css('z-index', i);
      // console.log($(element).css('z-index'));
      i -= 1;
    });
  }

  ngAfterViewInit(): void {
    $(document).ready(() => {
    });
  }
  filterEvents(by: string) {
    this.filterby = by;
  }
  showModal(event, venue, badge) {
    this.currentEvent = event;
    this.currentVenue = venue;
    this.currentBadge = badge;
    this.showM = true;
  }

  private getAllEvents() {
    this.loading = true;
    const localstoredEvent = JSON.parse(
      localStorage.getItem('allAdminEventsDetails')
    );
    if (localstoredEvent === null) {
      this.forceRefresh();
    } else {
      this.loading = false;
      this.eventsDetailsList = localstoredEvent.eventsDetails;
      this.venuesList = localstoredEvent.venues;
      this.usersList = localstoredEvent.users;
    }
  }

  forceRefresh() {
    this.loading = true;
    this.vqeventprovider.getAdminEvents().then((resp: any) => {
      console.log('List--->', resp);
      localStorage.setItem('allAdminEventsDetails', JSON.stringify(resp));
      const localstoredEvent = JSON.parse(
        localStorage.getItem('allAdminEventsDetails')
      );
      this.loading = false;
      this.eventsDetailsList = localstoredEvent.eventsDetails;
      this.venuesList = localstoredEvent.venues;
      this.usersList = localstoredEvent.users;
    });
  }

  modalClosed($event) {
    console.log('CLOSED-->', $event);
    this.forceRefresh();
    if ($event.addEvent) {
      if (this.eventsDetailsList === undefined) {
        this.eventsDetailsList = [];
      }
      this.eventsDetailsList.push($event.object);
    }
    this.pushTolocalStorage();
  }

  private pushTolocalStorage() {
    const localstoredEvent = JSON.parse(
      localStorage.getItem('allAdminEventsDetails')
    );
    localstoredEvent.eventsDetails = this.eventsDetailsList;
    localStorage.setItem(
      'allAdminEventsDetails',
      JSON.stringify(localstoredEvent)
    );
  }

  /**
   * restore deleted event
   * @param id event id
   */
  public restoreEvent(vqEvent: VQEvent) {
    this.loading = true;
    this.translateservice.get('notifications.event.restore.confirm').subscribe(value => {
      if (confirm(value)) {
        vqEvent.deleted = false;
        this.vqeventprovider
          .restoreEvent(vqEvent.id)
          .then((success: any) => {
            this.loading = false;
            // this.getAllEvents();
            this.translateservice.get('notifications.event.restore.success').subscribe(values => {
              this._notificationsService.success(values);
            });
            this.forceRefresh();
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
            this.translateservice.get('notifications.event.restore.error').subscribe(values => {
              this._notificationsService.error(values);
            });
            vqEvent.deleted = true;
          });
      }
    });
  }

  /**
   * Delete Event
   * @param Id event id
   */
  public deleteEvent(vqEvent: VQEvent) {
    // console.log("Delete->", Id);
    this.translateservice.get('notifications.event.delete.confirm').subscribe(value => {
      if (confirm(value)) {
        this.loading = true;
        vqEvent.deleted = true;
        this.vqeventprovider
          .deleteEvent(vqEvent.id)
          .then((success: any) => {
            this.loading = false;
            // this.getAllEvents();
            this.translateservice.get('notifications.event.delete.success').subscribe(values => {
              this._notificationsService.success(values);
            });
            this.pushTolocalStorage();
          })
          .catch(err => {
            console.error(err);
            this.loading = false;
            this.translateservice.get('notifications.event.delete.error').subscribe(values => {
              this._notificationsService.error(values);
            });
            vqEvent.deleted = true;
          });
      }
    });
  }

  /**
   * This function regenerate a QrCode Hash
   * @param Id
   * @param VqEvent
   * @version 1.4.3
   */
  public regenerateQrCode(vqevent: VQEvent) {
    vqevent.hash = Util.generateCode();
    this.loading = true;
    this.vqeventprovider
      .updateEventOnly(vqevent)
      .then((success: any) => {
        this.loading = false;
        this.translateservice.get('notifications.event.regenerate.success').subscribe(values => {
          this._notificationsService.success(values
          );
        });
        this.pushTolocalStorage();
      })
      .catch(err => {
        this.loading = false;
        this.translateservice.get('notifications.event.regenerate.error').subscribe(values => {
          this._notificationsService.error(values);
        });
      });
  }

  /**
   * Delete Manager
   * @param manager
   * @param eventDetails
   */
  public deleteManagerFromEvent(manager: IManager, eventDetails: any) {
    this.translateservice.get('notifications.manager.delete.confirm').subscribe(value => {

      if (confirm(value)) {
        const index = eventDetails.event.managers.indexOf(manager);
        this.loading = true;
        if (index > -1) {
          eventDetails.event.managers.splice(index, 1);
        }
        console.log('Event ', eventDetails.event);
        this.userProvider.getWithUid(manager.id).then(
          (founduser: User) => {
            // delete event from user manager list
            // the index of the event
            let iev = -1;
            // geting the index of current event
            founduser.eventsManager.forEach(event => {
              if (event.id === eventDetails.event.id) {
                iev = founduser.eventsManager.indexOf(event);
                return;
              }
            });
            if (iev > -1) {
              founduser.eventsManager.splice(iev, 1);
            }
            console.log('Found user', founduser);
            // - update user
            this.userProvider.updateUser(founduser)
              .then(updatedUser => {
                // update event
                this.vqeventprovider
                  .updateEventOnly(eventDetails.event)
                  .then((success: any) => {
                    this.loading = false;
                    // location.reload();
                    this.loading = false;
                    this.translateservice.get('notifications.manager.delete.success').subscribe(values => {
                      this._notificationsService.success(values);
                    });
                  })
                  .catch(err => {
                    this.loading = false;
                    console.error(err);
                    this.translateservice.get('notifications.manager.delete.error').subscribe(values => {
                      this._notificationsService.error(values);
                    });
                  });
              })
              .catch(err => {
                console.error(err);
                this.loading = false;
                this.loading = false; this.translateservice.get('notifications.manager.delete.error').subscribe(values => {
                  this._notificationsService.error(values);
                });
              });
          }
        );
      }
    });
  }

  // sendEmail(admin, event) {
  //   this.loading = true;
  //   const htmlTxt =
  //     '<html>' +
  //     '<body>' +
  //     '<h2>You have been garented an admin access into vQpass app</h2>' +
  //     '<div><p>You can click here to accept your registration<br/>' +
  //     'Note: You must be connected to your account<br/>' +
  //     ' https://' +
  //     firebaseConfig.authDomain +
  //     '/%23/admin/becomeAdmin/' +
  //     event.EventId +
  //     '/' +
  //     admin.Code +
  //     '</div>' +
  //     '<div>' +
  //     'Or you can use this creditetials into event\' list to accept the access:<br/>' +
  //     '<b>Event Id:</b>' +
  //     event.EventId +
  //     '<br>' +
  //     '<b>Code:</b>' +
  //     admin.Code +
  //     '<br>' +
  //     '</p></div>' +
  //     '</body>' +
  //     '</html>';
  //   const plaintext =
  //     'you can use this creditetials into event\' list to accept the access:' +
  //     'Event Id: ' +
  //     event.EventId +
  //     ' / Code: ' +
  //     admin.Code;
  //   this.http
  //     .get(
  //       'https://us-central1-vqpass.cloudfunctions.net/sendEmail?email=' +
  //         admin.Email +
  //         '&message=' +
  //         htmlTxt +
  //         '&subject=Admin access vQEvent' +
  //         '&plaintext=' +
  //         plaintext
  //     )
  //     .map((res: Response) => {
  //       console.log(res.json());
  //       this.loading = false;
  //     })
  //     .catch(this.handleErrorObservable);
  // }
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    this.loading = false;
    return Observable.throw(error.message || error);
  }

  // public becomePremium() {
  //   this.loading = true;
  //   this.userProvider.TogglePremium(this.currentUser.id)
  //     .then((success: any) => {
  //       this.loading = false;
  //       this._notificationsService.success('Premium', success.message);
  //     })
  //     .catch(err => {
  //       this.loading = false;
  //       this._notificationsService.error('Premium', err.message);
  //     });
  // }
}

