import { MemberStatus } from './enum/index';
import { TimeStamp } from './tools/index';

/**
 * This class represent the relationship between User and VQEvent classes
 */
export class EventMember {
    /** status of the subscription */
    status?: MemberStatus = MemberStatus.WAITING;
    /**user id */
    uid: string;
    /**event id */
    eventId: string;
    /** is the event favorite */
    isFavorite?: boolean;
    /**is this user a vip */
    isVIP?: boolean;
    /** signed at time */
    signedAt?: number= Date.now();

    realSignedUpAt?: number= Date.now();
    scannedAt?:number;
    /**Manager id */
    scannedBy?: string;
    /**Calculated difference between signed at time and scaned by time */
    waitedFor?: number;
    /**Tcket number must be generated according to the last event member */
    ticketNumber?: number;
    id?: string;
    kind?: string;

    constructor({uid, eventId}) {
        this.kind = 'EventMember';
        this.uid = uid;
        this.eventId = eventId;
        this.isFavorite = false;
        this.isVIP = false;
        Object.defineProperties(this, {
            id: { enumerable: false },
            kind: { enumerable: false }
        });
    }
}
