import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { User, VQEvent } from '../../../models';
import { NotificationsService } from 'angular2-notifications';
import { Http } from '@angular/http';
import { UsersProvider } from '../../../service/providers/users-provider.service';
import { VQEventsProviderService } from '../../../service/providers/v-qevents-provider.service';
import { FirebaseService } from '@firebase/app-types/private';
import { AuthService } from '../../../service/auth-service/auth.service';
import { IManager } from '../../../models/interfaces';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
    selector: 'app-manager-modal',
    templateUrl: './manager-modal.component.html',
    styleUrls: ['./manager-modal.component.scss']
})
export class ManagerModalComponent implements OnInit, AfterViewInit {
    page = 'Event';
    @Input() currentUser = new User({});
    @Input() $superAdmin = false;
    @Input() edit = false;
    @Input() $VQEvent = new VQEvent();
    @Input() $VQEventKey: string;
    @Input() usersList: User[] = [];
    @Input() ManagerModel: IManager = {
        id: '',
        fullName: '',
        canAccess: false,
        canAddManager: false,
        canDelete: false,
        canDeleteManager: false,
        canEdit: false,
        canRegenerateQr: false,
        canUpdate: false
    };
    @Output() closed: EventEmitter<any> = new EventEmitter();
    private value: any = {};
    private _disabledV = '0';
    private disabled = false;
    private items: Array<any> = [];
    showModal = true;
    loading = false;
    filtredlist: any;
    selectedValue: any;
    public options = {
        position: ['top', 'right'],
        timeOut: 5000,
        lastOnBottom: true
    };
    ngAfterViewInit(): void { }
    constructor(private authService: AuthService,
        private vqEventsprovider: VQEventsProviderService,
        private usersprovider: UsersProvider,
        private translateservice: TranslateService,
        private _notificationsService: NotificationsService) {

    }
    ngOnInit(): void {

        if (this.usersList) {
            this.usersList.forEach((element: User) => {
                if (element.id !== this.currentUser.id) {
                    if (this.$VQEvent.managers !== undefined && this.$VQEvent.managers.length !== 0) {
                        this.$VQEvent.managers.forEach(manager => {
                            // console.log(this.currentUser.id);
                            if (manager.id !== element.id) {
                                this.items.push({ id: element.id, text: element.firstName + ' ' + element.lastName });
                            }
                        });
                    } else if (this.$VQEvent.managers.length === 0) {
                        this.items.push({ id: element.id, text: element.firstName + ' ' + element.lastName });
                    }
                }



            });
        }

        if (this.ManagerModel.id !== '') {
            this.disabled = true;
        }

    }
    /**
     * Selected value
     */
    public selected(value: any): void {
        console.log('Selected value is: ', value);
    }
    /**
     * Removed value
     * @param value
     */
    public removed(value: any): void {
        console.log('Removed value is: ', value);
    }
    /**
     * Search input
     * @param value
     */
    public typed(value: any): void {
        console.log('New search input: ', value);
    }
    public refreshValue(value: any): void {
        this.value = value;
    }
    // ---------------
    addToEvent(edit = false) {
        if (this.ManagerModel.id === undefined) {
            console.log(this.ManagerModel);
            return;
        }
        if (this.value !== undefined) {
            this.loading = true;
            if (!edit) {
                this.ManagerModel.fullName = this.value.text;
                this.ManagerModel.id = this.value.id;
                this.$VQEvent.managers.push(this.ManagerModel);
            } else {
                this.$VQEvent.managers.forEach(manager => {
                    if (manager.id === this.ManagerModel.id) {
                        manager = this.ManagerModel;
                        return;
                    }
                });
            }
            console.log('ManagerTo Add', this.ManagerModel);

            this.$VQEvent.organizationId = this.currentUser.organizationId;
            this.vqEventsprovider.updateEventOnly(this.$VQEvent).then((success: any) => {

                this.usersprovider.getWithUid(this.ManagerModel.id).then((founduser: User) => {
                    // add event to user
                    founduser.eventsManager.push({ id: this.$VQEvent.id, canAccess: this.ManagerModel.canAccess });
                    this.usersprovider.updateUser(founduser).then(updatedUser => {
                        this.loading = false;
                        // location.reload();
                        const data = {
                            titleNoti: `VQpass Manager`,
                            bodyNoti: `You have been added to ${this.$VQEvent.name} as a Manager !`,
                            type: 'manager',
                            uid: founduser.id,
                            data: { eventId: this.$VQEvent.id, assign: true }
                        };

                        this.usersprovider.sendNotification(data).then(noti => {
                            console.log('Notification sent', noti, 'To ', founduser);
                        });
                        this.closed.emit({ success: true, object: this.ManagerModel });
                        this.translateservice.get('notifications.event.manager.add.success').subscribe(value => {

                            this._notificationsService.success(value);
                        });
                    }).catch(err => {
                        this.closed.emit({ success: false, object: this.ManagerModel });
                        this.translateservice.get('notifications.event.manager.add.error').subscribe(value => {

                            this._notificationsService.error(value);
                        });
                    });

                }).catch(err => {
                    this.closed.emit({ success: false, object: this.ManagerModel });
                    this.translateservice.get('notifications.event.manager.add.error').subscribe(value => {

                        this._notificationsService.error(value);
                    });
                });


            }).catch(err => {
                this.loading = false;
                this.translateservice.get('notifications.event.manager.add.error').subscribe(value => {

                    this._notificationsService.error(value);
                });
            });
        }

    }

    // sendEmail(admin) {
    //     const htmlTxt = '<html>' +
    //         '<body>' +
    //         '<h2>You have been garented an admin access into vQpass app</h2>' +
    //         '<div><p>You can click here to accept your registration<br/>' +
    //         'Note: You must be connected to your account<br/>' +
    //         ' https://' + firebaseConfig.authDomain + '/%23/admin/becomeAdmin/' + this.$VQEvent.id +
    //         '</div>' +
    //         '<div>' +
    //         'Or you can use this creditetials into event\' list to accept the access:<br/>' +
    //         '<b>Event Id:</b>' + this.$VQEvent.id + '<br>' +
    //         '<b>Code:</b>' + admin.Code + '<br>' +
    //         '</p></div>' +
    //         '</body>' +
    //         '</html>';
    //     const plaintext = '';
    //     return this.http.get('https://us-central1-vqpass.cloudfunctions.net/sendEmail?email=' + admin.Email +
    //         '&message=' + htmlTxt + '&subject=Admin access vQEvent' + '&plaintext=' + plaintext).map((res: Response) => res.json()
    //         )
    //         .catch(this.handleErrorObservable);
    // }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }

}
