import { Pipe, PipeTransform } from '@angular/core';
import { ORGTYPE } from '../models/enum';

@Pipe({
  name: 'typeEnumToStr'
})
export class TypeEnumToStrPipe implements PipeTransform {
  transform(params): any {
    switch (params) {
      case ORGTYPE.OTHERS: return 'Others';
      case ORGTYPE.HOSPITAL: return 'Hospital';
      case ORGTYPE.UNIVERSITY: return 'University';
      case ORGTYPE.IT: return 'IT';
      case ORGTYPE.AIRPORT: return 'AIRPORT';
      case ORGTYPE.RETAIL: return 'Retail';
      case ORGTYPE.GOVERNMENT: return 'Government';
      default:
      return 'NOT AVAILABLE';
    }
  }
}

