import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VQEventEmitterService {
  private _ItemSource = new BehaviorSubject<any>(null);

  Item$ = this._ItemSource.asObservable();

  emitValue(value) {
    this._ItemSource.next(value);
  }
  constructor() { }
}
