import { Component, OnInit } from '@angular/core';
import { OrganizationProviderService } from '../../service/providers/organization-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { User } from '../../models';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { ROLES } from '../../models/enum/roles';
import { current } from 'codelyzer/util/syntaxKind';
import { ORGTYPE } from '../../models/enum';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss']
})
export class OrganizationUsersComponent implements OnInit {
  public options = {
    position: ['bottom', 'right'],
    timeOut: 5000,
  };
  loading = false;
  users = [];
  currentUser;
  hospital = ORGTYPE.HOSPITAL;
  university = ORGTYPE.UNIVERSITY;
  others = ORGTYPE.OTHERS;
  manager1 = ROLES.MANAGERONE;
  manager2 = ROLES.MANAGERTWO;
  userRole = ROLES.USER;
  admin = ROLES.ADMINISTRATOR;
  constructor(
    private _notificationsService: NotificationsService,
    private _orgProvider: OrganizationProviderService,
    private _userProvider: UsersProvider, private translateService: TranslateService
  ) {
    this._userProvider.getCurrentUser().then((user: User) => {
      this.currentUser = user;
    }).catch(err => {
      this.translateService.get('notifications.user.get.error').subscribe(value => {
        this._notificationsService.error(value);
      });
    });
    this.loadusers();
  }
  loadusers(force = false) {
    this.loading = true;
    this._orgProvider.getOrganizationUsers(force).then((users: User[]) => {
      this.loading = false;
      this.users = users;
    }).catch(err => {
      console.error(err);
      this.translateService.get('notifications.organization.user.get.error').subscribe(value => {
        this._notificationsService.error(value);
      });
      this.loading = false;
    });
  }
  forceRefresh() {
    this.loadusers(true);
  }
  ngOnInit() {
  }

  onUserAdded($event) {
    if ($event === 'refresh') {
      this.loadusers(true);
    } else {
      let users = JSON.parse(localStorage.getItem(`organizationUsers-${this.currentUser.organizationId}`));
      if (!users) {
        users = [];
      }
      users.push($event);
      localStorage.setItem(`organizationUsers-${this.currentUser.organizationId}`, JSON.stringify(users));
      this.loadusers();
    }
  }

  onUserUpdated($event) {
    const users = JSON.parse(localStorage.getItem(`organizationUsers-${this.currentUser.organizationId}`));
    if (users) {
      const index = users.findIndex(item => item.id === $event.id);
      if (index >= 0) {
        users[index] = $event;
        localStorage.setItem(`organizationUsers-${this.currentUser.organizationId}`, JSON.stringify(users));
        this.loadusers();
      }
    }
  }

  deleteUser(user: User) {
    this.translateService.get('notifications.user.delete.confirm').subscribe(value => {
      if (confirm('Do you really want to delete this user from you organization !?')) {
        this.loading = true;
        this._orgProvider.unsubUserFromOrg(user.id, this.currentUser.organizationId).then((success) => {
          this.forceRefresh();
          this.translateService.get('notifications.user.delete.success').subscribe(values => {
            this._notificationsService.success(values);
          });
          this.loading = false;
        }).catch(err => {
          this.translateService.get('notifications.user.delete.error').subscribe(values => {
            this._notificationsService.error(values);

          });
          console.error(err);
          this.loading = false;
        });
      }
    });
  }

}
