import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from '../../service/auth-service/auth.service';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { VQEventsProviderService } from '../../service/providers/v-qevents-provider.service';
import { MemberStatus } from '../../models/enum';
import { BaseChartDirective } from 'ng2-charts';
import { AnalyticsProviderService } from '../../service/providers/analytics-provider.service';
import { Router } from '@angular/router';
import { User } from '../../models';
import { ROLES } from '../../models/enum/roles';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  currentUser: any = {};
  // isCurrentUserPremium = false;
  public options = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true

  };

  public doughnutChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  public doughnutChartType = 'doughnut';
  // -- analytics
  recentAppUser = 0;
  recentVipUser = 0;
  swapDoneToday = 0;
  scanDoneToday = 0;
  NOSHOW = 0;
  WAITING = 0;
  CANCELLED = 0;
  SCANNED = 0;
  public doughnutChartData: number[] = [this.NOSHOW, this.WAITING, this.CANCELLED, this.SCANNED];
  @ViewChild('chart') chart: BaseChartDirective;
  // --------------------------
  displayPremiumNotice = false;
  loading = false;
  eventsDetailsList: any[] = [];
  venuesList: any[] = [];
  usersList: any[] = [];

  constructor(
    private usersProvider: UsersProvider,
    private authService: AuthService,
    private _notificationsService: NotificationsService,
    private analyticsProvider: AnalyticsProviderService,
    private vqeventprovider: VQEventsProviderService,
    private router: Router,
    private translateservice: TranslateService
  ) {



    // if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {
    this.loading = true;
    this.usersProvider.getCurrentUser().then((user: User) => {

      this.loading = false;
      this.currentUser = user;
      this.getAllEvents();

      if (user.role !== undefined && <ROLES>parseInt(user.role.toString(), 10) === ROLES.USER) {
        this.translateservice.get('notifications.user.login.cantlogin').subscribe(value => {
          this._notificationsService.error(value);
          this.authService.logout();
          setTimeout(() => {
            router.navigate(['/home'], { queryParams: { message: value } });
          }, 1000);
        });
        this.authService.logout();
        setTimeout(() => {
          router.navigate(['/home'], { queryParams: { message: 'You cannot loging into the web app !!' } });
        }, 1000);
      } else {
        this.analyticsProvider.getCountScansToday(user.organizationId).then((success: any) => this.scanDoneToday = success.count)
          .catch(err => console.log(err));
        this.analyticsProvider.getCountRecentAppUser(user.organizationId).then((success: any) => this.recentAppUser = success.count)
          .catch(err => console.log(err));
        this.analyticsProvider.getCountRecentAppVipUser(user.organizationId).then((success: any) => this.recentVipUser = success.count)
          .catch(err => console.log(err));
        this.analyticsProvider.getCompletedSwap().then((success: any) => this.swapDoneToday = success.count)
          .catch(err => console.log(err));
        this.translateservice.get('notifications.user.login.wellcome').subscribe(value => {
          this._notificationsService.success(value);
        });
      }
      // this.loading = false;
    }).catch(err => {
      console.log(err);
    });
    // } else {
    //
    // }


  }

  private getAllEvents() {
    this.loading = true;
    let localstoredEvent = JSON.parse(localStorage.getItem('allAdminEventsDetails'));
    if (localstoredEvent === null) {
      this.vqeventprovider.getAdminEvents().then((resp: any) => {
        // console.log('List--->', resp);
        localStorage.setItem('allAdminEventsDetails', JSON.stringify(resp));
        localstoredEvent = JSON.parse(localStorage.getItem('allAdminEventsDetails'));
        this.loading = false;
        this.eventsDetailsList = localstoredEvent.eventsDetails;
        this.venuesList = localstoredEvent.venues;
        this.usersList = localstoredEvent.users;
        this.updateDoughnut();
      });
    } else {
      this.loading = false;
      this.eventsDetailsList = localstoredEvent.eventsDetails;
      this.venuesList = localstoredEvent.venues;
      this.usersList = localstoredEvent.users;
      this.updateDoughnut();
    }
    // console.log(this.eventsDetailsList);

  }
  updateDoughnut() {
    if (this.eventsDetailsList !== undefined) {
      this.eventsDetailsList.forEach(eventDetail => {
        this.vqeventprovider.getEventImages([eventDetail['event'].id], true).then(success => {
          eventDetail['event'].eventLogo = success;
        }).catch(err => {
          eventDetail['event'].eventLogo = undefined;
        });
        this.vqeventprovider.getEventImages([eventDetail['event'].id], false).then(success => {
          eventDetail['event'].eventBackground = success;
        }).catch(err => {
          eventDetail['event'].eventBackground = undefined;
        });


        this.analyticsProvider.getCountUsersTodayForEvent([eventDetail['event'].id], this.currentUser.organizationId)
          .then((success: any) => {
            eventDetail['event'].CANCELLED = success.CANCELLED;
            eventDetail['event'].NOSHOW = success.NOSHOW;
            eventDetail['event'].WAITING = success.WAITING;
            eventDetail['event'].SCANNED = success.SCANNED;
            this.CANCELLED += success.CANCELLED;
            this.WAITING += success.WAITING;
            this.NOSHOW += success.NOSHOW;
            this.SCANNED += success.SCANNED;
          }).catch(err => {
            console.log(err);

          });


      });
    }

    this.doughnutChartData = [this.NOSHOW, this.WAITING, this.CANCELLED, this.SCANNED];
    if (this.chart && this.chart.chart) {
      this.chart.chart.update();
    }
  }
  chartHovered($event) {

  }
  chartClicked($event) {

  }
  ngAfterViewInit() {
    setInterval(() => {

      if (this.chart !== undefined) {
        // console.log(this.doughnutChartData)
        this.doughnutChartData = [this.NOSHOW, this.WAITING, this.CANCELLED, this.SCANNED];
        this.chart.data = this.doughnutChartData;
        if (this.chart.chart !== undefined) {
          this.chart.chart.update();

        }
      }
    }, 1000);
  }
  closeDialog() {
    this.displayPremiumNotice = false;
  }
  goto(str: string, requirePremium: boolean = false) {
    if (requirePremium && !this.currentUser.isPremium) {
      this.displayPremiumNotice = true;

    } else {
      this.displayPremiumNotice = false;
      this.router.navigate([str]);
    }
  }
  ngOnInit() { }
}
