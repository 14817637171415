const { version: appVersion, appStade: stade } = require('../../package.json');

export const CONFIG = {
    GOOGLERECAPTCHAKEY: '6LfvTFkUAAAAAIX8IFS-MP4Qyiwb6Iop0LvmUaRx', // bouzahar.yosobox@gmail.com
    FIREBASECONFIG: (stade === 'dev') ? {
        apiKey: 'AIzaSyCbnBF1jXQsWHjZ-3IRXZ9gks4Bqv6FaxQ',
        authDomain: 'vqpass-test-62279.firebaseapp.com',
        databaseURL: 'https://vqpass-test-62279.firebaseio.com',
        projectId: 'vqpass-test-62279',
        storageBucket: 'vqpass-test-62279.appspot.com',
        messagingSenderId: '436462692086'
    } :
        {
            apiKey: 'AIzaSyCoVsnMb_R126ZdKzJA6OnHKwwxKw3B39U',
            authDomain: 'vqpass-f3e4e.firebaseapp.com',
            databaseURL: 'https://vqpass-f3e4e.firebaseio.com',
            projectId: 'vqpass-f3e4e',
            storageBucket: 'vqpass-f3e4e.appspot.com',
            messagingSenderId: '300532638168'
        }
};
export const OPTIONS = {
    MAX_AVATAR_UPLOAD_SIZE: 350000, // ~300kb
};
