import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { Organization } from '../../models/organization';
import { OrganizationProviderService } from '../../service/providers/organization-provider.service';
import { ORGTYPE, OrgType } from '../../models/enum';
import { Router } from '@angular/router';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { User } from '../../models';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../service/auth-service/auth.service';
import { ROLES } from '../../models/enum/roles';
import { DOCUMENT } from '@angular/platform-browser';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
@Component({
  selector: 'app-set-up-organization',
  templateUrl: './set-up-organization.component.html',
  styleUrls: ['./set-up-organization.component.scss']
})
export class SetUpOrganizationComponent implements OnInit {

  public options = {
    position: ['bottom', 'right'],
    timeOut: 5000,
  };
  loading = false;
  pickedLogo = false;
  logoFileName = 'setuporganization_page.addlogo';
  organization: Organization = {};
  types = [];
  code;
  typeValue: any = {};
  @ViewChild('logoUpload') logoUpload: ElementRef;
  @ViewChild('logoOrgDisplay') logoOrgDisplay: ElementRef;
  constructor(private organizationProvider: OrganizationProviderService,
    @Inject(DOCUMENT) private document,
    private router: Router,
    private userprovider: UsersProvider,
    private _notificationsService: NotificationsService,
    private authService: AuthService,
    private orgProvider: OrganizationProviderService,
    private translateservice: TranslateService

  ) {
    this.loading = true;

    this.userprovider.getCurrentUser().then((user: User) => {
      this.loading = false;
      if (user.role !== undefined && <ROLES>parseInt(user.role.toString(), 10) === ROLES.USER) {
        this.translateservice.get('notifications.user.login.cantlogin').subscribe(value => {
          this._notificationsService.error(value);
          this.authService.logout();
          setTimeout(() => {
            router.navigate(['/home'], { queryParams: { message: value } });
          }, 1000);
        });
      } else {
        if (user && user.organization && user.organizationId) {
          this.router.navigate(['/interface']);
        }
      }

    }).catch(err => {
      this.router.navigate(['/home']);
    });
  }

  ngOnInit() {
    this.document.getElementById('theme')
      .setAttribute('href', './assets/css/home.css');
    this.orgProvider.getAllOrgTypes().then((result: OrgType[]) => {
      result.forEach((el) => {
        this.types.push({ id: el.identifier, text: el.name });
      });
    }).catch(err => { console.error(err); });
    // this.types.push({ id: ORGTYPE.UNIVERSITY, text: 'University' });
    // this.types.push({ id: ORGTYPE.AIRPORT, text: 'Airport' });
    // this.types.push({ id: ORGTYPE.GOVERNMENT, text: 'Government' });
    // this.types.push({ id: ORGTYPE.IT, text: 'IT' });
    // this.types.push({ id: ORGTYPE.RETAIL, text: 'Retail' });
    // this.types.push({ id: ORGTYPE.HOSPITAL, text: 'Hospital' });
    // this.types.push({ id: ORGTYPE.OTHERS, text: 'Others' });
  }

  uploadLogo() {
    this.logoUpload.nativeElement.click();
  }

  displayLogo() {
    console.log(this.logoUpload.nativeElement.files);
    if (this.logoUpload.nativeElement.files && this.logoUpload.nativeElement.files[0]) {
      const reader = new FileReader();
      const img = this.logoOrgDisplay.nativeElement;
      console.log(img);
      reader.onload = function (e) {
        img.src = reader.result;

      };
      this.pickedLogo = true;
      this.logoFileName = this.logoUpload.nativeElement.files[0].name;

      reader.readAsDataURL(this.logoUpload.nativeElement.files[0]);
    }
  }

  onConfirm() {
    this.loading = true;
    this.organizationProvider.addOrganization(this.organization, false).then(success => {
      this.translateservice.get('notifications.organization.add.success').subscribe(value => {
        this._notificationsService.success(value);
        this.loading = false;
        this.router.navigate(['/interface']);
      });
      window.location.reload();
    }).catch(err => {
      this.loading = false;
      console.log(err);
      this.translateservice.get('notifications.organization.add.error').subscribe(value => {
        this._notificationsService.error(value);
      });
    });
  }
  onConfirmCode() {
    this.loading = true;
    if (this.code && this.code !== '') {
      this.organizationProvider.validateUserCode(this.code).then(succees => {
        this.translateservice.get('notifications.organization.add.error').subscribe(value => {
          this._notificationsService.success(value);
        });
        this.loading = false;
        window.location.reload();

      }).catch(err => {
        console.log(err);
        this.loading = false;
        this.translateservice.get('notifications.organization.add.validationerror').subscribe(value => {
          this._notificationsService.error(value);
        });
      });
    } else {
      this.loading = false;
      this.translateservice.get('notifications.organization.add.coderequired').subscribe(value => {

        this._notificationsService.error(value);
      });

    }

  }
}
