import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { User, Venue } from '../../models';
import { AuthService } from '../../service/auth-service/auth.service';
import { VQEventsProviderService } from '../../service/providers/v-qevents-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { Http } from '@angular/http';
import { VenuesProviderService } from '../../service/providers/venues-provider.service';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
    selector: 'app-venues',
    templateUrl: './venues.component.html',
    styleUrls: ['./venues.component.scss']
})
export class VenuesComponent implements OnInit, AfterViewInit {
    /** */
    page = 'Venue';
    public options = {
        position: ['bottom', 'right'],
        timeOut: 5000,
    };
    gpsCoord = { lat: 2.5845, lng: 37.2584 };
    listView = true;
    marker = {
        display: true,
        lat: null,
        lng: null,
    };
    loading = false;
    JSON = JSON;
    currentUser: User;

    venuesList: any[] = [];
    constructor(private authService: AuthService,
        private vqeventprovider: VQEventsProviderService,
        private venuesProvider: VenuesProviderService,
        private _notificationsService: NotificationsService,
        private usersProvider: UsersProvider,
        private translateservice: TranslateService) {
        this.getAllVenues();
        // this.loading = true;
        if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {
            this.loading = true;
            this.usersProvider.getCurrentUser().then((user: User) => {
                this.loading = false;
                this.currentUser = user;
                localStorage.setItem('CurrentUser', JSON.stringify(user));
                this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

            });
        } else {
            this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
        }

        if (!!navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {

                this.gpsCoord.lat = position.coords.latitude;
                this.gpsCoord.lng = position.coords.longitude;
            });
            // Support
        } else {
            // No support
        }
    }
    onMapReady(map) {
        // console.log('map', map);
        // console.log('markers', map.markers);  // to get all markers as an array
    }
    onIdle(event) {
        // console.log('map', event.target);
    }
    onMarkerInit(marker) {

        console.log('LATLNG', marker.getPosition().lat(), marker.getPosition().lng());
        this.gpsCoord.lat = marker.getPosition().lat();
        this.gpsCoord.lng = marker.getPosition().lng();


    }
    clickedMarker({ target: marker }, venueid) {
        this.marker.lat = marker.getPosition().lat();
        this.marker.lng = marker.getPosition().lng();
        marker.nguiMapComponent.openInfoWindow('iw-' + venueid, marker);
    }
    hideMarkerInfo() {
        this.marker.display = !this.marker.display;
    }
    getAllVenues() {
        this.loading = true;
        const localStoredVenues = JSON.parse(localStorage.getItem('VenuesList'));
        if (localStoredVenues === null) {
            this.forceRefresh();
        } else {
            this.venuesList = localStoredVenues;
            this.loading = false;
        }

    }
    forceRefresh() {
        this.loading = true;
        this.venuesProvider.getAllVenues().then((resp: any) => {
            localStorage.setItem('VenuesList', JSON.stringify(resp));
            this.venuesList = JSON.parse(localStorage.getItem('VenuesList'));
            this.loading = false;
        }).catch(err => { });
    }
    deleteVenue(venue: Venue) {
        if (confirm('Do you really want to delete this Venue ?')) {
            venue.deleted = true;
            this.loading = true;
            this.venuesProvider.deleteVenue(venue.id).then(success => {
                this.loading = false;
                this.translateservice.get('notifications.venue.delete.success').subscribe(value => {
                    this._notificationsService.success(value);
                });
                this.pushTolocalStore();
            }).catch(err => {
                venue.deleted = false;
                this.loading = false;
                this.translateservice.get('notifications.venue.delete.error').subscribe(value => {
                    this._notificationsService.success(value);
                });
            });
        }

    }
    restoreVenue(venue: Venue) {
        venue.deleted = false;
        this.loading = true;
        this.venuesProvider.restoreVenue(venue.id).then(success => {
            this.loading = false;
            this.translateservice.get('notifications.venue.restore.success').subscribe(value => {

                this._notificationsService.success(value);
            });
            this.pushTolocalStore();
        }).catch(err => {
            console.error(err);
            venue.deleted = true;
            this.loading = false;
            this.translateservice.get('notifications.venue.restore.success').subscribe(value => {
                this._notificationsService.success(value);
            });
        });
    }
    modalClosed($event) {
        console.log('CLOSED-->', $event);
        if (!$event.updated) {
            this.venuesList.push($event.object);
            this.loading = false;
        }
        this.forceRefresh();
    }
    private pushTolocalStore() {
        // console.log('NEW VENUES',this.venuesList );
        let localStoredVenues = JSON.parse(localStorage.getItem('VenuesList'));
        localStoredVenues = this.venuesList;
        localStorage.setItem('VenuesList', JSON.stringify(localStoredVenues));
    }
    /** */
    ngOnInit(): void {

        document.getElementById('subtheme')
            .setAttribute('href', '/assets/css/eventsvenues.css');
    }
    ngAfterViewInit(): void {

    }
    // becomePremium() {
    // }

}
