import { Injectable, OnInit, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthInfo } from './auth-info';
import * as firebase from 'firebase/app';

/**
 * Auth service is a service that allows you to authenticate to firebase
 * @version 2.0.0
 * @author 'amine.bouzahar'
*/
@Injectable()
export class AuthService {
  static UNKNOWN_USER = new AuthInfo(null);
  returnUrl: string;
  user: any;
  firebaseUser: any = null;
  IsLogged = false;
  authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(AuthService.UNKNOWN_USER);
  constructor(private firebaseAuth: AngularFireAuth, private route: ActivatedRoute,
    private router: Router) {
    this.user = firebaseAuth.authState;
  }

  getCurrentFirebaseUser() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        resolve(firebase.auth().currentUser);
      }
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      });

    });

  }
  getToken(Force: boolean = false) {
    return new Promise((resolve, reject) => this.firebaseAuth.idToken.subscribe((token: any) => {
      if (token) {
        resolve(token);
        // debug.LOG('getToken', token);
        // token.getIdToken(Force).then(token => {
        //     resolve(token);
        // });
      } else {
        reject({ success: false, message: 'User Not authenticated' });
      }
    }));
  }
  changePassword(Email: string, Password: string, newPass) {
    return new Promise((resolve, reject) => {
      this.firebaseAuth.auth.signInWithEmailAndPassword(Email, Password).then(function (resp) {
        // fix new version of firebase resp ={user:{},credential:null,additionalUserInfo:{},operationType:'...'}
        resp.user.updatePassword(newPass).then((success) => {
          resolve(success);
        }).catch(error => reject(error));
      }).catch(error => reject(error));
    });
  }
  changeEmail(Email: string, Password: string, newEmail: string) {

    return this.firebaseAuth.auth.signInWithEmailAndPassword(Email, Password).then(function (resp) {

      resp.updateEmail(newEmail);


    })
      .catch(err => { console.log(err.message); });
  }
  sendVerification() {
    return new Promise((resolve, reject) => {
      this.firebaseAuth.auth.currentUser.sendEmailVerification().then(success => {
        resolve({ success: true, message: 'Email sent !', value: success });

      }).catch(err => {
        reject({ success: false, message: 'Unexpected error !', error: err });
      });
    });


  }
  signup(email, password) {
    return this.fromFirebaseAuthPromise(this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password));
  }

  login(email: string, password: string): Promise<any> {
    return this.fromFirebaseAuthPromise(this.firebaseAuth.auth.signInWithEmailAndPassword(email, password));
  }
  signupwithFacebook() {
    return this.firebaseAuth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider());
  }
  signupwithGitHub() {
    return this.firebaseAuth.auth.signInWithPopup(new firebase.auth.GithubAuthProvider());

  }
  signupwithGoogle() {

    return this.firebaseAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());

  }
  signupwithTwitter() {

    return this.firebaseAuth.auth.signInWithPopup(new firebase.auth.TwitterAuthProvider());

  }
  resetPassword(email: string) {
    return this.firebaseAuth.auth.sendPasswordResetEmail(email);
  }
  fromFirebaseAuthPromise(promise): Promise<any> {

    const subject = new Subject<any>();
    const service = this;


    return new Promise((resolve, reject) => {
      promise.then((res) => {
        const authInfo = new AuthInfo(service.firebaseAuth.auth.currentUser.uid);
        service.authInfo$.next(authInfo);
        subject.next(res);
        subject.complete();
        resolve({ 'success': 'You are now connected !', 'user': res.user });
        //
        // service.router.navigate(['/admin']);
      }).catch(
        function (error) {
          reject({ 'error': error.message });

          service.authInfo$.error(error);

          subject.complete();
        });
    });
  }


  deleteUser(uid: string) {
    // Not implemented ...
  }
  logout() {
    this.firebaseAuth.auth.signOut();
    this.authInfo$.next(AuthService.UNKNOWN_USER);
    localStorage.clear();
    // window.location.reload();
  }
}

