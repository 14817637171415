import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { EmailValidationComponent } from './interface/email-validation/email-validation.component';
import { SignupComponent } from './home/signup/signup.component';
import { SigninComponent } from './home/signin/signin.component';
import { SetUpOrganizationComponent } from './interface/set-up-organization/set-up-organization.component';
import { CanActivateViaAuthGuardService } from './service/CanActivateViaAuthGuard/can-activate-via-auth-guard.service';

const routes: Routes = [
  {
    path: 'emailvalidation',
    component: EmailValidationComponent
  },
  {
    path: 'setup-organization',
    component: SetUpOrganizationComponent,
    canActivate: [CanActivateViaAuthGuardService],
  },
  {
    path: 'login',
    redirectTo: 'signin'
  },
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: '',
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'admin',
        redirectTo: 'interface'
      },
      {
        path: '*',
        component: HomeComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
