import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { OrganizationProviderService } from '../../service/providers/organization-provider.service';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { User } from '../../models';
import { ORGTYPE } from '../../models/enum';
import { VQEventsProviderService } from '../../service/providers/v-qevents-provider.service';
import { MemberStatus } from '../../models/enum';
import { AnalyticsProviderService } from '../../service/providers/analytics-provider.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit, AfterViewInit {

  public scannedUsers: Array<any> = [];
  public vipsPerMonth: Array<any> = [
    {
      label: 'VIPs',
      data: [],
      backgroundColor: 'rgba(0,0,0,0)',
      showLine: true,
      borderColor: '#2ecc71',
      borderWidth: 2,
      pointBackgroundColor: '#fff',
      cubicInterpolationMode: false
    }
  ];
  public label: Array<any> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


  public scannedUsersbt2: Array<any> = [

    {
      label: 'Scanned Users',
      data: [{ x: 0, y: 0 }],
      backgroundColor: 'rgba(0,0,0,0)',
      showLine: true,
      borderColor: '#e67e22',
      borderWidth: 2,
      pointBackgroundColor: '#fff'

    }
  ];


  public lineChartType = 'line';
  public overAllLineOptions = {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.yLabel;
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(255,255,255,0.5)',
        }
      }],
      yAxes: [{
        ticks: {
          drawTicks: false
        },
        gridLines: {
          color: 'rgba(255,255,255,0.5)',
        }
      }]
    }
  };
  scannedOptions = {
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(255,255,255,0.5)',
        },
        ticks: {
          callback: function (value) {
            return new Date(value).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
          },
        },
        type: 'time',
        time: {
          displayFormats: {
            'millisecond': 'MMM YYYY',
            'second': 'MMM YYYY',
            'minute': 'MMM YYYY',
            'hour': 'MMM YYYY',
            'day': 'MMM YYYY',
            'week': 'MMM YYYY',
            'month': 'MMM YYYY',
            'quarter': 'MMM YYYY',
            'year': 'MMM YYYY',
          }
        }
      }]
    }
  };
  public lineChartOptions: object = {
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(255,255,255,0.5)',
        }
      }],
      yAxes: [{
        ticks: {
          drawTicks: false
        },
        gridLines: {
          color: 'rgba(255,255,255,0.5)',
        }
      }]
    }
  };

  public pieChartType = 'polarArea';

  public options = {
    showAllTooltips: true,
    animation: {
      onComplete: function () {
        // console.log('CHART',this)
        // const ctx = this.chart.ctx;
        // ctx.font = this.scale.font;
        // ctx.fillStyle = this.scale.textColor;
        // ctx.textAlign = 'center';
        // ctx.textBaseline = 'bottom';

        // this.datasets.forEach(function (dataset) {
        //     dataset.points.forEach(function (points) {
        //         ctx.fillText(points.value, points.x, points.y - 10);
        //     });
        // });
      }
    },
    legend: {
      labels: {
        fontColor: 'white',
        fontSize: 12
      }
    }
  };

  organizationType;
  public usersPerEvent: object[] = [{ data: [400, 50], borderWidth: 1 }];
  NOSHOW = 0;
  WAITING = 0;
  CANCELLED = 0;
  SCANNED = 0;
  public doughnutChartColors: any[] = [{
    backgroundColor: ['rgba(184, 67, 109,0.5)', 'rgba(0, 136, 249,0.5)',
      'rgba(199, 80, 60,0.5)', 'rgba(160, 173, 232,0.5)']
  }];
  public doughnutChartData = [this.NOSHOW, this.WAITING, this.CANCELLED, this.SCANNED];
  labelScanned: Array<any>[] = [];
  eventsDetailsList: any[] = [];
  @ViewChild('chart') chart: BaseChartDirective;
  @ViewChild('chartScannedPermanager') chartScannedPermanager: BaseChartDirective;
  userStatusLoading = false;
  ScannedPerManagersLoading = false;
  vipsPerMonthLoading: boolean;
  startDate;
  endDate;
  currentUser;
  scannedUsersbt2Loading: boolean;
  interval: NodeJS.Timer;
  pieChartLabels: string[] = ['Scanned', 'Waiting', 'Canceled', 'No Show'];
  constructor(
    private vqeventprovider: VQEventsProviderService,
    private analyticsProvider: AnalyticsProviderService,
    private userProvider: UsersProvider
  ) {
    this.userProvider.getCurrentUser().then((user: User) => {
      this.currentUser = user;
      this.organizationType = user.organization.type;
      console.log(this.organizationType);
      this.initValues();
      this.vipsPerMonthLoading = true;
      this.scannedUsersbt2Loading = true;
      this.ScannedPerManagersLoading = true;
      this.analyticsProvider.getCountScannedUsersByManagers(user.organizationId).then((result: any[]) => {
        this.ScannedPerManagersLoading = false;

        this.scannedUsers = result;
        if (this.chartScannedPermanager) {
          if (this.chartScannedPermanager.chart !== undefined) {
            // this.chartScannedPermanager.chart.data = this.scannedUsers.data;
            this.chartScannedPermanager.chart.update();

          }
        }
      }).catch(err => {
        this.ScannedPerManagersLoading = false;
        console.log(err); });

      this.analyticsProvider.getNumberOfVipUsersOfAllEventsPerMonth(user.organizationId).then((result: any[]) => {
        this.vipsPerMonthLoading = false;
        this.vipsPerMonth = result;
        console.log(result);
      }).catch(err => { console.log(err); });
      const now = new Date();
      now.setDate(now.getDate() + 1);
      this.endDate = new Date(now.getTime());

      now.setMonth(now.getMonth() - 6);
      this.startDate = new Date(now.getTime());
      this.analyticsProvider.getCountScannedUsersBttDates(user.organizationId, this.startDate.getTime(),
        this.endDate.getTime()).then((result: any) => {
          this.scannedUsersbt2Loading = false;
          this.scannedUsersbt2 = result.data;
          this.labelScanned = result.labels;
          console.log(result);
        }).catch(err => { console.log(err); });

    }).catch(error => {
      console.log(error);
    });
  }
  call() {
    this.analyticsProvider.getNumberOfVipUsersOfAllEventsPerMonth(this.currentUser.organizationId).then((result: any[]) => {
      this.vipsPerMonthLoading = false;
      this.vipsPerMonth = result;
      console.log(result);
    }).catch(err => { console.log(err); });
  }
  getbetween() {
    this.scannedUsersbt2Loading = true;

    this.analyticsProvider.getCountScannedUsersBttDates(this.currentUser.organizationId, this.startDate.getTime(),
      this.endDate.getTime()).then((result: any) => {
        this.scannedUsersbt2Loading = false;
        this.scannedUsersbt2 = result.data;
        this.labelScanned = result.labels;
        console.log(result);
      }).catch(err => { console.log(err); });
  }
  ngAfterViewInit() {
    this.interval = setInterval(() => {

      if (this.chart !== undefined) {
        // console.log(this.doughnutChartData)
        this.doughnutChartData = [this.SCANNED, this.WAITING, this.CANCELLED, this.NOSHOW];
        this.chart.data = this.doughnutChartData;
        if (this.chart.chart !== undefined) {
          this.chart.chart.update();

        }
      }
    }, 1000);
    // this.updateDoughnut();
  }
  public randomizeType(): void {
    this.lineChartType = this.lineChartType === 'line' ? 'bar' : 'line';
    this.pieChartType = this.pieChartType === 'doughnut' ? 'pie' : 'doughnut';
  }

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  ngOnInit(): void {
  }

  private getAllEvents() {
    // this.userStatusLoading = true;
    let localstoredEvent = JSON.parse(localStorage.getItem('allAdminEventsDetails'));
    if (localstoredEvent === null) {
      this.vqeventprovider.getAdminEvents().then((resp: any) => {
        console.log('List--->', resp);
        localStorage.setItem('allAdminEventsDetails', JSON.stringify(resp));
        localstoredEvent = JSON.parse(localStorage.getItem('allAdminEventsDetails'));
        // this.userStatusLoading = false;
        this.eventsDetailsList = localstoredEvent.eventsDetails;
        // this.venuesList = localstoredEvent.venues;
        // this.usersList = localstoredEvent.users;
        this.updateDoughnut();
      });
    } else {
      // this.loading = false;
      this.eventsDetailsList = localstoredEvent.eventsDetails;
      // this.venuesList = localstoredEvent.venues;
      // this.usersList = localstoredEvent.users;
      this.updateDoughnut();
    }
    // console.log(this.eventsDetailsList);

  }
  updateDoughnut() {
    if (this.eventsDetailsList !== undefined) {
      // const promisses = { noshow: [], cancelled: [], scanned: [], waiting: [] };
      this.userStatusLoading = true;
      const eventIds = [];
      this.eventsDetailsList.forEach(eventDetail => {
        eventIds.push(eventDetail.event.id);
      });
      this.analyticsProvider.getCountUsersTodayForEvent(eventIds, this.currentUser.organizationId).then((results: any) => {
        this.NOSHOW = results.NOSHOW;
        this.CANCELLED = results.CANCELLED;
        this.WAITING = results.WAITING;
        this.SCANNED = results.SCANNED;
        this.userStatusLoading = false;
      }).catch(err => { console.error(err); this.ScannedPerManagersLoading = false; });
    }


  }
  initValues() {

    console.log('type', this.organizationType);
    this.pieChartLabels = ['Scanned', 'Waiting', 'Canceled', 'No Show'];
    this.getAllEvents();
    this.usersPerEvent = [{ data: [300, 600, 400], borderWidth: 1 }];


  }
}
