import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../service/auth-service/auth.service';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { FormUser, User } from '../../models';
import { Organization } from '../../models/organization';
import { ORGTYPE } from '../../models/enum';
import { OrganizationProviderService } from '../../service/providers/organization-provider.service';
import { ROLES } from '../../models/enum/roles';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  loading = false;
  currentUser: any = {};
  User: any = {};
  logoFileName: string;
  hospital = ORGTYPE.HOSPITAL;
  university = ORGTYPE.UNIVERSITY;
  others = ORGTYPE.OTHERS;
  admin = ROLES.ADMINISTRATOR;
  manager1 = ROLES.MANAGERONE;
  manager2 = ROLES.MANAGERTWO;
  userRole = ROLES.USER;
  @ViewChild('logoUpload') logoUpload: ElementRef;
  @ViewChild('logoOrgDisplay') logoOrgDisplay: ElementRef;
  public options = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true

  };
  organization: Organization | undefined;
  types: any[] = [];
  constructor(private authService: AuthService,
    private usersProvider: UsersProvider,
    private _notificationsService: NotificationsService,
    private organizationProvider: OrganizationProviderService,
    private translateService: TranslateService
  ) {
    this.types.push({ id: ORGTYPE.UNIVERSITY, text: 'University' });
    this.types.push({ id: ORGTYPE.AIRPORT, text: 'Airport' });
    this.types.push({ id: ORGTYPE.GOVERNMENT, text: 'Government' });
    this.types.push({ id: ORGTYPE.IT, text: 'IT' });
    this.types.push({ id: ORGTYPE.RETAIL, text: 'Retail' });
    this.types.push({ id: ORGTYPE.HOSPITAL, text: 'Hospital' });
    this.types.push({ id: ORGTYPE.OTHERS, text: 'Others' });
    this.currentUser.email = '';
    this.getOrganization();
  }
  changePassword() {
    if ((this.User.confirmPassword && this.User.confirmPassword) && (this.User.confirmPassword === this.User.password)) {
      this.authService.changePassword(this.currentUser.email, this.currentUser.password, this.User.confirmPassword).then(() => {
        this.translateService.get('notifications.passwordchange.success').subscribe(value => {
          this._notificationsService.success(value);
        });
      }).catch(err => {
        this.translateService.get('notifications.passwordchange.error').subscribe(value => {
          this._notificationsService.error(value);
        });
      });
    }
  }
  updateProfile() {
    this.loading = true;
    if (this.currentUser.email !== this.User.email && this.User.email) {
      this.currentUser.email = this.User.email;
      this.changeEmail(this.User);
    }
    this.usersProvider.updateUser(this.currentUser).then(res => {
      this.loading = false;
      localStorage.setItem('CurrentUser', JSON.stringify(this.currentUser));
    });
  }
  private changeEmail(newLogin: FormUser) {
    this.authService.changeEmail(this.currentUser.email, newLogin.password, newLogin.email);
  }
  ngOnInit() {
    if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {
      this.loading = true;
      this.usersProvider.getCurrentUser().then((user: User) => {
        this.loading = false;

        this.currentUser = user;

        localStorage.setItem('CurrentUser', JSON.stringify(user));
        this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));


        // this.loading = false;
      });
    } else {
      this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

    }
  }

  getOrganization() {
    this.usersProvider.getCurrentUser().then((user: User) => {
      this.organization = user.organization;
    });
  }

  uploadLogo() {
    console.log('I an triggered');
    this.logoUpload.nativeElement.click();
  }

  displayLogo() {
    if (this.logoUpload.nativeElement.files && this.logoUpload.nativeElement.files[0]) {
      const reader = new FileReader();
      const img = this.logoOrgDisplay.nativeElement;
      console.log(img);
      reader.onload = (e) => {
        img.src = reader.result;
        this.organization.imageUrl = reader.result;
      };
      this.logoFileName = this.logoUpload.nativeElement.files[0].name;

      reader.readAsDataURL(this.logoUpload.nativeElement.files[0]);
    }
  }

  updateOrganization() {
    this.loading = true;
    const dataURL = this.organization.imageUrl;
    delete this.organization.imageUrl;
    this.organizationProvider.updateOrganization(this.organization, dataURL).then(success => {
      this.usersProvider.getCurrentUser().then((user: User) => {
        this.organization.imageUrl = dataURL;
        user.organization = this.organization;
        this.loading = false;
        this.translateService.get('notifications.organization.update.success').subscribe(value => {
          this._notificationsService.success(value);
        });
      });
    }).catch(err => {
      console.log(err);
      this.loading = false;
      this.translateService.get('notifications.organization.update.error').subscribe(value => {
        this._notificationsService.success(value);
      });
    });
  }
}
