import { Component, OnInit, EventEmitter, Output, Input, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { DesignType } from '../../models/enum';
import { BadgeDesign, User } from '../../models';
import { tickets, badges } from './designs/badges';
import { Router } from '@angular/router';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../service/auth-service/auth.service';
import { BadgesProviderService } from '../../service/providers/badges-provider.service';
import { UserBadgeOne, AdminBadgeOne } from './designs/displayedbadge';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-badge-design',
  templateUrl: './badge-design.component.html',
  styleUrls: ['./badge-design.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeDesignComponent implements OnInit, AfterViewInit {
  currentUser: any = {};
  isCurrentUserPremium = false;
  loading = false;
  designTypes: any[] = [];
  selectedType: DesignType = DesignType.BADGE;
  badgeDesignList: any[] = [];
  @Input() allowAdd = true;
  @Input() selectedBadge;

  @Input()
  set eventLogo(eventLogo: string) {
    console.log($('*[data-identifier="event.logo"]'));
    $('*[data-identifier="event.logo"]').each(function () {
      $(this).attr('href', eventLogo);
    });
  }

  @Input()
  set eventBackground(eventBackground) {
    console.log('event background', eventBackground);
    // Create an image in SVG's namespace
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    const pattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
    pattern.setAttribute('id', 'background');
    pattern.setAttribute('height', '100%');
    pattern.setAttribute('width', '100%');
    pattern.setAttribute('patternUnits', 'userSpaceOnUse');

    const background = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    background.setAttribute('href', eventBackground);
    background.setAttribute('height', '100%');
    background.setAttribute('width', '100%');
    background.setAttribute('preserveAspectRatio', 'none');
    pattern.appendChild(background);
    defs.appendChild(pattern);
    const adminBadge = $('#adminSVG > svg');
    // adminBadge[0].setAttribute('style', 'fill:url(#background)');

    const defAdmin = $('#adminSVG > svg >defs')[0];
    if (defAdmin) {
      defAdmin.remove();
    }

    const defUser = $('#userSVG > svg >defs')[0];
    if (defUser) {
      defUser.remove();
    }

    if (adminBadge[0]) {
      adminBadge[0].appendChild(defs);
      $('#adminSVG > svg > g > path')[0].setAttribute('style', 'fill:url(#background)');

    }
    const userBadge = $('#userSVG > svg ');
    if (userBadge[0]) {
      userBadge[0].appendChild(defs);
      $('#userSVG > svg > g > path')[0].setAttribute('style', 'fill:url(#background)');

    }

  }

  @Output() badgeClicked: EventEmitter<any> = new EventEmitter();

  public options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true
  };
  public activeSlideIndex = 0;

  constructor(private usersProvider: UsersProvider, private authService: AuthService,
    private _notificationsService: NotificationsService, private badgeprovider: BadgesProviderService,
    private translateservice: TranslateService) {

    if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {
      this.loading = true;
      this.usersProvider.getCurrentUser().then((user: User) => {
        this.loading = false;
        this.currentUser = user;
        localStorage.setItem('CurrentUser', JSON.stringify(user));
        this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
      });
    } else {
      this.loading = false;
      this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

    }

    // console.log(badges.badgeAdmin);
    this.forceRefresh(true, false);
    if (JSON.parse(localStorage.getItem('badgeDesignList')) === null) {
      this.forceRefresh(false, true);
    } else {
      JSON.parse(localStorage.getItem('badgeDesignList')).forEach(badge => {
        // console.log(badge);
        this.badgeDesignList.push(badge);
      });
      this.badgeDesignList = this.badgeDesignList.slice();
    }

  }

  forceRefresh(reinit = false, force = false) {
    if (reinit) {
      this.designTypes = [];
      this.designTypes.push({ name: 'badgedesign', value: DesignType.BADGE });
      this.designTypes.push({ name: 'ticketdesign', value: DesignType.TICKET });

    }
    if (force) {
      this.loading = true;
      const promises = [];

      promises.push(this.badgeprovider.getUserBadgeDesign(this.currentUser.id));
      promises.push(this.badgeprovider.getBadgesTemplates());


      Promise.all(promises).then(results => {
        console.log('List===> Badges ', results);


        this.loading = false;
        const unique_list = [];
        const concat = results[0].concat(results[1]);
        concat.forEach(element => {
          if (!unique_list.find(u => u.id === element.id)) {
            unique_list.push(element);
          }
        });
        console.log(unique_list);
        localStorage.setItem('badgeDesignList', JSON.stringify(unique_list));
        this.badgeDesignList = unique_list;
        //  this.badgeDesignList = this.badgeDesignList.slice();
      }).catch(error => {
        this.loading = false;
        console.log(error);
      });

    }

  }

  selectType(value: DesignType) {
    // console.log(value);
    this.selectedType = <DesignType>value;
  }

  ngAfterViewInit(): void {

  }

  hideSliderControls() {
    $('.carousel-indicators,.carousel-control').hide();
  }

  gotoSlide(index: number, badge = null) {
    if (this.activeSlideIndex >= 0 && this.activeSlideIndex < $('slide').length) {
      this.activeSlideIndex = index;
    }
    console.log('THE BADGE', badge);
    if (badge !== null) {
      this.badgeClicked.emit(badge);

    }
  }

  previous() {
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex -= 1;
    } else {
      this.activeSlideIndex = ($('slide').length - 1);
    }
    if (this.activeSlideIndex === undefined) {
      this.activeSlideIndex = 0;
    }
    // console.log(this.activeSlideIndex);
  }

  next() {
    if (this.activeSlideIndex < $('slide').length) {
      this.activeSlideIndex += 1;
    }
    if (this.activeSlideIndex === $('slide').length) {
      this.activeSlideIndex = 0;
    }
    if (this.activeSlideIndex === undefined) {
      this.activeSlideIndex = 0;
    }
    // console.log(this.activeSlideIndex);
  }

  deleteBadge(badge) {
    if (confirm('Do you realy want to do this !')) {
      this.loading = true;

      this.badgeprovider.deleteBadge(badge.id).then(success => {
        this.translateservice.get('notifications.design.delete.success').subscribe(value => {
          this._notificationsService.success(value);
        });
        this.forceRefresh(true, true);
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.translateservice.get('notifications.design.delete.error').subscribe(value => {

          this._notificationsService.error(value);
        });
        console.log(err);
      });
    }

  }

  ngOnInit() {
  }

}
