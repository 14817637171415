import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { RealtimeDatabaseService } from '../../service/realtime-database/realtime-database.service';
import { User } from '../../models';
import { VQEventEmitterService } from '../../service/vq-event-emitter/vqevent-emitter.service';
import { Subscription } from 'rxjs';
import { DataSnapshot } from 'angularfire2/database/interfaces';
import { isFunction } from 'util';

@Component({
  selector: 'app-pick-group-members',
  templateUrl: './pick-group-members.component.html',
  styleUrls: ['./pick-group-members.component.scss']
})
export class PickGroupMembersComponent implements OnInit, OnDestroy {
  usersIds: string[] = [];
  @Input() currentUser: User;
  groupName: string;
  @Input() members: User[];
  @Output() hideAddGroup = new EventEmitter<boolean>();
  @Input() group: DataSnapshot;
  sub_emitter: Subscription;


  filteredMembers: User[] = [];
  searchInput;
  constructor(private realTime: RealtimeDatabaseService, private vqEventEmitter: VQEventEmitterService) {

  }

  ngOnInit() {
    this.members.forEach(element => {
      element.checked = false;

    });
    this.filteredMembers = JSON.parse(JSON.stringify(this.members));
    this.sub_emitter = this.vqEventEmitter.Item$.subscribe((value: any) => {
      if (value && value.event === 'edit-group') {
        if (value.data.group && value.data.group.val && isFunction(value.data.group.val)) {
          this.group = value.data.group;
          this.groupName = this.group.val().name;

          for (const key in this.group.val()) {
            if (this.group.val().hasOwnProperty(key)) {
              // this.filteredMembers = JSON.parse(JSON.stringify(this.members));
              this.filteredMembers.forEach(element => {
                if (key === element.id) {
                  element.checked = true;

                  this.usersIds.push(key);

                }
              });
            }
          }
          console.log('members', this.filteredMembers, 'userIds', this.usersIds, this.group.val());
        }
      }
    });
    // this.filteredMembers = this.members;
    $('label').on('click', function () {
      $(this).parent('li').toggleClass('active');
    });
  }
  ngOnDestroy(): void {
    this.sub_emitter.unsubscribe();
  }
  onSelect(user: any) {
    user.checked = (user.checked === undefined) ? false : !user.checked;
    if (user) {
      if (user.checked) {
        if (!this.usersIds.find(u => u === user.id)) {
          this.usersIds.push(user.id);
        }
      } else {
        if (this.usersIds.find(u => u === user.id)) {
          this.usersIds.splice(user.id, 1);
        }
      }

      console.log('selected users', this.usersIds);

    }
  }

  searchForMembers() {
    console.log('search for', this.searchInput);
    if (!this.searchInput || this.searchInput === '') {
      this.filteredMembers = JSON.parse(JSON.stringify(this.members));
    }
    this.filteredMembers.forEach(element => {
      if (this.usersIds.find(u => u === element.id)) {
        element.checked = true;
      }
    });
    this.filteredMembers = this.members.filter(user => (user.email.toLowerCase().startsWith(this.searchInput) ||
      user.firstName.toLowerCase().startsWith(this.searchInput) || user.lastName.toLowerCase().startsWith(this.searchInput)));
  }

  createGroup() {
    if (this.currentUser) {
      this.realTime.createGroup(this.currentUser.id, this.usersIds, this.groupName).then((success: any) => {
        this.vqEventEmitter.emitValue({ event: 'group-created', data: { key: success.key } });
        this.hideAddGroup.emit(true);
      }).catch(err => {

      });
    }

  }
  editGroup() {
    if (this.currentUser && this.group) {
      this.realTime.createGroup(this.currentUser.id, this.usersIds, this.groupName, this.group.key).then((success: any) => {
        this.vqEventEmitter.emitValue({ event: 'group-created', data: { key: success.key } });
        this.hideAddGroup.emit(true);
      }).catch(err => {

      });
    }
  }
}
