import { Shape, Path } from './svg/index';
import { SVGType } from './enum/index';

export class SVG {
    type: SVGType;
    shapes: Shape[];
    paths: Path[];
    style: string;
    kind?: string;
    id?: string;
    private builder?: string;
    constructor(type: SVGType) {
        this.builder = 'Badge Design not built!';
        this.kind = 'SVG';
        this.type = type;
        Object.defineProperties(this, {
            id: { enumerable: false },
            kind: { enumerable: false }
        });
    }
    buildDesign() {
        this.builder = '<svg >' + '<g >';
        this.builder += '<defs>' + '</defs>';
        this.shapes.forEach(shape => {
            this.builder += shape;
        });
        this.paths.forEach(path => {
            this.builder += path;
        });

        this.builder += '</g>' + '</svg>';
    }
    public toString(): string {
        this.buildDesign();
            return this.builder;
    }
}
