import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../service/http/http.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { CONFIG } from '../../../environments/config';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  supportForm: any = { name: '', email: '', subject: '', message: '' };
  loading = false;
  captchaError = false;
  controlForm = new FormGroup({
    name: new FormControl(),
    email: new FormControl(),
    subject: new FormControl(),
    message: new FormControl(),
  });
  public options = {
    position: ['bottom', 'right'],
    timeOut: 5000
  };
  @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;
  reCaptchaKey = CONFIG.GOOGLERECAPTCHAKEY;
  token = undefined;
  message = 'support_page.allfieldsrequired';

  constructor(private http: HttpService, private fb: FormBuilder,
    private _notificationsService: NotificationsService,
    private translateservice: TranslateService
  ) {
    this.createForm();
  }

  /**
   * Handle reCaptcha response
   * @version 0.0.1
   * @author 'amine.bouzahar'
   */
  handleCorrectCaptcha($event) {
    if (this.captcha !== undefined) {
      this.token = this.captcha.getResponse();

      console.log(
        `handleCorrectCaptcha():
          Your captcha token :
        ${$event}`);

    }
  }

  ngOnInit() {
  }

  onSubmit() {
    console.log(this.token);
    if (!this.controlForm.valid) {
      for (const key in this.controlForm.controls) {
        if (this.controlForm.controls.hasOwnProperty(key)) {
          this.controlForm.controls[key].markAsDirty();

        }
      }
    } else {
      if (this.token) {
        this.loading = true;
        this.http.post('contactSupport', this.supportForm).then(success => {
          this.loading = false;
          console.log(success);
          this.translateservice.get('notifications.support.thanks').subscribe(value => {
            this._notificationsService.success(value);
            this.message = value;
          });

        }).catch(error => {
          this.translateservice.get('notifications.support.error').subscribe(value => {
            this._notificationsService.error(value);
            this.message = value;
          });
          this.loading = false;
          console.log(error);
        });
      } else {
        this.captchaError = true;
      }

    }

    console.log(this.supportForm);
  }

  createForm() {
    this.controlForm = this.fb.group({
      name: [null, Validators.required],
      email: [null, Validators.compose([Validators.email, Validators.required])],
      subject: [null, Validators.required],
      message: [null, Validators.required]
    });
  }
}
