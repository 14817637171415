import { ORGTYPE } from '../models/enum';
import { ROLES } from '../models/enum/roles';
import { User } from '../models';

export class Utils {
    static allow(rg: RouterData, user: User): Promise<boolean> {
        return new Promise((resolve) => {

            const test = user && user.organization && user.role !== undefined;
            let findOrgType;
            let findRole;
            if (test) {
                if (!rg.allOrgs) {
                    if (rg.orgTypes) {
                        findOrgType = rg.orgTypes.find(type => <ORGTYPE>type === Number(user.organization.type));
                    }
                    if (!rg.allRoles) {
                        findRole = rg.roles.find(role => <ROLES>role === <ROLES>(Number(user.role)));
                        if (findOrgType !== undefined && findRole !== undefined) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        resolve(true);
                    }
                } else {
                    if (!rg.allRoles || rg.roles) {
                        findRole = rg.roles.find(role => <ROLES>role === <ROLES>(Number(user.role)));
                        if ((findOrgType !== undefined || rg.allOrgs) && findRole !== undefined) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        resolve(true);
                    }
                }
            } else {
                resolve(false);
            }


        });
    }
}
export interface RouterData {
    allOrgs: boolean;
    allRoles: boolean;
    orgTypes: ORGTYPE[];
    roles: ROLES[];
}
