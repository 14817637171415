import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';
import { SimpleNotificationsModule, NotificationsService } from 'angular2-notifications';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule, SharedModule.forRoot(), SimpleNotificationsModule.forRoot(), FormsModule
  ],
  declarations: [SigninComponent, SignupComponent, HomeComponent],
  providers: [NotificationsService]
})
export class HomeModule { }
