import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ChooseAvatarModalComponent } from '../choose-avatar-modal/choose-avatar-modal.component';
import { User } from '../../../models';

@Component({
  selector: 'app-modal-service-avatar',
  templateUrl: './modal-service-avatar.component.html',
  styleUrls: ['./modal-service-avatar.component.scss']
})
export class ModalServiceAvatarComponent {

  bsModalRef: BsModalRef;
  @Input() currentUser: User;
  constructor(private modalService: BsModalService) {

  }
  openModalWithComponent() {
    this.bsModalRef = this.modalService.show(ChooseAvatarModalComponent, { class: 'modal-lg' });
    this.bsModalRef.content.currentUser = this.currentUser;
  }
}
