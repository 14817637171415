import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models';
import { ORGTYPE } from '../models/enum';
import { ROLES } from '../models/enum/roles';

@Pipe({
  name: 'filterUsersByRoles'
})
export class FilterUsersByRolesPipe implements PipeTransform {

  transform(list: User[], role: ROLES): any {
    const filtered = list.filter(user => <ROLES>parseInt(user.role.toString(), 10) === role);
    return filtered;
  }

}
