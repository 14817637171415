import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UsersProvider } from '../providers/users-provider.service';
import { User } from '../../models';
import { ROLES } from '../../models/enum/roles';
import { ORGTYPE } from '../../models/enum';
import { Utils, RouterData } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class CanActivateAllowAccessViaRolesService implements CanActivate {


  constructor(private userProvider: UsersProvider) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const rg = <RouterData>route.data;
    return new Promise(resolve => {
      this.userProvider.getCurrentUser().then((user: User) => {
        if (user) {
          return Utils.allow(rg, user).then((returned: boolean) => resolve(returned));
        } else {
          resolve(false);
        }
      }).catch(err => {
        resolve(false);
      });
    });

  }
}


