import { Pipe, PipeTransform } from '@angular/core';
import { ROLES } from '../models/enum/roles';
import { ORGTYPE, OrgType } from '../models/enum';
import { OrganizationProviderService } from '../service/providers/organization-provider.service';

@Pipe({
  name: 'roleTOStr'
})
export class RoleTOStrPipe implements PipeTransform {
  orgTypes: OrgType[];
  constructor(private orgProvider: OrganizationProviderService) {
    this.orgProvider.getAllOrgTypes().then((result: OrgType[]) => {
      this.orgTypes = result;
    }).catch(err => {
      console.error(err);
    });
  }
  transform(role: ROLES, orgType: number): any {
    const forgtype = this.orgTypes.find(o => o.identifier === Number(orgType));
    // console.log(role, orgType);
    if (forgtype) {
      switch (role) {
        case ROLES.ADMINISTRATOR:
          return forgtype.admin;
        case ROLES.MANAGERONE:
          return forgtype.manager1;
        case ROLES.MANAGERTWO:
          return forgtype.manager2;
        case ROLES.USER:
          return forgtype.user;
      }
    } else {
      return 'Unknow organization';
    }
  }
}
