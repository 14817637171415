import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { SelectComponent } from 'ng2-select';
import { AdminBadgeOne } from '../../badge-design/designs/displayedbadge';
import { Venue, BadgeDesign, User, VQEvent } from '../../../models';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ORGTYPE, OrgType } from '../../../models/enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { VenuesProviderService } from '../../../service/providers/venues-provider.service';
import { VQEventsProviderService } from '../../../service/providers/v-qevents-provider.service';
import { UsersProvider } from '../../../service/providers/users-provider.service';

import { NotificationsService } from 'angular2-notifications';
import { Util } from '../../../models/tools';
import { OPTIONS } from '../../../../environments/config';
import { IManager } from '../../../models/interfaces';
import { OrganizationProviderService } from '../../../service/providers/organization-provider.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';


const QRious = require('qrious');

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss']
})
export class EventModalComponent implements OnInit, AfterViewInit {
  @ViewChild('venuesDropdown') public venuesDropdown: SelectComponent;
  public options = {
    position: ['top', 'right'],
    timeOut: 5000
  };
  angularForm = new FormGroup({
    name: new FormControl(),
    usersPerSession: new FormControl(),
    vips: new FormControl(),
    vQUseres: new FormControl(),
    sessionTime: new FormControl(),
    responsible: new FormControl()
  });
  selectedBadge: any;
  title = '';
  badgeURL;
  morningStartTimeMin = new Date();
  morningStartTimeMax = new Date();
  morningStart = new Date();
  morningEnd = new Date();
  morningEndMin = new Date();
  adminBadge = new AdminBadgeOne();
  dataStoreVenue: Venue;
  currentVenueId = 'default';
  venuesList: any[] = [];
  usersList: any[] = [];
  vqEvent = new VQEvent();
  badgeDesign = new BadgeDesign();
  currentUser: User;
  loading = false;
  add = false;
  show = false;
  edit = false;
  eventLogo = undefined;
  eventBackground = undefined;
  // select variables for venues
  selectedVenue: any = [];
  disabledVenueSelect = false;
  venues: any[] = [];
  venue: any = {};
  // select variables for types
  selectedValue: any = [];
  disabled = false;
  types: any[] = [];
  value: any = {};
  // ----
  _bsValue: Date;
  public ctrl = new FormControl('', (control: FormControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hours = value.getHours();
    const minutes = value.getMinutes();
    if (hours < 0 || hours > 23) {
      return { outOfRange: true };
    }
    if (minutes < 0 || minutes > 60) {
      return { outOfRange: true };
    }
    return null;
  });


  public ctrl2 = new FormControl('', (control: FormControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hours = value.getHours();
    const minutes = value.getMinutes();
    if (hours < 0 || hours > 23) {
      return { outOfRange: true };
    }
    if (minutes < 0 || minutes > 60) {
      return { outOfRange: true };
    }
    return null;
  });
  step: any;

  get bsValue(): Date {
    return this._bsValue;
  }

  set bsValue(v: Date) {
    console.log(v);
    this._bsValue = v;
  }

  _bsRangeValue: any = [new Date(), new Date()];
  get bsRangeValue(): any {
    return this._bsRangeValue;
  }

  set bsRangeValue(v: any) {
    this._bsRangeValue = v;
  }

  public minTime: Date = new Date();
  public maxTime: Date = new Date();

  displayedQrValue = {
    eventId: '',
    hash: ''
  };
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  minTimeAfter = new Date();
  maxTimeAfter = new Date();
  afternoonStart = new Date();
  afternoonEnd = new Date();
  afternoonEndMin = new Date();


  initEndMorning(event) {
    const d = new Date();
    d.setHours(this.morningStart.getHours());
    d.setMinutes(this.morningStart.getMinutes());
    if (this.morningEnd < d) {
      this.morningEnd = d;
    }
    this.morningEndMin = d;
  }

  initEndAfternoon(event) {
    const d = new Date();
    d.setHours(this.afternoonStart.getHours());
    d.setMinutes(this.afternoonStart.getMinutes());
    if (this.afternoonEnd < d) {
      this.afternoonEnd = d;

    }
    this.afternoonEndMin = d;
  }

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private venuesProvider: VenuesProviderService,
    private vqeventprovider: VQEventsProviderService,
    private usersProvider: UsersProvider,
    private orgProvider: OrganizationProviderService,
    private _notificationsService: NotificationsService,
    private translateservice: TranslateService
  ) {/* localStorage.setItem('design.xml','');*/
    this.createForm();
    this.morningStartTimeMin.setHours(0);
    this.morningStartTimeMin.setMinutes(0);

    this.minTimeAfter.setHours(12);
    this.minTimeAfter.setMinutes(0);
    this.morningStart.setHours(0);
    this.morningStart.setMinutes(0);

    this.morningStartTimeMax.setHours(11);
    this.morningStartTimeMax.setMinutes(59);


    this.maxTimeAfter.setHours(23);
    this.maxTimeAfter.setMinutes(59);

  }

  modalClosed(event) {
    console.log('Added venue !! ', event);

    this.loadVenues(event);
  }

  loadVenues(event = null) {
    this.loading = true;
    this.venuesProvider.getAllVenues().then((venues: any[]) => {
      localStorage.setItem('VenuesList', JSON.stringify(venues));
      this.loading = false;
      this.venuesList = venues;
      this.venues = [];
      this.venuesList.forEach(venue => {
        // console.log(venue);
        if (this.vqEvent !== undefined && venue.id === this.vqEvent.venueId) {
          this.selectedVenue[0] = { id: venue.id, text: venue.name };
        }
        this.venues.push({ id: venue.id, text: venue.name });
      });
      // select currently added venue
      if (event) {
        this.selectedVenue[0] = { id: event.object.id, text: event.object.name };

      }

    }).catch(err => {
      this.loading = false;

      console.log(err);
    });
  }

  createForm() {
    this.angularForm = this.fb.group({
      name: [null, Validators.required],
      usersPerSession: [null, Validators.required],
      vips: [null, Validators.required],
      vQUseres: [null, Validators.required],
      sessionTime: [null, Validators.required],
      responsible: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.loading = false;
    console.log(this.vqEvent);
    this.orgProvider.getAllOrgTypes().then((result: OrgType[]) => {
      result.forEach((el) => {
        this.types.push({ id: el.identifier, text: el.name });
      });
    }).catch(err => { console.error(err); });
    this.loadVenues();
    if (this.vqEvent.type !== undefined) {
      this.types.forEach(element => {
        if (element.id === this.vqEvent.type) {
          this.selectedValue[0] = element;
          return;
        }
      });
    }

  }

  selectBadge($event) {
    console.log($event);
    this.selectedBadge = $event;
  }

  /**
   * when the vq event is set this method is triggered
   */
  reload() {
    // console.log('Event=>', event);
    this.displayedQrValue = { eventId: this.vqEvent.id, hash: this.vqEvent.hash };
    console.log(this.displayedQrValue);

    this.adminBadge.eventName.value = this.vqEvent.name;
    this.adminBadge.eventLogo.value = this.vqEvent.logoUrl;
    const qr = new QRious({ value: JSON.stringify(this.displayedQrValue) });
    // console.log(qr.toDataURL());
    this.adminBadge.qrCodeUrl.value = qr.toDataURL();
    const start = new Date(this.vqEvent.startDate);
    if (this.vqEvent.timeInterval && this.vqEvent.timeInterval.morning && this.vqEvent.timeInterval.morning.start) {
      const morningStart = new Date(this.vqEvent.timeInterval.morning.start);
      start.setHours(morningStart.getHours(), morningStart.getMinutes(), 0, 0);
    }
    this.adminBadge.eventDate.value = new Date(start).toLocaleString('en-US');
    this.adminBadge.normalQueueSize.value = this.vqEvent.normalQueue.size + '';
    this.adminBadge.personsPerSession.value = this.vqEvent.usersPerSession + '';
    this.adminBadge.sessionDuration.value = this.vqEvent.sessionTime + '';
    this.adminBadge.vips.value = this.vqEvent.quota.vips + ''; // Todo: need to be changed to count
    this.adminBadge.vqUsers.value = this.vqEvent.quota.vQUsers + ''; // Todo: need to be changed to count from database;
    try {
      if (!this.add) {
        this.vqeventprovider.getEventImages(this.vqEvent.id, true).then(success => {
          this.loading = false;
          this.eventLogo = success;
        }).catch(err => {
          this.loading = false;
          this.eventLogo = undefined;
        });

        this.loading = true;

        this.vqeventprovider.getEventImages(this.vqEvent.id, false).then(success => {
          this.loading = false;
          this.eventBackground = success;
        }).catch(err => {
          this.loading = false;
          this.eventBackground = undefined;
        });
        this.loading = true;
        this.vqeventprovider.getEventBadge(this.vqEvent.adminBadge).then(url => {
          this.loading = false;
          this.badgeURL = url;
        }).catch(err => {
          this.loading = false;
          console.log(err);
        });
      }
    } catch (error) {
      this.loading = false;
    }

  }

  ngAfterViewInit(): void {
    if (this.vqEvent.venueId !== undefined && this.vqEvent.venueId !== '') {
      this.currentVenueId = this.vqEvent.venueId;
    }
    if (this.vqEvent.id !== undefined) {
      console.log('Event=>', this.vqEvent);
      this.displayedQrValue = { eventId: this.vqEvent.id, hash: this.vqEvent.hash };

      this.adminBadge.eventName.value = this.vqEvent.name;
      this.adminBadge.eventLogo.value = this.vqEvent.logoUrl;
      this.adminBadge.qrCodeUrl.value = $('#qrCode').children().first().attr('src');
    }
  }

  goToStep(step, event$ = null) {
    console.log(step);
    const steps = ['details', 'time', 'location', 'badge', 'managers'];
    const anchors = ['detailsAnchor', 'locationAnchor', 'badgeAnchor', 'managersAnchor', 'timeAnchor'];
    $('.modal-lg').removeClass('bigger');

    if (step === 'badge') {
      $('.modal-lg').addClass('bigger');
    }
    this.step = step;
    $('#' + step).removeClass('hidden');
    if (event$ !== null) {

      $('#' + event$.target.id).addClass('selected');
      $('#' + event$.target.id).parent().addClass('current');
      anchors.forEach(anchor => {
        if (anchor !== event$.target.id) {
          $('#' + anchor).removeClass('selected');
          $('#' + anchor).parent().removeClass('current');
        }

      });
    } else {

      $('#' + step + 'Anchor').addClass('selected');
      $('#' + step + 'Anchor').parent().addClass('current');
      anchors.forEach(anchor => {
        if (anchor !== (step + 'Anchor')) {
          $('#' + anchor).removeClass('selected');
          $('#' + anchor).parent().removeClass('current');
        }

      });
    }

    steps.forEach(arraystep => {

      if (arraystep !== step) {
        $('#' + arraystep).addClass('hidden');
      }
    });

  }

  validateTime(event$) {
    // console.log(this.startingTime === null);
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
  }

  validateNumbers() {
    if (this.vqEvent.usersPerSession < 1) {
      this.vqEvent.usersPerSession = 1;
    }
    if ((this.vqEvent.quota.vips + this.vqEvent.quota.vQUsers) > this.vqEvent.usersPerSession ||
      this.vqEvent.usersPerSession < (this.vqEvent.quota.vips + this.vqEvent.quota.vQUsers)) {

      this.vqEvent.usersPerSession = this.vqEvent.quota.vips + this.vqEvent.quota.vQUsers;
    }

    if (this.vqEvent.quota.vips < 0) {
      this.vqEvent.quota.vips = 0;
    }
    if (this.vqEvent.quota.vQUsers < 0) {
      this.vqEvent.quota.vQUsers = 0;
    }
    if (this.vqEvent.coolDownTime < 0) {
      this.vqEvent.coolDownTime = 0;
    }
    if (this.vqEvent.sessionTime < 0) {
      this.vqEvent.sessionTime = 0;
    }
  }

  // --- select type methods
  /**
   * Selected value
   */
  public selectedVenue_(venue: any): void {

    this.dataStoreVenue = new Venue(venue.id);
    this.currentVenueId = venue.id;
    this.vqEvent.venueId = '' + venue.id;
    // this.dataStoreVenue.id = venue.id;
    console.log('Selected value is: ', venue, this.vqEvent.venueId);
  }

  /**
   * Removed value
   * @param venue
   */
  public removedVenue(venue: any): void {
    console.log('Removed value is: ', venue);
  }

  /**
   * Search input
   * @param venue
   */
  public typedVenue(value: any): void {
    console.log('New search input: ', value);
  }

  public refreshVenue(venue: any): void {
    this.venue = venue;
  }

  public pushVenue(venue: Venue) {
    console.log(venue);
    if (this.add || this.edit) {
      // console.log(this.venuesList);
      this.venues = [];
      this.venuesList.forEach(element => {
        this.venues.push({ id: element.id, text: element.name });
      });
    }
    this.venues.push({ id: venue.id, text: venue.name });
    this.venuesDropdown.active = [];
    this.selectedVenue[0] = { id: venue.id, text: venue.name };
    this.venuesDropdown.active = this.selectedVenue;
    this.selected({ id: venue.id, text: venue.name });
  }

  // ---------- select venue methodes
  /**
   * Selected value
   */
  public selected(value: any): void {
    this.vqEvent.type = value.id;
    console.log('Selected value is: ', value);
  }

  /**
   * Removed value
   * @param value
   */
  public removed(value: any): void {
    console.log('Removed value is: ', value);
  }

  /**
   * Search input
   * @param value
   */
  public typed(value: any): void {
    console.log('New search input: ', value);
  }

  public refreshValue(value: any): void {
    this.value = value;
  }

  /**
   * Update Event
   */
  updateEvent() {

    if (!this.angularForm.valid) {
      for (const key in this.angularForm.controls) {
        if (this.angularForm.controls.hasOwnProperty(key)) {
          this.angularForm.controls[key].markAsDirty();

        }
      }
    } else {
      this.validateNumbers();
      this.vqEvent.timeInterval = {
        morning: { start: this.morningStart.getTime(), end: this.morningEnd.getTime() },
        afternoon: { start: this.afternoonStart.getTime(), end: this.afternoonEnd.getTime() }
      };
      const start = new Date(this.vqEvent.startDate);
      if (this.vqEvent.timeInterval && this.vqEvent.timeInterval.morning && this.vqEvent.timeInterval.morning.start) {
        const morningStart = new Date(this.vqEvent.timeInterval.morning.start);
        start.setHours(morningStart.getHours(), morningStart.getMinutes(), 0, 0);
      }
      const end = new Date(this.vqEvent.endDate);
      if (this.vqEvent.timeInterval && this.vqEvent.timeInterval.afternoon && this.vqEvent.timeInterval.afternoon.end) {
        const afternoonEnd = new Date(this.vqEvent.timeInterval.afternoon.end);
        end.setHours(afternoonEnd.getHours(), afternoonEnd.getMinutes(), 0, 0);
      }
      this.vqEvent.startDate = start.getTime();
      this.vqEvent.endDate = end.getTime();
      this.loading = true;
      const felist = [];
      this.currentUser.eventsAdmin.forEach(event => {
        if (event.id === this.vqEvent.id) {
          felist.push(event);
          return;
        }
      });
      if (felist.length === 0) {
        this.currentUser.eventsAdmin.push({ id: this.vqEvent.id });
      }
      this.usersProvider.updateUser(this.currentUser).then(sucessUser => {
        this.vqEvent.venueId = this.currentVenueId;
        if (this.vqEvent.quota === undefined) {
          this.vqEvent.quota = { vips: 0, vQUsers: 0 };
        }
        console.log('Date Range---->', this._bsRangeValue);
        // console.log('Regeneration Time--->', this.badgeDesign.regenerationTime);
        this.vqEvent.startDate = (<Date>this.bsRangeValue[0]).getTime();
        this.vqEvent.endDate = (<Date>this._bsRangeValue[1]).getTime();
        // this.badgeDesign = new models.BadgeDesign(this.vqEvent.eventBadgeDesign.id, this.badgeDesign.hash);
        if (this.selectedBadge) {
          this.badgeDesign = this.selectedBadge;
          console.log(this.selectedBadge, this.badgeDesign);
          this.vqEvent.eventBadgeDesign = { id: '' };
          this.vqEvent.eventBadgeDesign.id = this.selectedBadge.id ? this.selectedBadge.id : undefined;
        }
        if (!this.vqEvent.hash) {
          this.vqEvent.hash = Util.generateCode();
        }
        this.vqEvent.organizationId = this.currentUser.organizationId;

        this.vqeventprovider.updateEvent(this.vqEvent, this.badgeDesign).then((success: any) => {
          if (success.success) {
            if (!this.eventLogo && !this.eventBackground) {
              this.loading = false;
            }
            if (this.eventLogo) {
              this.vqeventprovider.uploadeventImage(this.vqEvent.id, true, this.eventLogo.split(',')[1]).then(successUpload => {
                this.loading = false;
                this.translateservice.get('notifications.file.upload.success').subscribe(value => {
                  this._notificationsService.success(value);
                });
              }).catch(err => {
                console.log(err);
                this.translateservice.get('notifications.file.upload.error').subscribe(value => {
                  this._notificationsService.error(value);
                });
              });

            }
            if (this.eventBackground) {
              this.vqeventprovider.uploadeventImage(this.vqEvent.id, false, this.eventBackground.split(',')[1]).then(successUpload => {
                this.loading = false;
                this.translateservice.get('notifications.file.upload.success').subscribe(value => {
                  this._notificationsService.success(value);
                });
              }).catch(err => {
                console.log(err);
                this.translateservice.get('notifications.file.upload.error').subscribe(value => {
                  this._notificationsService.error(value);
                });
              });
            }
            this.translateservice.get('notifications.event.update.success').subscribe(value => {
              this._notificationsService.success(value);
            });
            this.closed.emit({ success: true, object: this.vqEvent });
            this.refresh.emit({ success: true });

          } else {
            console.error(success);
            this.translateservice.get('notifications.event.update.error').subscribe(value => {
              this._notificationsService.error(value);
            });
            this.closed.emit({ success: false, object: this.vqEvent });
          }
          console.log(success);
        }).catch(err => {
          console.error(err);
          this.translateservice.get('notifications.event.update.error').subscribe(value => {
            this._notificationsService.error(value);
          });
        });
      });
    }
  }

  /**
   *
   */
  public addEvent() {
    if (!this.angularForm.valid) {
      for (const key in this.angularForm.controls) {
        if (this.angularForm.controls.hasOwnProperty(key)) {
          this.angularForm.controls[key].markAsDirty();

        }
      }
    } else {
      this.loading = true;
      this.validateNumbers();

      this.vqEvent.timeInterval = {
        morning: { start: this.morningStart.getTime(), end: this.morningEnd.getTime() },
        afternoon: { start: this.afternoonStart.getTime(), end: this.afternoonEnd.getTime() }
      };

      const start = new Date(this.vqEvent.startDate);
      if (this.vqEvent.timeInterval && this.vqEvent.timeInterval.morning && this.vqEvent.timeInterval.morning.start) {
        const morningStart = new Date(this.vqEvent.timeInterval.morning.start);
        start.setHours(morningStart.getHours(), morningStart.getMinutes(), 0, 0);
      }
      const end = new Date(this.vqEvent.endDate);
      if (this.vqEvent.timeInterval && this.vqEvent.timeInterval.afternoon && this.vqEvent.timeInterval.afternoon.end) {
        const afternoonEnd = new Date(this.vqEvent.timeInterval.afternoon.end);
        end.setHours(afternoonEnd.getHours(), afternoonEnd.getMinutes(), 0, 0);
      }
      this.vqEvent.startDate = start.getTime();
      this.vqEvent.endDate = end.getTime();
      // console.log(this.currentUser);
      this.vqEvent.venueId = this.currentVenueId;
      if (this.vqEvent.sessionTime === undefined) {
        this.vqEvent.sessionTime = 0;
      }
      const event = this.vqEvent;
      this.loading = true;
      let badgeDesign = new BadgeDesign();
      console.log('this.selectedBadge', this.selectedBadge);
      if (this.selectedBadge) {
        badgeDesign = this.selectedBadge;
        console.log(this.selectedBadge, badgeDesign);
      }
      // badgeDesign.regenerationTime = this.badgeDesign.regenerationTime;
      badgeDesign.userid = this.currentUser.id;
      if (this.selectedBadge !== undefined) {
        if (this.selectedBadge.id !== undefined) {
          badgeDesign.id = this.selectedBadge.id;
        }
      }
      // push admin on cloud function
      // event.admins.push({ id: this.currentUser.id, fullName: this.currentUser.firstName + ' ' + this.currentUser.lastName });
      this.vqEvent.startDate = (<Date>this.bsRangeValue[0]).getTime();
      this.vqEvent.endDate = (<Date>this._bsRangeValue[1]).getTime();
      // console.log('Event', event);

      this.usersProvider.getCurrentUser().then((user: User) => {
        if (user && user.organizationId) {
          event.organizationId = user.organizationId;
          this.vqeventprovider.addEvent(event, badgeDesign, user.organizationId).then((success: any) => {
            // console.log('Add Event: ', success);
            if (this.eventLogo) {
              this.vqeventprovider.uploadeventImage(success.eventId, true, this.eventLogo.split(',')[1]).then(successUpload => {
                this.loading = false;
                this.translateservice.get('notifications.file.upload.success').subscribe(value => {
                  this._notificationsService.success(value);
                });
              }).catch(err => {
                console.log(err);
                this.translateservice.get('notifications.file.upload.error').subscribe(value => {
                  this._notificationsService.error(value);
                });
              });
            }

            if (this.eventBackground) {

              this.vqeventprovider.uploadeventImage(success.eventId, false, this.eventBackground.split(',')[1]).then(successUpload => {
                this.loading = false;
                this.translateservice.get('notifications.file.upload.success').subscribe(value => {
                  this._notificationsService.success(value);
                });
              }).catch(err => {
                console.log(err);

              });
            }
            this.vqEvent = {
              admins: [{ fullName: this.currentUser.firstName + ' ' + this.currentUser.lastName, id: this.currentUser.id }],
              coolDownTime: this.vqEvent.coolDownTime,
              currentAverageWaitingTime: this.vqEvent.currentAverageWaitingTime,
              deleted: false,
              hash: this.vqEvent.hash,
              description: this.vqEvent.description,
              endDate: this.vqEvent.endDate,
              startDate: this.vqEvent.startDate,
              eventBadgeDesign: { id: success.badgeId },
              adminBadge: success.adminBadge,
              id: success.eventId,
              isPremium: false,
              quota: this.vqEvent.quota,
              organizationId: user.organizationId,
              name: this.vqEvent.name,
              sessionTime: this.vqEvent.sessionTime,
              type: this.vqEvent.type,
              usersPerSession: this.vqEvent.usersPerSession,
              venueId: this.vqEvent.venueId,
              kind: this.vqEvent.kind,
              logoUrl: this.vqEvent.logoUrl,
              managers: this.vqEvent.managers,
              mapCoord: this.vqEvent.mapCoord,
              normalQueue: this.vqEvent.normalQueue,
              socialMedias: this.vqEvent.socialMedias,
              vQSize: this.vqEvent.vQSize
            };
            this.badgeDesign = {
              id: success.badgeId,
              kind: this.badgeDesign.kind,
              // regenerationTime: this.badgeDesign.regenerationTime,
              adminsvg: this.badgeDesign.adminsvg,
              type: this.badgeDesign.type,
              adminxml: this.badgeDesign.adminxml,
              name: this.badgeDesign.name,
              primaryColor: this.badgeDesign.primaryColor,
              secondaryColor: this.badgeDesign.secondaryColor,
              userid: this.badgeDesign.userid,
              userxml: this.badgeDesign.userxml,
              usersvg: this.badgeDesign.usersvg,
            };
            this.closed.emit({ success: true, object: { event: this.vqEvent, badge: this.badgeDesign }, addEvent: true });
            // location.reload();
            this.loading = false;
          }).catch(err => {
            this.loading = false;
            console.log(err);
            this._notificationsService.error(
              'Add vQEvent',
              err.message + ' Details :' + err.message
            );
          });
        } else {
          this.translateservice.get('notifications.venue.update.error').subscribe(value => {
            this._notificationsService.error(value);
          });
        }
      }).catch(err => {
        this.translateservice.get('notifications.venue.update.error').subscribe(value => {
          this._notificationsService.error(value);
        });
      });
    }
  }

  uploadLogo(event) {
    const TYPES = { 'image/jpeg': 'jpg', 'image/png': 'png', 'image/gif': 'gif' };
    const target = event.target || event.srcElement;
    const files = target.files;
    const file = files[0];
    if (file && files) {
      if (file.size <= OPTIONS.MAX_AVATAR_UPLOAD_SIZE) {
        if (TYPES[file.type]) {

          console.log(file);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = ((e: any) => {
            this.eventLogo = e.target.result;
          });

        } else {
          this.translateservice.get('notifications.file.upload.typeerror').subscribe(value => {
            this._notificationsService.alert(value);
          });
        }

      } else {
        this.translateservice.get('notifications.file.upload.maxsizeerror', { size: '350kb' }).subscribe(value => {
          this._notificationsService.alert(value);
        });
      }
    }
  }

  uploadBackground(event) {
    const TYPES = { 'image/jpeg': 'jpg', 'image/png': 'png', 'image/gif': 'gif' };
    const target = event.target || event.srcElement;
    const files = target.files;
    const file = files[0];
    if (file && files) {
      if (file.size <= OPTIONS.MAX_AVATAR_UPLOAD_SIZE) {
        if (TYPES[file.type]) {
          console.log(file);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = ((e: any) => {
            this.eventBackground = e.target.result;
          });

        } else {
          this.translateservice.get('notifications.file.upload.typeerror').subscribe(value => {
            this._notificationsService.alert(value);
          });
        }

      } else {
        this.translateservice.get('notifications.file.upload.maxsizeerror', { size: '350kb' }).subscribe(value => {
          this._notificationsService.alert(value);
        });
      }
    }
  }


  /**
   * Delete Manager
   * @param manager
   * @param eventDetails
   */
  public deleteManagerFromEvent(manager: IManager) {
    this.translateservice.get('notifications.manager.delete.confirm').subscribe(value => {
      if (confirm(value)) {
        const index = this.vqEvent.managers.indexOf(manager);
        this.loading = true;
        if (index > -1) {
          this.vqEvent.managers.splice(index, 1);
        }
        console.log('Event ', this.vqEvent);
        this.usersProvider.getWithUid(manager.id).then((founduser: User) => {
          // delete event from user manager list
          // the index of the event
          let iev = -1;
          // geting the index of current event
          founduser.eventsManager.forEach(event => {
            if (event.id === this.vqEvent.id) {
              iev = founduser.eventsManager.indexOf(event);
              return;
            }
          });
          if (iev > -1) {
            founduser.eventsManager.splice(iev, 1);
          }
          console.log('Found user', founduser);
          // - update user
          this.usersProvider.updateUser(founduser).then(updatedUser => {
            // update event
            this.vqeventprovider.updateEventOnly(this.vqEvent).then((success: any) => {
              this.loading = false;
              const data = {
                titleNoti: `VQpass Manager`,
                bodyNoti: `You have been deleted from ${this.vqEvent.name} as a Manager !`,
                type: 'manager',
                uid: founduser.id,
                data: { eventId: this.vqEvent.id, assign: false }
              };

              this.usersProvider.sendNotification(data).then(noti => {
                console.log('Notification sent', noti, ' To ', founduser);
              });
              this.refresh.emit({ success: true });
              this.translateservice.get('notifications.manager.delete.success').subscribe(values => {
                this._notificationsService.success(values);
              });
            }).catch(err => {
              this.loading = false; this.translateservice.get('notifications.manager.delete.error').subscribe(values => {
                this._notificationsService.error(values);
              });
            });
          }).catch(err => {
            console.error(err);
            this.loading = false;
            this.loading = false; this.translateservice.get('notifications.manager.delete.error').subscribe(values => {
              this._notificationsService.error(values);
            });
          });
        });
      }
    });
  }

  initStartAfternoon($event) {
    console.log($event, this.morningEnd);

    const d = new Date();
    d.setHours(this.morningEnd.getHours());
    d.setMinutes(this.morningEnd.getMinutes());
    if (this.afternoonStart < d) {
      this.afternoonStart = d;

    }
    this.minTimeAfter = d;
  }
}
