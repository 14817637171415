import { Component, EventEmitter, OnInit, Output, Input, OnDestroy, ElementRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { VQEventEmitterService } from '../../service/vq-event-emitter/vqevent-emitter.service';
import { RealtimeDatabaseService } from '../../service/realtime-database/realtime-database.service';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { User } from '../../models';
import { isFunction } from 'util';

@Component({
  selector: 'app-chat-conversation',
  templateUrl: './chat-conversation.component.html',
  styleUrls: ['./chat-conversation.component.scss']
})
export class ChatConversationComponent implements OnInit, OnDestroy {
  convName: string;
  @Output() closed = new EventEmitter<boolean>();
  @Input() members: User[];
  @Input() messages: Observable<any[]>;
  @Input() conversation;
  @Input() group;
  @Input() currentUser: User;
  isCreator = false;
  message;
  sub_emitter: Subscription;
  subConvSeen: Subscription;
  subGroupSeen: Subscription;

  constructor(private vqEventEmitter: VQEventEmitterService,
    private realtimeDatabase: RealtimeDatabaseService,
    private userProvider: UsersProvider
  ) {
    this.sub_emitter = this.vqEventEmitter.Item$.subscribe((value: any) => {
      if (value && value.data && value.event) {
        const loadGroup = () => {
          this.isCreator = false;
          this.conversation = undefined;
          if (value.data.key) {
            this.realtimeDatabase.getGroupByKey(value.data.key).then(snap => {
              this.group = snap;
              if (this.currentUser.id === this.group.val().creator) {
                console.log('Im the creator of this group');
                this.isCreator = true;
              }
              this.convName = this.getMember(null, true);
              this.subGroupSeen = this.realtimeDatabase.seenGroup(value.data.key);
              // this.subGroupSeen.unsubscribe();
              this.messages = this.realtimeDatabase.getMessagesByKey(value.data.key).valueChanges();
              this.messages.subscribe();

            });
          }
        };
        const loadConv = () => {
          this.isCreator = false;
          this.group = undefined;

          if (value.data.key) {
            this.realtimeDatabase.getConversationByKey(value.data.key).then(snap => {
              this.conversation = snap;
              // both users can delete the conversation
              this.isCreator = true;
              // if (this.currentUser.id === this.conversation.val().creator) {
              //   this.isCreator = true;
              // }
              this.convName = this.getMember();
              this.subConvSeen = this.realtimeDatabase.seenConv(value.data.key);

              this.messages = this.realtimeDatabase.getMessagesByKey(value.data.key).valueChanges();
              this.messages.subscribe();

            });
          }
        };

        if (value.event === 'conv-trigger') {
          loadConv();
        } else if (value.event === 'group-trigger') {
          loadGroup();
        } else if (value && value.event === 'group-created') {
          loadGroup();
        } else if (value && value.event === 'scroll-down') {
          this.scrollToBottom();
        }
      }
    });
  }
  /**
   * Scroll to bottom of messages
   */
  scrollToBottom(ele: string | ElementRef = '#messages') {
    let height = 0;
    $($(ele).children()).each(function (i, objvalue) {
      height += $(this).height();
    });
    if ($(ele).scrollTop() < height) {
      $(ele).animate({ scrollTop: height });
      console.log('scroll to ', height);

    }
  }
  /**
   * close chat
   */
  close() {
    this.closed.emit(true);
  }
  /**
   * Send message
   */
  sendMessage() {
    if (this.conversation) {
      this.realtimeDatabase.sendMessage(this.conversation.key, this.currentUser.id, this.message, false);
      this.message = undefined;
      this.scrollToBottom();
    } else if (this.group) {
      console.log(this.group);
      this.realtimeDatabase.sendMessage(this.group.key, this.currentUser.id, this.message, true);
      this.message = undefined;
      this.scrollToBottom();
    }

  }
  ngOnInit() {
    // setInterval(this.scrollToBottom(), 1000);

  }
  edit() {
    this.vqEventEmitter.emitValue({ event: 'edit-group', data: { group: this.group } });
  }
  delete() {
    if (this.group) {
      if (confirm('Do you realy want to delete this group this action is irreversible ?!')) {
        this.realtimeDatabase.deleteGroup(this.group.key);
        this.group = null;
        this.convName = null;
      }
    }

    if (this.conversation) {
      if (confirm('Do you realy want to delete this conversation this action is irreversible ?!')) {
        this.realtimeDatabase.deleteConversation(this.conversation.key);
        this.conversation = null;
        this.convName = null;
      }
    }
  }
  getMember(uid = null, isgroup = false) {
    if (uid) {
      const founduser = this.members.find(u => u.id === uid);
      if (founduser) {
        return `${founduser.firstName} ${founduser.lastName}`;
      }
    } else {
      if (this.conversation && this.conversation.val() && !isgroup) {
        for (const key in this.conversation.val()) {
          if (this.conversation.val().hasOwnProperty(key)) {

            if (key !== this.currentUser.id) {
              uid = key;
              break;
            }
          }
        }
        const finduser = this.members.find(u => u.id === uid);
        // console.log('Find user ..', finduser, ' with uid', uid, ' in ', this.members);
        if (finduser) {
          return `${finduser.firstName} ${finduser.lastName}`;

        } else {
          return 'Unkown';
        }

      } else if (isgroup) {
        if (this.group && isFunction(this.group.val) && this.group.val()) {

          return this.group.val().name + ' -Group-';
        } else {
          return 'Unkown';
        }
      }
    }

  }
  ngOnDestroy(): void {
    this.sub_emitter.unsubscribe();
    if (this.subConvSeen) {
      this.subConvSeen.unsubscribe();

    }
    if (this.subGroupSeen) {
      this.subGroupSeen.unsubscribe();

    }
  }

}
