import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { OPTIONS } from '../../../../environments/config';
import { DOCUMENT } from '@angular/common';
import { NotificationsService } from 'angular2-notifications';
import { UsersProvider } from '../../../service/providers/users-provider.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-choose-avatar-modal',
  templateUrl: './choose-avatar-modal.component.html',
  styleUrls: ['./choose-avatar-modal.component.scss']
})
export class ChooseAvatarModalComponent implements OnInit, AfterViewInit {
  loading = false;
  currentUser: any = {};
  public toggled = false;
  fileType = null;
  public options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true

  };
  constructor(@Inject(DOCUMENT) private document, private userProvider: UsersProvider,
    private _notificationsService: NotificationsService, private translateService: TranslateService
  ) { }
  ngAfterViewInit(): void {

  }
  ngOnInit(): void {

  }

  uploadImage(event) {
    const TYPES = { 'image/jpeg': 'jpg', 'image/png': 'png', 'image/gif': 'gif' };
    const target = event.target || event.srcElement;
    const files = target.files;
    const file = files[0];
    if (file && files) {
      if (file.size <= OPTIONS.MAX_AVATAR_UPLOAD_SIZE) {
        if (TYPES[file.type]) {
          this.fileType = TYPES[file.type];
          console.log(file);
          const reader = new FileReader();
          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsBinaryString(file);
        } else {
          this.translateService.get('notifications.file.upload.typeerror').subscribe(value => {
            this._notificationsService.alert(value);
          });

        }

      } else {
        this.translateService.get('notifications.file.upload.maxsizeerror', { size: '350kb' }).subscribe(value => {
          this._notificationsService.alert(value);
        });
      }
    }


  }
  private _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    const binary = btoa(binaryString);

    this.userProvider.updateProfilePicture(binary, this.fileType, this.currentUser.id).then((success) => {
      this.translateService.get('notifications.file.upload.success').subscribe(value => {
        this._notificationsService.success(value);

      });
      console.log(success);
    }).catch(err => {
      console.log(err);
    });


  }
}
