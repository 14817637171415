import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { User, Venue } from '../../../models';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { VenueModalComponent } from '../venue-modal/venue-modal.component';
import { VqEmitterService } from '../../../service/vqEmitter/vq-emitter.service';

@Component({
  selector: 'app-modal-service-venues',
  templateUrl: './modal-service-venues.component.html',
  styleUrls: ['./modal-service-venues.component.scss']
})
export class ModalServiceVenuesComponent {
  page = 'Venue';
  bsModalRef: BsModalRef;
  @Input() add = false;
  @Input() edit = false;
  @Input() currentUser: User;
  @Output() addClicked: EventEmitter<any> = new EventEmitter();
  @Input() venue: Venue = new Venue();
  constructor(private modalService: BsModalService, private vqEmitter: VqEmitterService) { }
  openModalWithComponent() {
    this.bsModalRef = this.modalService.show(VenueModalComponent, { class: 'modal-lg' });
    /**patch 1.4.2 */
    this.bsModalRef.content.addClicked.subscribe(($venue) => {
      this.addClicked.emit($venue);
      this.vqEmitter.emitValue({ action: 'reload_eventlist' });

    });
    /**-- */
    this.bsModalRef.content.add = this.add;
    this.bsModalRef.content.edit = this.edit;
    this.bsModalRef.content.venue = this.venue;
    this.bsModalRef.content.currentUser = this.currentUser;
  }

}
