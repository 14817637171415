import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Organization } from '../../models/organization';
import { User } from '../../models';
import { RealtimeDatabaseService } from '../../service/realtime-database/realtime-database.service';
import { AngularFireList } from 'angularfire2/database';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { VQEventEmitterService } from '../../service/vq-event-emitter/vqevent-emitter.service';
import { Subscription, Observable } from 'rxjs';
import { DataSnapshot } from 'angularfire2/database/interfaces';

// import { Observable } from '@firebase/util';

@Component({
  selector: 'app-chat-sidebar',
  templateUrl: './chat-sidebar.component.html',
  styleUrls: ['./chat-sidebar.component.scss']
})
export class ChatSidebarComponent implements OnInit, OnDestroy {

  searchInput: any;
  @Input()
  organization: Organization = {};
  groups: Observable<any[]>;
  conversations: Observable<any[]>;
  @Input() currentUser: User;
  currentMessages;
  currentConversationkey;
  loading = false;
  @Input()
  members: User[] = [];
  filteredMembers: User[] = [];
  sub_emitter: Subscription;
  @Output() showAddGroup = new EventEmitter<boolean>();
  notify: any = {};
  subConv: Subscription[] = [];


  constructor(private realtimeDatabase: RealtimeDatabaseService,
    private userprovider: UsersProvider, private vqEventEmitter: VQEventEmitterService) {
    this.loading = true;
    this.userprovider.getCurrentUser().then((user: User) => {
      this.currentUser = user;

      this.realtimeDatabase.getCurrentUserConversations(user.id).then((convsub: AngularFireList<any>) => {
        this.conversations = convsub.snapshotChanges();
        this.subConv.push(this.conversations.subscribe((values: DataSnapshot[]) => {
          this.subConv.push(this.realtimeDatabase.checkUsersNotification().subscribe((value: any) => {
            values.forEach(element => {
              // console.log('VALUE',value)

              if (element.key === value.key) {
                this.notify[element.key] = value.isNotif;
                // console.log('NOTIFY', value);
              }
            });
          }));
        }, error => {
          console.log('Error while subscribing to snapshot', error);
        }));
        this.realtimeDatabase.getCurrentUserGroups(user.id).then((groupsb) => {
          this.groups = groupsb.snapshotChanges();
          this.loading = false;
          this.subConv.push(this.groups.subscribe(value => {
            // console.log('Groups --> ');

            //  value.forEach(element => {
            //    console.log(element.payload.val());
            //  });
          }, error => {
            console.log('Error while subscribing to snapshot', error);
          }));
        }).catch(err => {

        });
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);

    });
    this.sub_emitter = this.vqEventEmitter.Item$.subscribe((value: any) => {
    });
  }

  createConversation(userId) {
    console.log(userId);
    this.realtimeDatabase.createConversation(this.currentUser.id, userId)
      .then((success: any) => {
        this.currentMessages = success.messages;
        this.currentConversationkey = success.key;
        console.log('Messages', success);
        this.vqEventEmitter.emitValue({
          event: 'conv-trigger',
          data: { messages: this.currentMessages, key: this.currentConversationkey }
        });
      }
      ).catch(err => {
        console.log(err);
      });
  }

  getMessages(conversation) {

    this.vqEventEmitter.emitValue({ event: 'conv-trigger', data: { messages: this.currentMessages, key: conversation.key } });
  }
  getGroupMessages(group) {
    this.vqEventEmitter.emitValue({ event: 'group-trigger', data: { messages: this.currentMessages, key: group.key } });
  }
  ngOnInit() {
    this.filteredMembers = this.members;
  }
  searchForMembers() {
    console.log('search for', this.searchInput);
    if (!this.searchInput || this.searchInput === '') {
      this.filteredMembers = JSON.parse(JSON.stringify(this.members));
    }
    // this.members.forEach(element => {
    //   if (this.usersIds.find(u => u === element.id)) {
    //     element.checked = true;
    //   }
    // });
    this.filteredMembers = this.members.filter(user =>
      (user.email.toLowerCase().startsWith(this.searchInput.toLowerCase()) ||
        user.firstName.toLowerCase().startsWith(this.searchInput.toLowerCase()) ||
        user.lastName.toLowerCase().startsWith(this.searchInput.toLowerCase())));
  }
  getReceiver(conversation): any {
    let uid;
    // console.log(conversation.payload.val());
    if (conversation.payload && conversation.payload.val()) {
      const lastKey = Object.keys(conversation.payload.val()).sort().reverse()[0];
      for (const key in conversation.payload.val()) {
        if (conversation.payload.val().hasOwnProperty(key)) {
          // console.log('Conversation', conversation.payload.val());
          if (key !== this.currentUser.id) {
            uid = key;
            break;
          }

        }
      }
      let foundreceiver = this.members.find(u => u.id === uid);
      if (!foundreceiver) {
        // console.log('User not found');

        foundreceiver = new User({ firstName: 'Unkown' });
      }
      return foundreceiver;

    } else {
      // console.log('Conversation payload not defined');
      return { firstName: 'Unknown' };
    }


  }

  ngOnDestroy(): void {
    this.sub_emitter.unsubscribe();
    if (this.subConv.length > 0) {
      this.subConv.forEach(element => {
        element.unsubscribe();
      });
    }
  }

  addGroup() {
    this.showAddGroup.emit(true);

  }
}
