import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { AuthService } from '../../service/auth-service/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { User } from '../../models';
const { version: appVersion, appStade: stade } = require('../../../../package.json');
import * as $ from 'jquery';
import { ORGTYPE } from '../../models/enum';
import { ROLES } from '../../models/enum/roles';
@Component({
    selector: 'app-interface-sidebar',
    templateUrl: './interface-sidebar.component.html',
    styleUrls: ['./interface-sidebar.component.scss']
})
export class InterfaceSidebarComponent implements AfterViewInit {

    currentUser: any = {};
    menucontentisCollapsed = false;
    public appVersion;
    public appStade;
    hospital = ORGTYPE.HOSPITAL;
    university = ORGTYPE.UNIVERSITY;
    others = ORGTYPE.OTHERS;
    admin = ROLES.ADMINISTRATOR;
    manager1 = ROLES.MANAGERONE;
    manager2 = ROLES.MANAGERTWO;
    userRole = ROLES.USER;

    constructor(private authService: AuthService, private userProvider: UsersProvider,
        private _notificationsService: NotificationsService) {
        this.appVersion = appVersion;
        this.appStade = stade;
        if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {

            this.userProvider.getCurrentUser().then((user: User) => {

                this.currentUser = user;
                localStorage.setItem('CurrentUser', JSON.stringify(user));
                this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

                // this.loading = false;
            });
        } else {
            this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

        }
    }
    public element1isCollapsed = true;
    public element2isCollapsed = true;
    public element3isCollapsed = true;

    public collapsed(event: any): void {
        if (this.menucontentisCollapsed) {
            if ($(document).width() > 767) {
                $('.content.admin').css('margin-left', '50px');
                $('header').css('margin-left', '50px');
                $('.nav-side-menu').css('width', '50px');
                $('.brand a').css('display', 'none');
                $('footer').css('margin-left', '50px');
                $('.menu-content').css('display', 'block');
                // $('.menu-content').css('height','');
                $('.menu-content li a').css('padding', '5px');
                $('.hiddable').css('display', 'none');
            } else {
                $('.content.admin').css('margin-left', '0px');
                $('.nav-side-menu').css('width', '100%');
                $('.menu-content').css('display', 'none');
                $('.brand a').css('display', 'block');
            }
        } else {
            if ($(document).width() > 767) {
                $('.hiddable').css('display', '');
                $('.menu-content li a').css('padding', '');
                $('footer').css('margin-left', '300px');
                $('.nav-side-menu').css('width', '300px');
                $('.content.admin').css('margin-left', '300px');
                $('.brand a').css('display', 'block');
            } else {
                $('.menu-content').css('display', 'block');
                $('.menu-content').css('heigth', 'auto');
            }
        }
    }

    public expanded(event: any): void {
        console.log(event);
    }
    ngAfterViewInit(): void {
        $(document).ready(function () {
            $(window).on('resize', function () {
                // console.log($( document ).width());
                if ($(document).width() <= 767) {
                    $('.menu-content').css('display', 'block');
                    $('.hiddable').css('display', '');
                    $('.nav-side-menu').css('width', '100%');
                    $('.brand a').css('display', 'block');
                    $('.content.admin').css('margin-left', '0px');
                } else {
                    $('.nav-side-menu').css('width', '300px');
                    $('.content.admin').css('margin-left', '300px');
                }
            });
        });
    }
}
