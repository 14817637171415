import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { AuthService } from '../service/auth-service/auth.service';
import { OrganizationProviderService } from '../service/providers/organization-provider.service';
import { OrgType } from '../models/enum';
const { version: appVersion, stade: appStade } = require('../../../package.json');

@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss']
})
export class InterfaceComponent implements OnInit {

  public appVersion;
  public appStade;
  constructor(@Inject(DOCUMENT) private document, private orgprovider: OrganizationProviderService) {
    this.appVersion = appVersion;
    this.appStade = appStade;
    orgprovider.getAllOrgTypes(true).then((results: OrgType[]) => {

      console.log('ORG TYPES', results);
    }).catch((err) => {
      console.error(err);
    });
  }

  ngOnInit() {
    this.document.getElementById('theme')
      .setAttribute('href', './assets/css/admin.css');
    this.document.getElementById('subtheme')
      .setAttribute('href', '');
    $('.menu-toggle').click(function (e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
      $('#wrapper').toggleClass('not-toggled');
    });
  }
}
