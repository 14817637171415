import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth-service/auth.service';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-interface-navigation',
  templateUrl: './interface-navigation.component.html',
  styleUrls: ['./interface-navigation.component.scss']
})
export class InterfaceNavigationComponent implements OnInit {
  loading = false;
  currentUser: any = {};
  isCurrentUserPremium = false;
  CurrentUserEmail: string = null;
  isOnline = false;
  en = true;

  constructor(private authService: AuthService,
    private usersProvider: UsersProvider, private translate: TranslateService) {
    try {
      this.en = JSON.parse(localStorage.getItem('en'));
    } catch (error) {
      this.en = JSON.parse(localStorage.getItem('en'));

    }
    if (this.en === null) {
      this.en = true;
      localStorage.setItem('en', JSON.stringify(this.en));
    }
    this.isOnline = navigator.onLine;
    const comp = this;
    window.addEventListener('offline', function () {
      comp.isOnline = false;
    });
    window.addEventListener('online', function () {
      comp.isOnline = true;
    });
    if (JSON.parse(localStorage.getItem('CurrentUser')) === null) {
      this.loading = true;
      this.usersProvider.getCurrentUser().then(User => {
        this.currentUser = User;
        this.loading = false;
        localStorage.setItem('CurrentUser', JSON.stringify(User));
        this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

      });
    } else {
      this.loading = false;
      this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    }
  }
  toggleLangage() {
    this.en = !this.en;
    localStorage.setItem('en', JSON.stringify(this.en));

    this.translate.use(this.en ? 'en' : 'fr');
    location.reload();
  }
  logOut() {
    this.authService.logout();
    window.location.reload();
  }
  ngOnInit(): void { }

}
