import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../service/auth-service/auth.service';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { FormUser, User } from '../../models';
import { Observable } from '@firebase/util';
import * as firebase from 'firebase/app';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  messages: string[] = [];
  loading = false;
  public options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true
  };
  constructor(@Inject(DOCUMENT) private document,
    private authService: AuthService,
    private usersProvider: UsersProvider,
    private router: Router,
    private _notificationsService: NotificationsService,
    private translateservice: TranslateService
  ) {
    this.authService.getCurrentFirebaseUser().then(user => {
      console.log(user);
      if (user) {
        this.loading = false;
        this.router.navigate(['/interface']);
        return;
      } else {
        this.loading = false;
        return;
      }
    });
  }

  user = {} as FormUser;
  userAuth: Observable<firebase.User>;
  Register() {
    this.messages = [];
    // console.log(this.user);
    if (this.user.email === '' || this.user.password === '') {
      this.messages.push('Email or password shouldn\'t be empty !');
    }
    if ((this.user.firstName === undefined || this.user.lastName === undefined) ||
      (this.user.firstName === '' || this.user.lastName === '')) {
      this.messages.push('\n First Name and Last Name shouldn\'t be empty !');
    }
    if (this.messages.length !== 0) { return false; }
    this.authService.signup(this.user.email, this.user.password).then(resolve => {
      // console.log(resp);
      const user: User = new User({
        id: resolve.user['uid'] + '',
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: resolve.user['email'],
      });
      this.usersProvider.AddUser(user).then(res => {
        this.loading = false;
        if (!resolve.emailVerified) {
          this.authService.sendVerification().then(success => {
            console.log(success);
            this.translateservice.get('notifications.user.login.verifyemail').subscribe(values => {
              this._notificationsService.success(values);
            });
            this.router.navigate(['/home']);
          }).catch(err => {
            console.log(err);
            this.translateservice.get('notifications.user.login.othererror').subscribe(values => {
              this._notificationsService.error(values);
            });
          });
        } else {
          this.router.navigate(['/admin']); // redirect after registration if the email is verified
        }
      }).catch(err => { console.log(err); });
    }, reject => {
      console.log(reject);

      this.loading = false;
      this.translateservice.get('notifications.user.login.othererror').subscribe(values => {

        this._notificationsService.error(values);
      });
    }
    );
    return true;
  }
  ngOnInit() {
    this.document.getElementById('theme')
      .setAttribute('href', './assets/css/home.css');
    this.document.getElementById('subtheme')
      .setAttribute('href', './assets/css/login.css');
  }
}
