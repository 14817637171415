import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VqEmitterService {

  private Item_source = new BehaviorSubject<any>(null);

  Item$ = this.Item_source.asObservable();

  emitValue(value) {
    this.Item_source.next(value);
  }

  constructor() { }
}
