import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class ImageUploaderService {
  constructor() { }
  uploadImage(imageData, folder, fileName) {
    return new Promise(resolve => {
      const storageRef = firebase.storage().ref(`${folder}/${fileName}`);
      storageRef.putString(imageData, 'base64').then(success => {
        resolve(success);
      }).catch(err => { });
    });
  }
  downloadImage(folder, fileName) {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref(`${folder}/${fileName}`);
      // console.log(`LOADING FILE ... ${folder}/${fileName}`);
      storageRef
        .getDownloadURL()
        .then((url) => {
          // console.log('imageProv', url);
          resolve(url);
        })
        .catch(function(error) {
          // console.log('The download error', error);
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              reject({ success: false, message: 'File doesn\'t exist' });

              break;

            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              reject({ success: false, message: 'Unauthorized access' });

              break;

            case 'storage/canceled':
              // User canceled the upload
              reject({
                success: false,
                message: 'Download canceled by the user'
              });

              break;

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              reject({ success: false, message: 'Unknown error occured' });

              break;
            default:
              reject({
                success: false,
                message: 'Unknown error occured: ' + error.code
              });
          }
        });
    });
  }
}
