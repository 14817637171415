import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { UsersProvider } from '../providers/users-provider.service';
import { User } from '../../models';
import * as uuid from 'uuid/v4';
import { DataSnapshot } from 'angularfire2/database/interfaces';
import { isFunction } from 'util';
import { VQEventEmitterService } from '../vq-event-emitter/vqevent-emitter.service';
import { Subscription, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RealtimeDatabaseService {
  orgId;
  private orgRefName = `organizations`;
  private convRefName = `conversations`;
  private messagesRefName = `messages`;
  private groupsRefName = `groups`;
  private sub_emitter: Subscription;

  currentUserId;
  constructor(private angularDatabase: AngularFireDatabase,
    private userProvider: UsersProvider,
    private eventEmitter: VQEventEmitterService) {
    this.userProvider.getCurrentUser().then((user: User) => {
      if (user && user.organizationId) {
        this.orgId = user.organizationId;
        this.currentUserId = user.id;
      }
    }).catch(err => {
      // Do nothing ! user not authenticated
    });
  }
  /**
   *
   * @param userId
   */
  getCurrentUserConversations(userId = null): Promise<AngularFireList<any>> {
    return new Promise((resolve, reject) => {
      if (userId) { this.currentUserId = userId; }
      if (this.currentUserId) {
        const ref = `/${this.orgRefName}/${this.orgId}/${this.convRefName}/`;
        resolve(this.angularDatabase.list(ref, reference => reference.orderByChild(`/${this.currentUserId}`).equalTo(true)));
      } else {
        reject({ message: 'User not connected' });
      }
    });

  }
  getCurrentUserGroups(userId = null): Promise<AngularFireList<any>> {
    return new Promise((resolve, reject) => {
      if (userId) { this.currentUserId = userId; }
      if (this.currentUserId) {
        const ref = `/${this.orgRefName}/${this.orgId}/${this.groupsRefName}/`;
        resolve(this.angularDatabase.list(ref, reference => reference.orderByChild(`/${this.currentUserId}`).equalTo(true)));
      } else {
        reject({ message: 'User not connected' });
      }
    });

  }
  /**
   *
   * @param receiverId
   * @param userId
   */
  getConversation(): Promise<any> {
    return new Promise((resolve, reject) => {
      const ref = `/${this.orgRefName}/${this.orgId}/${this.convRefName}`;
      resolve(this.angularDatabase.database.ref(ref).orderByChild(`/${this.currentUserId}`).equalTo(true));
      // resolve(this.angularDatabase.list(ref, reference => reference.orderByChild(`/${this.currentUserId}`).equalTo(true)));
    });
  }
  getConversationByKey(key) {
    return new Promise(resolve => {
      // console.log('getting conv with key', key);
      const ref = `/${this.orgRefName}/${this.orgId}/${this.convRefName}/${key}`;
      // console.log('Ref path', ref);

      this.angularDatabase.database.ref(ref).once('value', (datasnap) => {
        if ((datasnap !== null && datasnap !== undefined) &&
          (datasnap.val() !== null && datasnap.val() !== undefined)) {
          // console.log('Conversation not null !! ', datasnap);
          resolve(datasnap);
        } else {
          resolve(datasnap);
        }
      });
    });

  }
  /**
   * get a group by key
   * @param key the key of the group
   */
  getGroupByKey(key) {
    return new Promise(resolve => {
      const ref = `/${this.orgRefName}/${this.orgId}/${this.groupsRefName}/${key}`;
      this.angularDatabase.database.ref(ref).once('value', (datasnap) => {
        if ((datasnap !== null && datasnap !== undefined) &&
          (datasnap.val() !== null && datasnap.val() !== undefined)) {
          resolve(datasnap);
        } else {
          resolve(datasnap);
        }
      });
    });
  }
  getMessagesByKey(key: string): AngularFireList<any> {
    const ref = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${key}`;
    this.angularDatabase.database.ref(ref).on('child_added', (snapshot) => {
      // send an event to scroll down
      // console.log('Scroll down to ', snapshot);
      this.eventEmitter.emitValue({ event: 'scroll-down', data: snapshot });

    });
    return this.angularDatabase.list(ref);
  }
  /**
   * delete conversation and its messages
   * @param key key of conversation
   */
  deleteConversation(key: string) {
    let ref = `/${this.orgRefName}/${this.orgId}/${this.convRefName}/${key}`;
    this.angularDatabase.database.ref(ref).remove();
    ref = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${key}`;
    this.angularDatabase.database.ref(ref).remove();
  }
  /**
   * delete group and its messages
   * @param key key of group
   */
  deleteGroup(key: string) {
    let ref = `/${this.orgRefName}/${this.orgId}/${this.groupsRefName}/${key}`;
    this.angularDatabase.database.ref(ref).remove();
    ref = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${key}`;
    this.angularDatabase.database.ref(ref).remove();

  }
  /**
   *
   * @param receiverId
   * @param senderId
   * @param message
   */
  createConv(receiverId, senderId, message = null): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.orgId && receiverId) {
        const conversation = {};
        conversation[senderId] = true;
        conversation[receiverId] = true;
        // const key = uuid();
        // ref for converstions
        let ref = `/${this.orgRefName}/${this.orgId}/${this.convRefName}`;
        // const promisses = [];
        const key = this.angularDatabase.list(ref).push(conversation).key;
        ref = `/${this.orgRefName}/${this.orgId}/${this.convRefName}/${key}`;
        if (message !== null && message !== undefined) {
          const pushedMessage = {
            text: message,
            seen: false,
            timestamp: Date.now(),
            sender: senderId
          };
          // ref for messages
          ref = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${key}`;
          this.angularDatabase.list(`${ref}`).push(pushedMessage);
        }
        resolve({ key: key, messages: this.getMessagesByKey(key) });
      } else {
        if (!receiverId) {
          reject({ message: 'No receiver selected !' });

        }
        if (!this.orgId) {
          reject({ message: 'You do not have an organization !' });

        }
      }
    });
  }
  /**
   *
   * @param senderId
   * @param receiverId
   * @param message
   */
  createConversation(senderId, receiverId, message = null): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getConversation().then(ref => {
        console.log('Found list', ref);
        ref.once('value', (datasnap: DataSnapshot) => {
          if (datasnap && isFunction(datasnap.val) && datasnap.val()) {
            const lastKey = Object.keys(datasnap.val()).sort().reverse()[0];
            for (const key in datasnap.val()) {
              if (datasnap.val().hasOwnProperty(key)) {
                console.log(datasnap.val()[key]);
                const value = datasnap.val()[key];
                // sender and receiver exists
                if (value[senderId] && value[receiverId]) {
                  resolve({ key: key, messages: this.getMessagesByKey(key) });
                  break;
                } else {
                  // console.log('Not the current conversation !');
                }
                if (key === lastKey && (value[receiverId] === false || value[receiverId] === undefined)) {

                  this.createConv(receiverId, senderId, message)
                    .then((success) => { resolve(success); }).catch(error => {
                      console.log('Error while creating conversation', error);
                    });
                } else {

                  // console.log('Not the last key !');
                }
              }
            }
          } else {
            console.log('ELSE statement !');
            this.createConv(receiverId, senderId, message).then((success) => { resolve(success); }).catch(err => {
              console.log('Error while creating conversation', err);
            });
          }
          // console.log('Datasnapshot', datasnap.val());
        });
        //
      }).catch(err => reject(err));
    });



  }
  /**
   *
   * @param creatorId
   * @param usersIds
   * @param initialMessage
   */
  createGroup(creatorId, usersIds: string[], groupName = 'My group', key = null, initialMessage = null) {
    return new Promise((resolve, reject) => {
      if (this.orgId && usersIds.length > 0) {
        const group = {
          creator: creatorId,
          name: groupName,
          seen: {}
        };
        // make sure the creator is in the users by default
        if (!usersIds.find(u => u === creatorId)) {
          group[creatorId] = true;
          group.seen[creatorId] = false;

        }
        usersIds.forEach(id => {
          group[id] = true;
          group.seen[id] = false;
        });
        console.log('Group to create', group);
        let ref = `/${this.orgRefName}/${this.orgId}/${this.groupsRefName}`;
        // const promisses = [];
        if (key) {
          ref = `/${this.orgRefName}/${this.orgId}/${this.groupsRefName}/${key}`;

          this.angularDatabase.database.ref(ref).set(group, (err: Error) => {
            if (err) {
              reject(err);
            }
          }).then((successupdate) => {
            if (initialMessage) {
              const pushedMessage = {
                text: initialMessage,
                // seen: false,
                timestamp: Date.now(),
                sender: creatorId
              };
              // ref = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${key}`;
              this.angularDatabase.list(ref).push(pushedMessage);
            }
            resolve({ key: key });
          });
        } else {
          key = this.angularDatabase.list(ref).push(group).key;
          if (initialMessage) {
            const pushedMessage = {
              text: initialMessage,
              // seen: false,
              timestamp: Date.now(),
              sender: creatorId
            };
            ref = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${key}`;
            this.angularDatabase.list(ref).push(pushedMessage);
          }
          resolve({ key: key });
        }
      } else {
        if (usersIds.length === 0) {
          reject({ message: 'No users selected!' });

        } else if (!this.orgId) {
          reject({ message: 'You do not have an organization !' });

        }
      }
    });
  }
  /**
   * update
   * @param convId conversation id
   */
  seenConv(convId) {
    const ref = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${convId}`;
    const messages = this.angularDatabase.list(ref).snapshotChanges();
    return messages.subscribe(values => {
      const reversed = values.reverse();
      const fitem = reversed.find(item => item.payload.val()['sender'] !== this.currentUserId
        && item.payload.val()['seen'] === false);
      // console.log('lastfound not seen message', fitem);
      if (fitem !== undefined) {
        let reference;
        values.forEach(v => {
          if (v.payload.val()['sender'] !== this.currentUserId) {
            reference = `${ref}/${v.key}/seen`;
            this.angularDatabase.database.ref(reference).set(true, (err) => {
              if (err) {
                console.error('ERROR WHILE UPDATING SEEN ! ', err);
              }
            });
          }

        });
      }
      // console.log('VALUES ', values);
    });

  }
  /**
   *
   * @param groupId
   */
  seenGroup(groupId) {
    const ref = `/${this.orgRefName}/${this.orgId}/${this.groupsRefName}/${groupId}`;
    const group = this.angularDatabase.object(ref).snapshotChanges();
    return group.subscribe(value => {
      if (value && value.payload && value.payload.val()) {
        if (value.payload.val()['seen'][this.currentUserId] === false) {
          // console.log('GROUP TO SET TO SEEN', value.payload.val());

          this.angularDatabase.database.ref(`${ref}/seen/${this.currentUserId}`).set(true, (err) => {
            if (err) {
              console.error('ERROR WHILE UPDATING SEEN ! ', err);
            }
          });
        }
      }

    });
  }


  /**
   *Listen on current user
   */
  listenOnMyConversations(): Observable<boolean> {
    return new Observable((observer) => {

      const refConversations = `/${this.orgRefName}/${this.orgId}/${this.convRefName}/`;

      const conversations = this.angularDatabase.list(refConversations, reference =>
        reference.orderByChild(`/${this.currentUserId}`).equalTo(true)
      ).snapshotChanges();

      conversations.subscribe((conversationssnap: any[]) => {

        conversationssnap.forEach(conv => {
          const refMessages = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${conv.key}`;

          const messages = this.angularDatabase.list(refMessages).snapshotChanges();

          messages.subscribe((messagessnap) => {
            const reversed = messagessnap.reverse();
            const fitem = reversed.find(item => item.payload.val()['sender'] !== this.currentUserId
              && item.payload.val()['seen'] === false);
            if (fitem !== undefined) {
              observer.next(true);

            } else {
              observer.next(false);
            }
          });
        });
      });

    });
  }

  listenOnMyGroups(): Observable<boolean> {
    return new Observable((observer) => {
      const refConversations = `/${this.orgRefName}/${this.orgId}/${this.groupsRefName}/`;

      const groups = this.angularDatabase.list(refConversations, reference =>
        reference.orderByChild(`/${this.currentUserId}`).equalTo(true)
      ).snapshotChanges();

      groups.subscribe((groupssnap: any[]) => {
        // console.log('GROUP SNAP ', groupssnap);
        groupssnap.forEach(group => {
          // console.log('SEEN group', group.payload.val());
          if (group.payload.val()['seen'][this.currentUserId] === false) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      });
    });
  }
  /**
   *
   * @param group
   */
  makeSeenGroup(group: DataSnapshot) {
    return new Promise((resolve) => {
      const ref = `/${this.orgRefName}/${this.orgId}/${this.groupsRefName}/${group.key}`;
      console.log('REF', ref, 'GROUP', group.val());
      const promisses = [];
      for (const key in group.val()['seen']) {
        if (key !== this.currentUserId) {

          const reference = `${ref}/seen/${key}`;
          // console.log('REference', reference);
          promisses.push(this.angularDatabase.database.ref(reference).set(false, (err) => {
            if (err) {
              console.error('ERROR WHILE UPDATING SEEN ! ', err);
            }
          }));
        } else {

          const reference = `${ref}/seen/${key}`;
          // console.log('REference', reference);
          promisses.push(this.angularDatabase.database.ref(reference).set(true, (err) => {
            if (err) {
              console.error('ERROR WHILE UPDATING SEEN ! ', err);
            }
          }));
        }
      }
      Promise.all(promisses).then(() => {
        resolve('Updated');
      });
    });
  }

  /**
   * send Message to group or conversation
   * @param convGroupId
   * @param senderId
   * @param recieverId
   */
  sendMessage(convGroupId, senderId, message, isgroup: boolean = false) {
    this.userProvider.getCurrentUser().then((user: User) => {
      const ref = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${convGroupId}`;
      if (isgroup === true) {
        // group
        const pushedMessage = {
          text: message,
          // seen: false,
          timestamp: Date.now(),
          sender: senderId,
          isGroup: isgroup,
          name: {
            firstName: user.firstName,
            lastName: user.lastName,
          }
        };
        console.log('Message to push', pushedMessage, 'to path', ref);
        this.angularDatabase.list(ref).push(pushedMessage).then(() => {
          const sub = this.angularDatabase.object(`/${this.orgRefName}/${this.orgId}/${this.groupsRefName}/${convGroupId}`)
            .snapshotChanges().subscribe(value => {
              console.log('group to make unseen', value);
              this.makeSeenGroup(value.payload).then(() => {
                sub.unsubscribe();
              });
            });
          // sub.unsubscribe();
        });

      } else {
        const pushedMessage = {
          text: message,
          seen: false,
          timestamp: Date.now(),
          sender: senderId,
          isGroup: isgroup,
          name: {
            firstName: user.firstName,
            lastName: user.lastName,
          }
        };
        console.log('Message to push', pushedMessage, 'to path', ref);
        this.angularDatabase.list(ref).push(pushedMessage);
      }
    });
  }


  /**
   * Check conv notif
   */
  checkUsersNotification() {
    return new Observable((observer) => {
      const refConversations = `/${this.orgRefName}/${this.orgId}/${this.convRefName}/`;

      const conversations = this.angularDatabase.list(refConversations, reference =>
        reference.orderByChild(`/${this.currentUserId}`).equalTo(true)
      ).snapshotChanges();

      conversations.subscribe((conversationssnap) => {
        conversationssnap.forEach(conv => {
          const refMessages = `/${this.orgRefName}/${this.orgId}/${this.messagesRefName}/${conv.key}`;

          let otherUserId = null;

          for (const key in conv.payload.val()) {
            if (key && key !== `${this.currentUserId}`) {
              otherUserId = key;
            }
          }


          const messages = this.angularDatabase.list(refMessages).snapshotChanges();

          messages.subscribe((messagessnap) => {
            const reversedMessages = messagessnap.reverse();
            const lastMessage = reversedMessages.reverse().find(item => item.payload.val()['sender'] !== `${this.currentUserId}`
              && item.payload.val()['seen'] === false);
            if (lastMessage) {

              observer.next({ isNotif: true, key: conv.key });
            } else {
              observer.next({ isNotif: false, key: conv.key });
            }

          });
        });
      });
    });
  }
}
