import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InterfaceRoutingModule } from './interface-routing.module';
import { InterfaceComponent } from './interface.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { BadgeDesignComponent } from './badge-design/badge-design.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailValidationComponent } from './email-validation/email-validation.component';
import { ProfileComponent } from './profile/profile.component';
import { SupportComponent } from './support/support.component';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { UsersComponent } from './users/users.component';
import { VenuesComponent } from './venues/venues.component';
import { EventsComponent } from './events/events.component';
import { SharedModule } from '../shared/shared.module';
import { SimpleNotificationsModule, NotificationsService } from 'angular2-notifications';
import { SetUpOrganizationComponent } from './set-up-organization/set-up-organization.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { ModalServiceComponent } from './events/modal-service/modal-service.component';
import { EventModalComponent } from './events/event-modal/event-modal.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ModalModule, DatepickerModule, BsDatepickerModule, TimepickerModule, CarouselModule } from 'ngx-bootstrap';
import { NguiMapModule } from '@ngui/map';
import { SelectModule } from 'ng2-select';
import { VenueModalComponent } from './venues/venue-modal/venue-modal.component';
import { ModalServiceVenuesComponent } from './venues/modal-service-venues/modal-service-venues.component';
import { ManagerModalComponent } from './events/manager-modal/manager-modal.component';
import { ModalServiceToolboxComponent } from './toolbox/modal-service-toolbox/modal-service-toolbox.component';
import { ToolboxModalComponent } from './toolbox/toolbox-modal/toolbox-modal.component';
import { ChooseAvatarModalComponent } from './profile/choose-avatar-modal/choose-avatar-modal.component';
import { ModalServiceAvatarComponent } from './profile/modal-service-avatar/modal-service-avatar.component';
import { OrganizationUsersComponent } from './organization-users/organization-users.component';
import { ModalServiceOrgUsersComponent } from './organization-users/modal-service-org-users/modal-service-org-users.component';
import { ModalOrgUsersComponent } from './organization-users/modal-org-users/modal-org-users.component';
import { ReCaptchaModule } from 'angular2-recaptcha/angular2-recaptcha';


@NgModule({
  imports: [
    CommonModule,
    InterfaceRoutingModule, SharedModule.forRoot(), SimpleNotificationsModule.forRoot(), FormsModule, QRCodeModule,
    ModalModule.forRoot(), DatepickerModule.forRoot(), BsDatepickerModule.forRoot(), CarouselModule.forRoot(),
    ReactiveFormsModule, TimepickerModule.forRoot(),
    ReCaptchaModule,
    ChartsModule, NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyANvOlYyl8rwnUKitcoCsms8JpxvqZ2GIs' }),
    SelectModule
  ],
  entryComponents: [EventModalComponent, VenueModalComponent, ToolboxModalComponent, ChooseAvatarModalComponent, ModalOrgUsersComponent],
  declarations: [InterfaceComponent, AnalyticsComponent, BadgeDesignComponent,
    DashboardComponent, EmailValidationComponent, ProfileComponent, SupportComponent, SetUpOrganizationComponent,
    ToolboxComponent, UsersComponent, VenuesComponent, EventsComponent,
    ModalServiceComponent, EventModalComponent, VenueModalComponent,
    ModalServiceVenuesComponent, ManagerModalComponent, ModalServiceToolboxComponent, ToolboxModalComponent,
    ChooseAvatarModalComponent, ModalServiceAvatarComponent,
     OrganizationUsersComponent, ModalServiceOrgUsersComponent, ModalOrgUsersComponent],
  providers: [NotificationsService]
})
export class InterfaceModule { }
