
/**
 * Time stamp class
 */
export class TimeStamp {
    value: number;
    constructor(d: Date|number|TimeStamp) {
        this.value = this.getValue(d);
    }
    /**
     * to get the value from date or number
     * @param source Date or time stamp number
     * @version 0.0.2
     */
    private getValue(source: Date|number|TimeStamp): number {
        return source instanceof (Date) ? source.getTime() : source instanceof (TimeStamp) ? source.valueOf() : source;
    }
    /**
     * The value of time stamp
     */
    public valueOf(): number {
        return this.value;
    }
    public toDate(): Date {
        return new Date(this.value);
    }
    public toString(): string {
        return this.value.toString();
    }
}
