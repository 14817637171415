import { Component, OnInit, Inject } from '@angular/core';
import * as firebase from 'firebase/app';
import { AuthService } from '../../service/auth-service/auth.service';
import { UsersProvider } from '../../service/providers/users-provider.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormUser, User } from '../../models';
import { Observable } from '@firebase/util';
import { NotificationsService } from 'angular2-notifications';
import { take } from 'rxjs/operators';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  email = '';
  loading = false;
  public options = {
    position: ['top', 'right'],
    timeOut: 5000,
    lastOnBottom: true

  };
  constructor(@Inject(DOCUMENT) private document,
    private authService: AuthService,
    private usersProvider: UsersProvider,
    private router: Router,
    private _notificationsService: NotificationsService,
    private translateservice: TranslateService
  ) {
    this.loading = true;
    this.authService.getCurrentFirebaseUser().then(user => {
      console.log(user);
      if (user) {
        this.loading = false;
        this.router.navigate(['/interface']);
        return;
      } else {
        this.loading = false;
        return;
      }
    });
    // take(1).call(this.authService.user.subscribe(auth => {
    //   console.log('This is the user', auth);
    //   if (auth) {
    //     this.loading = false;
    //     this.router.navigate(['/interface']);
    //     return;
    //   } else {
    //     // console.log(auth);

    //     this.loading = false;
    //     return;
    //   }
    // }));
    //

  }

  user = {} as FormUser;

  // userAuth: Observable<firebase.User>;

  public login() {
    this.loading = true;
    let ob: Promise<any>;
    try {
      ob = this.authService.login(this.user.email, this.user.password);
    } catch (error) {
      console.log(error);
      this.translateservice.get('notifications.user.login.error').subscribe(values => {

        this._notificationsService.error(values);
      });
      return;
    }
    ob.then(resolve => {
      console.log(resolve);
      this.user.firstName = '';
      this.user.lastName = '';
      // console.log(resolve.user);
      this.translateservice.get('notifications.user.login.success').subscribe(values => {
        this._notificationsService.success(values);
      });
      const user: User = new User({
        id: resolve.user['uid'] + '',
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: resolve.user['email']
      });

      this.usersProvider.AddUser(user).then(res => {
        this.loading = false;
        this.router.navigate(['/interface']);
      }).catch(err => {
        console.error(err);
        // this.authService.logout();
      });
      // this.loading=false;
    }, reject => {
      console.log(reject);
      this.loading = false;
      this.translateservice.get('notifications.user.login.success').subscribe(values => {
        this._notificationsService.success(values);
      });
    });


  }

  loginFb() {
    this.loading = true;
    this.authService.signupwithFacebook().then(result => {
      console.log(' THIS IS THE RESULT ', result);
      if (result.user != null) {
        const displayNameArray = result.user['displayName'].split(' ');
        let rest = '';
        if (displayNameArray.length > 1) {
          rest = displayNameArray.slice(1, displayNameArray.length).join(' ');
        }
        const user: User = new User({
          id: result.user['uid'] + '',
          firstName: displayNameArray[0],
          lastName: rest,
          email: result.user['email']
        });
        // add the user
        // console.log('User to create ', user);
        this.usersProvider.AddUser(user).then(res => {
          this.loading = false;
          if (!result.user.emailVerified) {
            this.authService.sendVerification().then(success => {
              console.log(success);
              this.translateservice.get('notifications.user.login.verifyemail').subscribe(values => {
                this._notificationsService.success(values);
              });
              this.router.navigate(['/home']);
            }).catch(err => {
              console.error(err);
              this.translateservice.get('notifications.user.login.othererror').subscribe(values => {

                this._notificationsService.error(values);
              });
            });
          } else {
            this.router.navigate(['/interface']);
          }
        }).catch(err => {
          console.error(err);
          // this.authService.logout();
        });

      }
    });
  }
  loginGoogle() {
    console.log('loading Google pop up ...');
    this.loading = true;
    this.authService.signupwithGoogle().then(result => {
      // console.log('THIS IS THE RESULT ', result.user);
      if (result.user != null) {
        const displayNameArray = result.user['displayName'].split(' ');
        let rest = '';
        if (displayNameArray.length > 1) {
          rest = displayNameArray.slice(1, displayNameArray.length).join(' ');
        }
        const user: User = new User({
          id: result.user['uid'] + '',
          firstName: displayNameArray[0],
          lastName: rest,
          email: result.user['email']
        });
        // add the user
        // console.log('User to create ', user);
        this.usersProvider.AddUser(user).then(res => {
          this.loading = false;
          this.router.navigate(['/interface']);
        }).catch(err => {
          console.error(err);
          // this.authService.logout();
        });

      }
    });
  }
  loginGitHub() {
    this.authService.signupwithGitHub();
  }
  loginTwitter() {
    this.authService.signupwithTwitter().then(result => {
      console.log('THIS IS THE RESULT ', result);
      if (result.user != null) {
        const displayNameArray = result.user['displayName'].split(' ');
        let rest = '';
        if (displayNameArray.length > 1) {
          rest = displayNameArray.slice(1, displayNameArray.length).join(' ');
        }
        const user: User = new User({
          id: result.user['uid'] + '',
          firstName: displayNameArray[0],
          lastName: rest,
          email: result.user['email']
        });
        // add the user
        // console.log('User to create ', user);
        this.usersProvider.AddUser(user).then(res => {
          this.loading = false;
          this.router.navigate(['/interface']);
        }).catch(err => {
          console.error(err);
          // this.authService.logout();
        });

      }
    });
  }
  forgotPassword() {
    if (this.email !== '') {
      this.loading = true;
      this.authService.resetPassword(this.email).then(success => {
        this.loading = false;
        this.translateservice.get('notifications.user.login.resetemailsent').subscribe(values => {
          this._notificationsService.success(values);
        });
      }).catch(err => {
        this.loading = false;
        console.log(err);
        this.translateservice.get('notifications.user.login.othererror').subscribe(values => {
          this._notificationsService.error(values);
        });
      });
    }
  }
  ngOnInit() {
    this.document.getElementById('theme')
      .setAttribute('href', './assets/css/home.css');
    this.document.getElementById('subtheme')
      .setAttribute('href', './assets/css/login.css');

  }
}
