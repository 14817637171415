import { BadgeDesign } from './index';

import { IAdmin, IManager, IQuota, IMapCoord, INormalQueue, IBadgeDesign, ISocialMedia, ITimeInterval } from './interfaces/index';

import { TimeStamp, Util } from './tools/index';
import { ORGTYPE } from './enum/index';


/**
 * VqEvent class represents an event
 * @version 2.0.2
 */
export class VQEvent {// we cannot use the name Event because it's a reserved name for angular so be carefull
  /**
   * Event' name
   * @type {string}
   */
  name?: string;
  /**
   * Event' description
   * @type {string}
   */
  description?: string;
  /**
   * Event' starting date by default it's now
   * @type {number}
   */
  startDate?: number = Date.now();

  /**
   * Event' ending date
   * @type {number}
   */
  endDate?: number;

  /**
   *event ending time each day
   * @type {number}
   */
  timeInterval?: ITimeInterval;

  /**
   * Logo of the event
   */
  logoUrl?: string;
  backgroundUrl?: string;
  /**
   * The event badge design
   * @type {BadgeDesign}
   */
  eventBadgeDesign?: IBadgeDesign;
  /**
   * List of admins
   */
  admins?: IAdmin[] = [];
  /**
   * List of managers
   */
  managers?: IManager[] = [];
  /**
   * virtual queue size calculated
   */
  vQSize?: number;
  /**
   * event id
   */
  id?: string;
  /**
   * the Kind it's Event by default
   */
  kind?: string;
  /**
   * Percentages of vip and vQ members in this event
   */
  quota?: IQuota;
  /**Map Coordinates */
  mapCoord?: IMapCoord;
  /** Cool down time prevents from adding more vqUsers */
  coolDownTime?: number;
  /**Normal Queue */
  normalQueue?: INormalQueue;
  /**Social Media list*/
  socialMedias?: ISocialMedia[];
  /**is this event a premium event */
  isPremium?: boolean;
  /** Calculated average waiting time*/
  currentAverageWaitingTime?: number;
  deleted?: boolean;
  /**Venue id */
  venueId?: string;
  /**
   * Type
   */
  type?: ORGTYPE;
  /** session Duration */
  sessionTime?: number;
  /**Users per session */
  usersPerSession?: number;
  /**
   * Hash for the qRcode
   */
  hash?: string;
  /**uuid of the rendred svg file stored n firebase storage*/
  adminBadge?: string;
  /**
   * the id of the organization
   */
  organizationId?: string;

  responsible?: string;

  /**
   * {venueid,name:string,description:string,startDate:TimeStamp}
   * @param {string,string,TimeStamp} obj {venueId,name:string,startDate:TimeStamp}
   */
  constructor(obj: any = {}) {
    this.id = obj.id ? obj.id : undefined;
    this.venueId = obj.venueId ? obj.venueId : '';
    this.name = obj.name ? obj.name : '';
    this.type = obj.type ? obj.type : undefined;
    this.description = obj.description ? obj.description : '';
    this.startDate = obj.startDate ? obj.startDate : undefined;
    this.kind = 'Event';
    this.quota = obj.quota ? { vips: obj.vips ? obj.vips : 0, vQUsers: obj.vQUsers ? obj.vQUsers : 0 } : { vips: 0, vQUsers: 0 };
    this.coolDownTime = 0;
    this.isPremium = false;
    this.vQSize = 0;
    this.eventBadgeDesign = { id: '' };
    this.normalQueue = { size: 0, waitTime: 0 };
    this.mapCoord = {
      bottom: 0, height: 0, left: 0, right: 0, top: 0, units: 'px', width: 0
    };
    this.hash = obj.hash ? obj.hash : Util.generateCode();
    this.organizationId = obj.organizationId;
    this.timeInterval = obj.timeInterval ? obj.timeInterval : {};
    // making the id and kind not enumerable so we don't have unnecessary duplications
    Object.defineProperties(this, {
      id: { enumerable: false },
      kind: { enumerable: false }
    });
  }

  /**Create an event from JSON object */
  public static fromJSONObject({
    venueId, name, description, startDate,
    admins, managers, coolDownTime, currentAverageWaitingTime,
    normalQueue, vQSize, endDate, isPremium, sessionTime, mapCoord,
    socialMedias, usersPerSession, logoUrl, eventBadgeDesign, quota, id, kind
  }
  ): VQEvent {
    const event = new VQEvent({ venueId, name, description, startDate });
    try {
      event.id = id;
      if (admins !== undefined) {
        event.admins = <IAdmin[]>admins;
      }
      if (managers !== undefined) {
        event.managers = <IManager[]>managers;
      }
      event.coolDownTime = <number>coolDownTime;
      event.currentAverageWaitingTime = <number>currentAverageWaitingTime;
      event.normalQueue = <INormalQueue>normalQueue;
      event.vQSize = <number>vQSize;
      event.endDate = endDate;
      event.isPremium = <boolean>isPremium;
      event.sessionTime = <number>sessionTime;
      event.mapCoord = <IMapCoord>mapCoord;
      event.socialMedias = <ISocialMedia[]>socialMedias;
      event.usersPerSession = <number>usersPerSession;
      event.logoUrl = <string>logoUrl;
      event.eventBadgeDesign = <IBadgeDesign>eventBadgeDesign;
      event.quota = <IQuota>quota;
    } catch (error) {
      return null;
    }
    return event;
  }
}
