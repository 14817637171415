import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { DesignType } from '../../../models/enum';
import { BsModalRef } from 'ngx-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { BadgesProviderService } from '../../../service/providers/badges-provider.service';
import { BadgeDesign, User } from '../../../models';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-toolbox-modal',
  templateUrl: './toolbox-modal.component.html',
  styleUrls: ['./toolbox-modal.component.scss']
})
export class ToolboxModalComponent implements OnInit, AfterViewInit {
  currentUser: User;
  selectedType: DesignType = DesignType.BADGE;
  badgeDesign: BadgeDesign = new BadgeDesign();
  designTypes: any[] = [];
  edit = false;
  add = false;
  loading: false;
  defaultTemplates: any[] = [];
  unfilteredDefaultTemplates: any[] = [];
  pickedDefault;
  addClicked: EventEmitter<any> = new EventEmitter();
  public options = {
    position: ['top', 'right'],
    timeOut: 5000
  };
  constructor(private _notificationsService: NotificationsService,
    private badgeprovider: BadgesProviderService,
    private translateservice: TranslateService
  ) {
    // console.log('Modal',this.badgeDesign);
    this.designTypes.push({ name: 'Badge design', value: DesignType.BADGE });
    this.designTypes.push({ name: 'Ticket design', value: DesignType.TICKET });
  }

  selectType(value: DesignType) {
    // console.log(value);
    this.selectedType = <DesignType>value;
    this.badgeDesign.type = this.selectedType;
  }

  ngAfterViewInit(): void {

  }

  goToStep(step, event$ = null) {
    console.log(event$);
    const steps = ['details', 'admindesign', 'userdesign'];
    const anchors = ['detailsAnchor', 'admindesignAnchor', 'userdesignAnchor'];
    $('#' + step).removeClass('hidden');
    $('.modal-lg').removeClass('bigger');

    if (step !== 'details') {
      $('.modal-lg').addClass('bigger');
    }

    if (event$ !== null) {

      $('#' + event$.target.id).addClass('selected');
      $('#' + event$.target.id).parent().addClass('current');
      anchors.forEach(anchor => {
        if (anchor !== event$.target.id) {
          $('#' + anchor).removeClass('selected');
          $('#' + anchor).parent().removeClass('current');
        }

      });
    } else {

      $('#' + step + 'Anchor').addClass('selected');
      $('#' + step + 'Anchor').parent().addClass('current');
      anchors.forEach(anchor => {
        if (anchor !== (step + 'Anchor')) {
          $('#' + anchor).removeClass('selected');
          $('#' + anchor).parent().removeClass('current');
        }

      });
    }

    steps.forEach(arraystep => {

      if (arraystep !== step) {
        $('#' + arraystep).addClass('hidden');
      }
    });

  }

  save(edit = false) {
    this.badgeDesign.adminsvg = localStorage.getItem('admintoolbox.svg');
    this.badgeDesign.adminxml = localStorage.getItem('admintoolbox.xml');
    this.badgeDesign.usersvg = localStorage.getItem('usertoolbox.svg');
    this.badgeDesign.userxml = localStorage.getItem('usertoolbox.xml');
    this.badgeDesign.userid = this.currentUser.id;
    this.badgeDesign.organizationId = this.currentUser.organizationId;
    console.log(this.badgeDesign);
    if (edit) {
      this.badgeprovider.updateBadgeDesign(this.badgeDesign).then((success: any) => {
        this.translateservice.get('notifications.design.update.success').subscribe(value => {
          this._notificationsService.success(value);
        });

        this.addClicked.emit({ badge: this.badgeDesign });
      }).catch(error => {
        console.error(error);
        this.translateservice.get('notifications.design.update.error').subscribe(value => {
          this._notificationsService.error(value);
        });
      });
    }
    if (!edit) {
      this.badgeprovider.addBadgeDesign(this.badgeDesign).then((success: any) => {
        this.translateservice.get('notifications.design.add.success').subscribe(value => {
          this._notificationsService.success(value);
        });
        this.addClicked.emit({ badge: this.badgeDesign });

      }).catch(error => {
        console.error(error);
        this.translateservice.get('notifications.design.add.error').subscribe(value => {
          this._notificationsService.error(value);
        });

      });
    }

  }

  ngOnInit(): void {
    this.badgeprovider.getBadgesTemplates().then((badges: any[]) => {
      this.unfilteredDefaultTemplates = badges;
      this.defaultTemplates = badges.filter((item: BadgeDesign) => {
        return item.type === 0;
      });
    });
  }

  setTemaplate(event) {
    const badge = this.defaultTemplates.find((item: any) => {
      return item.id === this.pickedDefault;
    });
    if (badge) {
      const tempBadgedesign = new BadgeDesign();
      tempBadgedesign.name = this.badgeDesign.name;
      tempBadgedesign.type = this.badgeDesign.type;
      tempBadgedesign.isTemplate = this.badgeDesign.isTemplate;
      tempBadgedesign.usersvg = badge.usersvg;
      tempBadgedesign.adminsvg = badge.adminsvg;
      tempBadgedesign.userxml = badge.userxml;
      tempBadgedesign.adminxml = badge.adminxml;
      this.badgeDesign = tempBadgedesign;

    }
  }

  filterTemplate() {
    console.log(this.badgeDesign.type);
    console.log(this.unfilteredDefaultTemplates);
    this.defaultTemplates = this.unfilteredDefaultTemplates.filter((item: BadgeDesign) => {
      console.log(this.badgeDesign.type);
      return item.type === Number(this.badgeDesign.type);
    });
    console.log(this.defaultTemplates);

  }
}
