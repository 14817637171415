import { Component, EventEmitter, OnInit } from '@angular/core';
import { User } from '../../../models';
import { OrganizationProviderService } from '../../../service/providers/organization-provider.service';
import { NotificationsService } from 'angular2-notifications';
import { UsersProvider } from '../../../service/providers/users-provider.service';

import { ROLES } from '../../../models/enum/roles';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-modal-org-users',
  templateUrl: './modal-org-users.component.html',
  styleUrls: ['./modal-org-users.component.scss']
})
export class ModalOrgUsersComponent implements OnInit {


  addClicked: EventEmitter<any> = new EventEmitter();
  user: User = new User();
  oldEmail: string;
  add = false;
  edit = false;
  currentUser = null;
  loading: boolean;
  keyword: string;
  users: User[];
  selectedUsers: User[];
  selectedRole: string;
  roles = [];

  constructor(private organizationProvider: OrganizationProviderService,
    private _notificationsService: NotificationsService,
    private _usersProvider: UsersProvider,
    private translateservice: TranslateService
  ) {
    this.selectedUsers = [];
    this._usersProvider.getCurrentUser().then((user: User) => {
      if (Number(user.role) === ROLES.ADMINISTRATOR) {
        this.roles.push(ROLES.ADMINISTRATOR);
        this.roles.push(ROLES.MANAGERONE);
      }

      this.roles.push(ROLES.MANAGERTWO);
      this.roles.push(ROLES.USER);
    }).catch(err => {
      console.log(err);
    });
  }

  save() {
    this.loading = true;
    if (this.add) {
      this.organizationProvider.addOrganizationUser(this.user).then((success: any) => {
        this.user.id = success.id;
        this.addClicked.emit(this.user);
        this.loading = false;
        this.translateservice.get('notifications.user.add.success').subscribe(value => {

          this._notificationsService.success('Add User', 'User added successfully');
        });
      }).catch(err => {
        console.log('add user', err);
        this.loading = false;
        this.addClicked.emit({ failed: true });
        this.translateservice.get('notifications.user.add.error').subscribe(value => {
          this._notificationsService.success(value);
        });
      });
    } else {
      let isEmailChanged = false;
      if (this.oldEmail !== this.user.email) {
        isEmailChanged = true;
      }
      this.organizationProvider.updateOrganizationUser(this.user, isEmailChanged).then((success: any) => {
        this.addClicked.emit(this.user);
        this.loading = false;
        this.translateservice.get('notifications.user.update.success').subscribe(value => {
          this._notificationsService.success(value);
        });
      }).catch(err => {
        console.log('update user', err);
        this.loading = false;
        this.translateservice.get('notifications.user.update.error').subscribe(value => {
          this._notificationsService.error(value);

        });
      });
    }
  }

  goToStep(step, event$ = null) {

    const steps = ['create', 'select'];
    const anchors = ['createAnchor', 'selectAnchor'];
    $('#' + step).removeClass('hidden');
    if (event$ !== null) {

      $('#' + event$.srcElement.id).addClass('selected');
      $('#' + event$.srcElement.id).parent().addClass('current');
      anchors.forEach(anchor => {
        if (anchor !== event$.srcElement.id) {
          $('#' + anchor).removeClass('selected');
          $('#' + anchor).parent().removeClass('current');
        }

      });
    } else {

      $('#' + step + 'Anchor').addClass('selected');
      $('#' + step + 'Anchor').parent().addClass('current');
      anchors.forEach(anchor => {
        if (anchor !== (step + 'Anchor')) {
          $('#' + anchor).removeClass('selected');
          $('#' + anchor).parent().removeClass('current');
        }

      });
    }

    steps.forEach(arraystep => {

      if (arraystep !== step) {
        $('#' + arraystep).addClass('hidden');
      }
    });

  }

  search() {
    this.loading = true;
    this._usersProvider.searchUser(this.keyword).then((users: User[]) => {
      if (users && users.length > 0) {
        this.users = users;
      } else {
        this.users = [];
      }
      this.loading = false;
    }).catch(err => {
      console.log('search', err);
      this.loading = false;
      this.translateservice.get('notifications.organization.user.search.error').subscribe(value => {
        this._notificationsService.error(value);
      });
    });
  }

  assign() {
    this.loading = true;
    if (!this.selectedRole) {
      this.translateservice.get('notifications.user.update.rolerequired').subscribe(value => {
        this._notificationsService.error(value);
      });
    } else {
      if (this.selectedUsers.length > 0) {
        const role = parseInt(this.selectedRole, 10);
        this._usersProvider.assignUsers(this.selectedUsers, this.currentUser.organizationId, role).then((success) => {
          this.loading = false;
          this.addClicked.emit('refresh');
          this.translateservice.get('notifications.organization.user.assign.success').subscribe(value => {

            this._notificationsService.success(value);
          });
        }).catch(err => {
          this.loading = false;
          this.addClicked.emit('');
          console.error(err);
          this.translateservice.get('notifications.organization.user.assign.error').subscribe(value => {
            this._notificationsService.error(value);
          });
        });
      } else {
        this.loading = false;
        this.translateservice.get('notifications.organization.user.assign.nousers').subscribe(value => {
          this._notificationsService.error(value);
        });

      }
    }
  }

  ngOnInit() {
  }

  selectUser(user: User) {
    const index = this.selectedUsers.findIndex(item => item.id === user.id);
    if (index === -1) {
      this.selectedUsers.push(user);
      const indexUser = this.users.findIndex(item => item.id === user.id);
      this.users.splice(indexUser, 1);
    } else {
      this.selectedUsers.splice(index, 1);
      this.users.push(user);
    }
  }
}
