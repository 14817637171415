import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { VQEvent, Venue, BadgeDesign, User } from '../../../models';
import { EventModalComponent } from '../event-modal/event-modal.component';
import { ORGTYPE } from '../../../models/enum';
import { ROLES } from '../../../models/enum/roles';

@Component({
    selector: 'app-modal-service',
    templateUrl: './modal-service.component.html',
    styleUrls: ['./modal-service.component.scss']
})
export class ModalServiceComponent {
    page = 'Event';
    bsModalRef: BsModalRef;
    hospital = ORGTYPE.HOSPITAL;
    university = ORGTYPE.UNIVERSITY;
    others = ORGTYPE.OTHERS;
    admin = ROLES.ADMINISTRATOR;
    manager1 = ROLES.MANAGERONE;
    manager2 = ROLES.MANAGERTWO;
    userRole = ROLES.USER;
    @Input() add = false;
    @Input() edit = false;
    @Input() show = false;
    @Input() details = false;
    @Input() vqEvent = new VQEvent();
    @Input() title = '';
    @Input() currentUser: User;
    @Input() dataStoreVenue = new Venue();
    @Input() badgeDesign = new BadgeDesign();
    @Input() venuesList: any[] = [];
    @Input() usersList: any[] = [];
    @Output() closed: EventEmitter<any> = new EventEmitter();
    @Output() refresh: EventEmitter<any> = new EventEmitter();

    constructor(private modalService: BsModalService) {
    }

    openModalWithComponent() {
        if (!this.show && !this.details) {
            this.bsModalRef = this.modalService.show(EventModalComponent, { class: 'modal-lg' });

        }
        if (this.show || this.details) {
            this.bsModalRef = this.modalService.show(EventModalComponent, { class: 'modal-md' });

        }
        /**Patch 1.4.2 */
        this.bsModalRef.content.closed.subscribe(($event) => {
            this.bsModalRef.hide();
            this.closed.emit($event);
        });
        /**-- */
        this.bsModalRef.content.refresh.subscribe(($event) => {
            this.refresh.emit($event);
        });
        this.bsModalRef.content.title = this.title;
        this.bsModalRef.content.vqEvent = this.vqEvent;
        // dates
        this.bsModalRef.content._bsRangeValue[0] = new Date(this.vqEvent.startDate ? this.vqEvent.startDate : Date.now());
        this.bsModalRef.content._bsRangeValue[1] = new Date(this.vqEvent.endDate ? this.vqEvent.endDate : Date.now());
        // ----------------------------------------------------

        if (this.vqEvent.timeInterval) {
            let d = new Date();

            if (this.vqEvent.timeInterval.afternoon) {
                // setting the afternoon

                d.setMilliseconds(0);
                // start
                d.setHours(new Date(
                    this.vqEvent.timeInterval.afternoon.start ? this.vqEvent.timeInterval.afternoon.start : Date.now()).getHours());
                d.setMinutes(new Date(
                    this.vqEvent.timeInterval.afternoon.start ? this.vqEvent.timeInterval.afternoon.start : Date.now()).getMinutes());
                this.bsModalRef.content.afternoonStart = d;

                // end
                d = new Date();
                d.setMilliseconds(0);

                d.setHours(new Date(
                    this.vqEvent.timeInterval.afternoon.end ? this.vqEvent.timeInterval.afternoon.end : Date.now()).getHours());
                d.setMinutes(new Date(
                    this.vqEvent.timeInterval.afternoon.end ? this.vqEvent.timeInterval.afternoon.end : Date.now()).getMinutes());
                this.bsModalRef.content.afternoonEnd = d;
            }


            // setting the morning
            if (this.vqEvent.timeInterval.morning) {
                // start
                d = new Date();
                d.setMilliseconds(0);

                d.setHours(new Date(
                    this.vqEvent.timeInterval.morning.start ? this.vqEvent.timeInterval.morning.start : Date.now()).getHours());
                d.setMinutes(new Date(
                    this.vqEvent.timeInterval.morning.start ? this.vqEvent.timeInterval.morning.start : Date.now()).getMinutes());
                this.bsModalRef.content.morningStart = d;
                // end
                d = new Date();
                d.setMilliseconds(0);

                d.setHours(new Date(
                    this.vqEvent.timeInterval.morning.end ? this.vqEvent.timeInterval.morning.end : Date.now()).getHours());
                d.setMinutes(new Date(
                    this.vqEvent.timeInterval.morning.end ? this.vqEvent.timeInterval.morning.end : Date.now()).getMinutes());
                this.bsModalRef.content.morningEnd = d;
            }


        }

        // ---------------------------------------------
        // venue
        this.bsModalRef.content.currentVenueId = this.vqEvent.venueId ? this.vqEvent.venueId : 'default';
        this.bsModalRef.content.add = this.add;
        this.bsModalRef.content.edit = this.edit;
        this.bsModalRef.content.show = this.show || this.details;
        this.bsModalRef.content.dataStoreVenue = this.dataStoreVenue;
        this.bsModalRef.content.badgeDesign = this.badgeDesign;
        this.bsModalRef.content.currentUser = this.currentUser;
        this.bsModalRef.content.usersList = this.usersList;

        if (this.venuesList) {
            this.venuesList.forEach(venue => {
                // console.log(venue);
                if (this.vqEvent !== undefined && venue.id === this.vqEvent.venueId) {
                    this.bsModalRef.content.selectedVenue[0] = { id: venue.id, text: venue.name };
                }
                this.bsModalRef.content.venues.push({ id: venue.id, text: venue.name });
            });
        }

        if (this.vqEvent.type !== undefined) {
            // console.log(this.vqEvent.type);
            this.bsModalRef.content.types.forEach(element => {
                if (element.id === this.vqEvent.type) {
                    this.bsModalRef.content.selectedValue[0] = element;
                    return;
                }
            });
        }
        this.bsModalRef.content.reload();


    }
}
