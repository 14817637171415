import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { AuthService } from '../auth-service/auth.service';
import { map, take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  verificationToken: any;
  constructor(private http: Http, private authService: AuthService) {
    this.GetToken().then(token => this.verificationToken = token).catch(err => {

    });
  }
  /**
  * This Method send a post request
  * @param functionName function name
  * @param body The body of the request must be an object
  */
  public post(functionName: string, body: any) {
    return new Promise((resolve, reject) => {
      this.GetToken().then(token => {
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        headers.append('Content-Type', 'application/json');
        // let content={name:'verification'};
        this.http.post('/api/' + functionName, body, { headers: headers }).toPromise().then((resp: Response) => {
          resolve(resp.json());
        }).catch(err => {
          // console.log(err)
          reject(err.json());
        });
      }).catch(err => reject({ success: false, error: err, message: err.message }));
    });
  }
  public get(functionName: string) {
    return new Promise((resolve, reject) => {
      this.GetToken().then(token => {
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        headers.append('Content-Type', 'application/json');
        // let content={name:'verification'};
        this.http.get('/api/' + functionName, { headers: headers }).subscribe((resp: Response) => {
          // console.log(resp.json());
          resolve(resp.json());
        });
      }).catch(err => reject({ success: false, error: err, message: err.message }));
    });
  }
  /**
   * This function is getting the Token from authenticated user
   * @param Force forcing cache
   */
  private GetToken(Force: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => this.authService.getToken(Force).then(token => {
      resolve(token);
    }).catch(err => reject(err)));
  }
}
