import { Injectable } from '@angular/core';
import { Venue } from '../../models';
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class VenuesProviderService {

  constructor(private database: AngularFireDatabase, private httpservice: HttpService) {
  }
  getAllVenues() {
      return new Promise((resolve, reject) => {
          this.httpservice.get('getVenues').then(success => {
              resolve(success);
          }).catch(err => {
              reject(err);
          });
      });
  }
  addVenue(venue: Venue, orgId: string) {
    venue.organizationId = orgId;
      const body = { data: venue };
      return new Promise((resolve, reject) => {
          this.httpservice.post('addVenue', body).then(success => { resolve(success); }).catch(err => { reject(err); });
      });
  }
  updateVenue(venue: Venue, orgId: string) {
    venue.organizationId = orgId;
      const body = { updates: venue, id: venue.id };
      return new Promise((resolve, reject) => {
          this.httpservice.post('updateVenue', body).then(success => { resolve(success); }).catch(err => { reject(err); });
      });
  }
  deleteVenue(id: string) {
      const body = { updates: {deleted: true}, id: id };
      console.log(body);
      return new Promise((resolve, reject) => {
          this.httpservice.post('updateVenue', body).then(success => { resolve(success); }).catch(err => { reject(err); });
      });
  }
  restoreVenue(id: string) {
      const body = { updates: {deleted: false}, id: id };
      return new Promise((resolve, reject) => {
          this.httpservice.post('updateVenue', body).then(success => { resolve(success); }).catch(err => { reject(err); });
      });
  }
}
