import { Injectable } from '@angular/core';
import { VQEvent, BadgeDesign } from '../../models';
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpService } from '../http/http.service';
import { ImageUploaderService } from './image-uploader.service';

@Injectable({
  providedIn: 'root'
})
export class VQEventsProviderService {
  // Events$: FirebaseListObservable<VQEvent[]> = this.database.list(
  //   '/VQEvents'
  // );

  constructor(
    private database: AngularFireDatabase,
    private httpservice: HttpService,
    private imageUploader: ImageUploaderService
  ) {
    // this.Events$=this.database.list('/VQEvents');
  }
  /** This function returns all the vqEvents from Google Datastore
   * @version 0.0.1
   * @author bouzahar.yosobox(at)gmail.com
   */
  public getAdminEvents() {
    // this.httpservice.get('test');
    return new Promise((resolve, reject) => {
      this.httpservice
        .get('getAdminEvents')
        .then((resp: any) => {
          //
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   * Get manager' event list
   */
  public getManagerEvents() {
    // this.httpservice.get('test');
    return new Promise((resolve, reject) => {
      this.httpservice
        .get('getManagerEvents')
        .then((resp: any) => {
          //
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   * This function adds the vQEvent to the Google DataStore
   * @param {models.VQEvent} vqEvent the vqEvent object
   * @version 0.0.1
   */
  public addEvent(vqEvent: VQEvent, badge: BadgeDesign, orgId: string) {
    vqEvent.organizationId = orgId;
    // console.log(vqEvent);
    const body = {
      data: {
        event: vqEvent,
        badge: badge
      }
    };
    // console.log(body);
    return new Promise((resolve, reject) => {
      // console.log('sending----');
      this.httpservice
        .post('addEvent', body)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   * This function updates a given vQEvent and badge in the Google DataStore
   * @param {models.VQEvent} vqEvent the vqEvent object
   * @todo complete this function and implement it every where needed
   * @version 0.0.3
   * @author bouzahar.yosobox(at)gmail.com
   */
  public updateEvent(vqEvent: VQEvent, badge: BadgeDesign) {
    const body = {
      event: { updates: vqEvent, id: vqEvent['id'] },
      badge: { updates: badge, id: badge.id }
    };
    return new Promise((resolve, reject) => {
      console.log('sending---->', body);
      this.httpservice.post('updateEvent', body).then((resp: any) => {
        resolve(resp);
      }).catch(err => { reject(err); });
    });
  }
  public updateEventOnly(vqEvent: VQEvent) {
    const body = {
      event: { updates: vqEvent, id: vqEvent['id'] }
    };
    return new Promise((resolve, reject) => {
      console.log('sending---->', body);
      this.httpservice.post('updateEventOnly', body).then((resp: any) => {
        resolve(resp);
      });
    });
  }
  /**
   * This function delete an event from datastore
   * @version 0.0.1
   */
  public deleteEvent(vqEventId: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body = { id: vqEventId };
      this.httpservice
        .post('deleteEvent', body)
        .then((resp: any) => {
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  }
  public restoreEvent(vqEventId: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body = { id: vqEventId };
      this.httpservice
        .post('restoreEvent', body)
        .then((resp: any) => {
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  }
  public uploadeventImage(eventId, islogo: boolean, data) {
    return new Promise((resolve, reject) => {
      this.imageUploader
        .uploadImage(
          data,
          'events_img',
          `${eventId}-${islogo ? 'logo' : 'background'}.png`
        )
        .then((success: any) => {
          resolve(success);
        })
        .catch(err => reject(err));
    });
  }
  getEventImages(eventid, islogo: boolean) {
    return new Promise((resolve, reject) => {
      this.imageUploader
        .downloadImage(
          'events_img',
          `${eventid}-${islogo ? 'logo' : 'background'}.png`
        )
        .then((url) => {
          // console.log("prov", url);
          resolve(url);
        })
        .catch((err) => reject(err));
    });
  }
  getEventBadge(uuid) {
    return this.imageUploader.downloadImage('badges/admins', `${uuid}`);
  }
}
