import {Injectable} from '@angular/core';
import {AngularFireDatabase} from 'angularfire2/database';
import {HttpService} from '../http/http.service';
import {BadgeDesign} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class BadgesProviderService {

  constructor(private database: AngularFireDatabase,
              private httpservice: HttpService) {

  }

  public deleteBadge(badgeId) {
    const body = {badgeId: badgeId};
    return this.httpservice.post('deleteBadge', body);
  }



  public getBadgesTemplates() {
    return this.httpservice.get('getBadgesTemplates');

  }

  public addBadgeDesign(badgeDesign: BadgeDesign) {
    const body = {data: badgeDesign};
    return new Promise((resolve, reject) => {
      this.httpservice.post('addBadgeDesign', body).then((success) => {
        resolve(success);
      }).catch(err => reject(err));
    });
  }

  public updateBadgeDesign(badgeDesign: BadgeDesign) {
    const body = {
      updates: badgeDesign, id: badgeDesign.id
    };
    console.log(badgeDesign);
    return new Promise((resolve, reject) => {
      this.httpservice.post('updateBadgeDesign', body).then((success) => {
        resolve(success);
      }).catch(err => reject(err));
    });
  }

  public getBadgeDesign(id) {
    const body = {id: id};
    return new Promise((resolve, reject) => {
      this.httpservice.post('getBadgeDesign', body).then((success) => {
        resolve(success);
      }).catch(err => reject(err));
    });
  }

  public getUserBadgeDesign(uid) {
    const body = {attributes: {userid: uid}};
    return new Promise((resolve, reject) => {
      this.httpservice.post('getUserBadgeDesign', body).then((success) => {
        resolve(success);
      }).catch(err => reject(err));
    });
  }

}
